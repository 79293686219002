import React, {useState, useEffect} from "react"
import { Card, CardBody, Table, CardTitle, Col, Button } from "reactstrap"
import Axios from "../../helpers/axios_helper"
import * as CHelper from "../../helpers/custom_helper"
import { toast, Slide } from 'react-toastify/dist/react-toastify';

const PaymentPolicy = ({paymentPolicyData,transactions,academicProfileId, courseId, userHasEditPermission}) => {
    const [paymentPolicy, setPaymentPolicy] = useState(false);
    const [transactionHistory, setTransactionsHistory] = useState(false);
    useEffect(()=>{
        if(paymentPolicyData && courseId && academicProfileId){
            setPaymentPolicy(paymentPolicyData);
        }
        if(transactions && courseId && academicProfileId){
            setTransactionsHistory(transactions);
        }
        return;
    },[paymentPolicyData])

    const openPaymentWindow = (paymentUrl) => {
        window.location.href = paymentUrl;
    };

    async function initiatePayment(applicationFee, installmentNo){
        window.sLoader();
        const successUrl = JSON.stringify(
            {
                academicProfileId:academicProfileId,
                courseId:courseId,
                activeTab:6,
                applicationFee:applicationFee,
                installmentNo:installmentNo
            }
        )

        const jsonData = {
            academicProfileId: academicProfileId,
            courseId: courseId,
            applicationFee : applicationFee,
            installmentFee: !applicationFee,
            installmentNo: installmentNo,
            successUrlParams: encodeURIComponent(CHelper.encryptData(successUrl)),
            value_a:`academicProfileId_${academicProfileId}`,
            value_b:`courseId_${courseId}`,
            value_c:`applicationFee_${applicationFee?1:0}`,
            value_d:`installmentNo_${installmentNo}`
        }

        await Axios.post(`/open/sslcommerz/init`, jsonData)
            .then(async (response) => {
                if (response.data.status === 200) {
                    openPaymentWindow(decodeURIComponent(response.data.message));
                    
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                }
            })
            .catch((e) => {
                window.hLoader();
                toast.error(e, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            })
    }

    const TransactionStatus = ({transactionStatus}) =>{
        return (
            <>
                {transactionStatus==1 && <span className="badge badge-soft-secondary font-size-12">Unpaid</span>}
                {transactionStatus==2 && <span className="badge badge-soft-warning font-size-12">Processing</span>}
                {transactionStatus==3 && <span className="badge badge-soft-warning font-size-12">Checking</span>}
                {transactionStatus==4 && <span className="badge badge-soft-success font-size-12">Confirmed</span>}
                {transactionStatus==5 && <span className="badge badge-soft-danger font-size-12">Denied From SSL</span>}
                {transactionStatus==6 && <span className="badge badge-soft-danger font-size-12">Failed</span>}
                {transactionStatus==7 && <span className="badge badge-soft-danger font-size-12">Cancelled</span>}
            </>
            
        )
    }

    const TransactionButton = ({transactionDetail}) =>{
        return (
            <>
                {transactionDetail.transactionStatus==1 && !userHasEditPermission && <Button onClick={()=>initiatePayment(transactionDetail.transactionType==1,transactionDetail.transactionInstallmentNo)} className="btn btn-primary btn-sm">Pay now</Button>}
                {transactionDetail.transactionStatus==2 && !userHasEditPermission && <Button onClick={()=> {}} className="btn btn-warning btn-sm" disabled={true}>Processsing</Button>}
                {transactionDetail.transactionStatus==3 && !userHasEditPermission && <Button onClick={()=> {}} className="btn btn-warning btn-sm" disabled={true}>Checking</Button>}
                {transactionDetail.transactionStatus==4 && !userHasEditPermission && <Button onClick={()=>initiatePayment(transactionDetail.transactionType==1,transactionDetail.transactionInstallmentNo)} className="btn btn-success btn-sm">Paid</Button>}
                {transactionDetail.transactionStatus==5 && !userHasEditPermission && <Button onClick={()=>initiatePayment(transactionDetail.transactionType==1,transactionDetail.transactionInstallmentNo)} className="btn btn-primary btn-sm">Pay Again</Button>}
                {transactionDetail.transactionStatus==6 && !userHasEditPermission && <Button onClick={()=>initiatePayment(transactionDetail.transactionType==1,transactionDetail.transactionInstallmentNo)} className="btn btn-primary btn-sm">Pay now</Button>}
                {transactionDetail.transactionStatus==7 && !userHasEditPermission && <Button onClick={()=>initiatePayment(transactionDetail.transactionType==1,transactionDetail.transactionInstallmentNo)} className="btn btn-primary btn-sm">Pay now</Button>}
            </>
            
        )
    }

    return (
        <Col lg={12}>
            {paymentPolicy && 
            <Card>
                <CardBody style={{paddingLeft:"0",paddingRight:"0"}}>
                    <CardTitle className="h4 mb-4">Payment Section</CardTitle>
                    <div className="table-responsive">
                        <Table className="table-centered">
                            <thead>
                                <tr>
                                    <th scope="col">Payment Type</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col" colSpan="2">Payment Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paymentPolicy.haveApplicationFee && 
                                    <tr>
                                        <td>Application Fee</td>
                                        <td>{paymentPolicy.applicationFee} Taka</td>
                                        <td><span className="badge badge-soft-danger font-size-12">Unpaid</span></td>
                                        <td><Button onClick={()=>initiatePayment(true,0)} className="btn btn-primary btn-sm">Pay now</Button></td>
                                    </tr>
                                }
                                {paymentPolicy.paymentInstallmentDetails.length>1 ? 
                                    paymentPolicy.paymentInstallmentDetails.map((item,i)=>{
                                    return ( 
                                        <tr>
                                            <td>Installment No {i+1}</td>
                                            <td>{item.perInstallmentAmount} Taka</td>
                                            <td><span className="badge badge-soft-danger font-size-12">Unpaid</span></td>
                                            <td><Button onClick={()=>{}} className="btn btn-primary btn-sm" disabled={true}>Pay now</Button></td>
                                        </tr>)
                                    })
                                    :
                                    <tr>
                                        <td>Course Fee</td>
                                        <td>{paymentPolicy.paymentInstallmentDetails[0].perInstallmentAmount} Taka</td>
                                        <td><span className="badge badge-soft-danger font-size-12">Unpaid</span></td>
                                        <td><Button onClick={()=>initiatePayment(false, 0)} className="btn btn-primary btn-sm">Pay now</Button></td>
                                    </tr>}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>}


            {transactionHistory && 
            <Card>
                <CardBody style={{paddingLeft:"0",paddingRight:"0"}}>
                    <CardTitle className="h4 mb-4">Payment Section</CardTitle>
                    <div className="table-responsive">
                        <Table className="table-centered">
                            <thead>
                                <tr>
                                    <th scope="col">Payment Type</th>
                                    <th scope="col">Last/Next Payment Date</th>
                                    <th scope="col">Grace Period</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col" colSpan="2">Payment Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactionHistory.length>1 ? 
                                    transactionHistory.map((item,i)=>{
                                    return ( 
                                        <tr key={i}>
                                            <td>{item.transactionType==1?"Application Fee":"Installment "+item.transactionInstallmentNo}</td>
                                            <td>{item.transactionInstallmentStartDate}</td>
                                            <td>{item.transactionInstallmentGracePeriod}</td>
                                            <td>{item.transactionInstallmentAmount} Taka</td>
                                            <td><TransactionStatus transactionStatus={item.transactionStatus}/></td>
                                            <td><TransactionButton transactionDetail={item}/></td>
                                        </tr>)
                                    })
                                    :
                                    <tr>
                                        <td>Course Fee</td>
                                        <td>{paymentPolicy[0].perInstallmentAmount} Taka</td>
                                        <td><span className="badge badge-soft-danger font-size-12">Unpaid</span></td>
                                        <td><Button onClick={()=>initiatePayment(false, 0)} className="btn btn-primary btn-sm">Pay now</Button></td>
                                    </tr>}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>}
        </Col>
    )
}

export default PaymentPolicy