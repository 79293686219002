import React, { useMemo, useState, useEffect, useRef } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Label,
  CardImg,
  Table,
  Modal
} from "reactstrap"
import { Delete,Edit, VideoCall, Download, Mail } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Typography,
  Tooltip,
} from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { AvForm, AvField } from "availity-reactstrap-validation"
import * as helper from '../../utils/helper'
import CustomModal from "../Common/CustomModal"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

import Axios from "../../helpers/axios_helper"
import * as SHelper from "../../helpers/session_helper"
import * as CHelper from "../../helpers/custom_helper"
import { useHistory } from "react-router-dom"
import { toast,Slide } from 'react-toastify/dist/react-toastify';

const Model = (props) => {
  const history = useHistory()
  const [courseClasses, setCourseClasses] = useState([]);
  const [updateData, setUpdateData] = useState(false)
  const [zoomAccessTokenModel, setZoomAccessTokenModel] = useState(false);
  const [delete_modal_center, setDelete_modal_center] = useState(false);
  const selectedItem = useRef(0)
  const [courseClassId, setCourseClassId] = useState(-1);
  const [courseClassIdx, setCourseClassIdx] = useState(-1);
  const [courseClassName, setCourseClassName] = useState('');
  const [courseStartDatetime, setCourseStartDatetime] = useState(null);
  const [courseEndDatetime, setCourseEndDatetime] = useState(null);
  const [courseClassDescription, setCourseClassDescription] = useState('');
  const [announcement_mail_modal, setAnnouncement_mail_modal] = useState(false);
  
  const [showCreateNewClass, setShowCreateNewClass] = useState(false)

  const [zoomDetailsModalOpen, setZoomDetailsModalOpen] = useState(false)
  const [zoomJoiningLink, setZoomJoiningLink] = useState(false)
  const showMeetingRoom = (data) =>{
    data = JSON.parse(data);
    setZoomJoiningLink(data.start_url);
    setZoomDetailsModalOpen(true)
  }

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [columnFilters, setColumnFilters] = useState([]);
  const [columnFilterFns, setColumnFilterFns] = useState({
    courseClassName: 'contains',
    courseStartDatetime: 'equal',
    courseEndDatetime: 'equal'
  });
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const tableInstanceRef = useRef(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5
  });

  const handleSubmit = async (event, errors, values) => {
    if (!props.courseId) {
        toast.error(`Course is required !`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
    }
    else {
      var updateData=courseClasses;
      updateData.forEach(element => {
        element.courseId=props.courseId;
        element.moduleId=props.moduleId;
        if(element.courseClassIsActive){
          element.courseClassIsActive=element.courseClassIsActive==1?true:false;
        }
      });

      const jsonData = {
        courseClasses:updateData
      }

      await Axios.post("/courseClass/bulk", jsonData)
        .then(async (response) => {
          if (response.data.status === 200) {
            toast.success('Course Classes Created/Updated successfully!', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setShowCreateNewClass(false);
            setTimeout(() => {
              fetchDataFromAPI();
            }, 1000);
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        })
        .catch((e) => {
          toast.error(e.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        })

    }

  }

  const sendAnnouncementMail = async (modalResponse) => {
    if(!modalResponse) return;
    await Axios.post(`/courseClass/sendMail/${selectedItem.current}`)
    .then((response) => {
        if (response.data.status==200) {
            toast.success(`Mail Send successfully !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else {
            toast.error(`Mail Send failed !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
    });
    setAnnouncement_mail_modal(false);
}

  const addCourseClass = () =>{
    if(courseClassIdx>-1){
        courseClasses[courseClassIdx].id=courseClassId;
        courseClasses[courseClassIdx].courseClassName=courseClassName;
        courseClasses[courseClassIdx].courseClassDescription=courseClassDescription;
        courseClasses[courseClassIdx].courseStartDatetime=courseStartDatetime;
        courseClasses[courseClassIdx].courseEndDatetime=courseEndDatetime;
    }
    else{
      courseClasses.push({
            courseClassName:courseClassName,
            courseClassDescription:courseClassDescription,
            courseStartDatetime:courseStartDatetime,
            courseEndDatetime:courseEndDatetime
        });
    }
    setCourseClasses(courseClasses);
    cancelCourseClassEdit();
    handleSubmit();
  }

  const cancelCourseClassEdit = () =>{
    setCourseClassIdx(-1);
    setCourseClassId(-1);
    setCourseClassName('');
    setCourseClassDescription('');
    setCourseStartDatetime(null);
    setCourseEndDatetime(null);
    setShowCreateNewClass(false)
  }

  const editCourseClass = (id,idx) =>{
    setCourseClassId(id);
    setCourseClassIdx(idx);
    setCourseClassName(courseClasses[idx].courseClassName);
    setCourseStartDatetime(courseClasses[idx].courseStartDatetime);
    setCourseEndDatetime(courseClasses[idx].courseEndDatetime);
    setCourseClassDescription(courseClasses[idx].courseClassDescription);
    setShowCreateNewClass(true);
  }

  const deleteCourseClass = async () =>{
    await Axios.delete(`/courseClass/id/${selectedItem.current}`)
        .then(async (response) => {
          if (response.data.status === 200) {
            toast.success('Course Classes Deleted  successfully!', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            fetchDataFromAPI();
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        })
        .catch((e) => {
          toast.error(e, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        })
  }

  const zoomOauthUrl=async ()=>{
    await Axios.get(`/zoom/oauthUrl`)
      .then((response) => {
          if (response.data.url) {
            window.open(response.data.url, '_blank');
          }
      });
  }

  const recheckAccessTokenValidity=async ()=>{
    await Axios.get(`/zoom/getOauthAccessToken`)
      .then((response) => {
          if (response.data.status==200) {
            setZoomAccessTokenModel(false);
            toast.success('Zoom Connected successfully !', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
          else{
            toast.error('Failed to connect with zoom !', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
      });
  }

  const checkAccessTokenValidity=async ()=>{
    await Axios.get(`/zoom/checkAccessTokenValidity`)
      .then((response) => {
          if (response.data.status==200) {
            toast.success('Zoom Connected successfully !', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
          else {
            setZoomAccessTokenModel(true)
            toast.error('Failed to connect with zoom !', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
      });
  }


  const startMeeting = async (data) =>{
    if(!data)return false;
    data= JSON.parse(data);
    window.open(data.start_url, '_blank');
  }
  
  
  const joinMeeting = async (data) =>{
    if(!data)return false;
    data= JSON.parse(data);
    let user = SHelper.getUser();
    const values={
      roomNumber:data.id,//"83730438367",
      roomPass:data.password,//"ZL2cu4",
      sdkKey:data.sdkKey,//'iFtSrW6QjqLRQnhgEglQ',
      role:0,
      userName:user.full_name,
      userEmail:(user.role_code=='111' || user.role_code=='222') ? data.host_email:'',
      registrantToken:"",
      zakToken:"",
      leaveUrl:window.location.href,
    };
    let encryptedUrl= SHelper.encrypt(JSON.stringify(values));
    history.push(`/join-meeting?key=${encodeURIComponent(encryptedUrl)}`)
  }


  //#region Datatable

  const columns = useMemo(
    () => {
      const iColums = [
      {
        size:50,
        accessorKey: 'sl',
        header: "#SL",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: false,
        enableColumnActions:false,
        enableSorting: false
      },
      {
        accessorKey: 'courseClassName',
        header: "Name",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        accessorKey: 'courseStartDatetime',
        header: "Start",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{row.original.courseStartDatetime}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        accessorKey: 'courseEndDatetime',
        header: 'End',
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{row.original.courseEndDatetime}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        accessorKey: 'status',
        header: "Status",
        Cell: ({ cell, row }) => (
          // <Typography variant='h7'>{row.original.startDatetime}</Typography>
          <span className={checkDateRange(row.original.courseStartDatetime,row.original.courseEndDatetime)[0]}>{checkDateRange(row.original.courseStartDatetime,row.original.courseEndDatetime)[1]}</span>
        ),
        enableColumnFilterModes: false,
        enableColumnActions:false,
        enableSorting: false
      },
    ]
    return iColums;
  },
    [],
  );

  const findFilterCondition = (name) => {
    var condition = '';
    if (columnFilterFns[name]) {
      condition = columnFilterFns[name];
    }
    return condition;
  }

  function checkDateRange(fromDate, toDate) {
    const currentDate = new Date();
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);

    if (currentDate >= startDate && currentDate <= endDate) {
        return ["badge badge-soft-success font-size-12","Ongoing",true]; //'Current date is within the range'
    } else if (currentDate > startDate) {
        return ["badge badge-soft-danger font-size-12","Completed",false]; //'Current date is before the range'
    } else {
        return ["badge badge-soft-warning font-size-12","Upcoming", false]; //'Current date is after the range'
    }
  }

  const fetchDataFromAPI = async (reset = false) => {
    
    window.sLoader();
    if (!courseClasses.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    let filterQuery = '';
    let sorterQuery = '';

    if (!reset) {
      if (columnFilters.length > 0) {
        columnFilters.forEach(element => {
          if (element.id === 'invoicedate' || element.id === 'duedate') {
            // filterQuery += `&filter=${element.id},${findFilterCondition(element.id)},${AuthVerify.getDate(element.value)}`;
          }
          else {
            filterQuery += `&filter=${element.id},${findFilterCondition(element.id)},${element.value}`;
          }
        });
      }
      if (sorting.length > 0) {
        sorting.forEach(element => {
          sorterQuery += `&sortby=${element.id}&orderby=${element.desc ? 'desc' : 'asc'}`;
        });
      }
    } else {
      setPagination({
        pageIndex: 0,
        pageSize: 5
      });
      tableInstanceRef.current.resetRowSelection();
    }

    const url = `courseClass/listByCourse/${props.courseId}/${props.moduleId}?limit=${pagination.pageSize}&offset=${pagination.pageIndex * pagination.pageSize}${sorterQuery}${filterQuery}`;
    // let url = `/page/list`;
    await Axios.get(url)
      .then(function (response) {
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
        if (response && response.data?.data) {
          setCourseClasses(response.data?.data);
          setRowCount(response.data.recordsTotal);
        }
        else {
          toast.error(response.statusText ?? "Data fetching failed !", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            transition: Slide,
          });
        }
      })
      .catch(function (error) {
        // navigate('/');
      });
      window.hLoader();
  };

  useEffect(() => {
    if(props.courseId && props.moduleId);
      fetchDataFromAPI();
    }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    props.courseId,
    props.moduleId
  ]);

  
  useEffect(() => {
    if(SHelper.userHasEditPermission()){
      checkAccessTokenValidity();
    }  
  }, []);
  //#endregion


  return (
    <Row>
      {(!props.id || props.id === 0 || (props.id > 0 && updateData)) &&
        <Col xl="12">
          
          <Card>
            <CardTitle style={{ fontSize: "18px", textAlign: "center", opacity:"0.7" }}>Classes</CardTitle>
            <CardBody style={{ paddingTop: "0.5rem" }}>
              <AvForm className="needs-validation" onSubmit={handleSubmit}>
              {SHelper.userHasEditPermission() && showCreateNewClass &&
              <>
                <Col md="12">
                  <Row>
                    <Col md="12">
                      <div className="mb-3">
                        <Label>Name</Label>
                        <AvField
                          name="courseClassName"
                          value={courseClassName}
                          onChange={(e)=>{setCourseClassName(e.target.value);}}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label>Start datetime</Label>
                        <Flatpickr
                            name="instructorDateOfBirth"
                            id="instructorDateOfBirth"
                            className="form-control d-block"
                            // placeholder="dd M, yyyy"
                            value = {courseStartDatetime}
                            options={{
                              dateFormat: 'Y-m-d h:i K',
                              timeFormat: 'h:i K',
                              enableTime: true,
                              time_24hr: false,
                              // altInput: true,
                              // altFormat: 'F j, Y h:i K',
                              // altInputClass: 'flatpickr-alt-input',
                            }}
                            onChange={(selectedDates, dateStr, instance) => {
                                setCourseStartDatetime(dateStr);
                            }}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label>End Datetime</Label>
                        <Flatpickr
                            name="instructorDateOfBirth"
                            id="instructorDateOfBirth"
                            className="form-control d-block"
                            value = {courseEndDatetime}
                            // placeholder="dd M, yyyy"
                            options={{
                              dateFormat: 'Y-m-d h:i K',
                              timeFormat: 'h:i K',
                              enableTime: true,
                              time_24hr: false,
                              // altInput: true,
                              // allowInput: true,
                              // altFormat: 'F j, Y h:i K',
                              // altInputClass: 'flatpickr-alt-input',
                              // parseDate: (datestr, format) => {
                              //   return moment(datestr, format, true).toDate();
                              // },
                              // formatDate: (date, format, locale) => {
                              //   // locale can also be used
                              //   return moment(date).format(format);
                              // }
                            }}
                            onChange={(selectedDates, dateStr, instance) => {
                                setCourseEndDatetime(dateStr);
                            }}
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="mb-3">
                        <Label>Description</Label>
                        <AvField
                          name="courseTypeDescription"
                          value={courseClassDescription}
                          onChange={(e)=>{setCourseClassDescription(e.target.value);}}
                          type="textarea"
                          className="form-control"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <div className="actions clearfix">
                    <Button
                        style={{ float: "right", marginLeft: "10px" }}
                        type="button"
                        color="primary"
                        onClick={addCourseClass}
                    >{ courseClassIdx>-1 ? "Update":"Add"}</Button>
                    { courseClassIdx >-1 && 
                    <Button
                        style={{ float: "right" }}
                        type="button"
                        color="danger"
                        onClick={cancelCourseClassEdit}
                    >Cancel Edit</Button>}
                    {showCreateNewClass && courseClassIdx ==-1 && 
                    <div className="actions clearfix">
                        <Button
                            onClick={()=>{setShowCreateNewClass(false); cancelCourseClassEdit();}}
                            style={{ float: "left" }}
                            type="button"
                            color="danger"
                            >Cancel</Button>
                    </div>}
                </div>
                </>}
                {!showCreateNewClass && SHelper.userHasEditPermission() &&
                  <div className="actions clearfix">
                      <Button
                          onClick={()=>{setShowCreateNewClass(true);}}
                          style={{ float: "left" }}
                          type="button"
                          color="primary"
                          >Create New Class</Button>
                  </div>}
                  <br/>
                {!showCreateNewClass &&
                  <MaterialReactTable
                      columns={columns}
                      data={courseClasses}
                      enableColumnActions={false}
                      enableGlobalFilter={false}
                      enableColumnFilterModes
                      initialState={{
                      showColumnFilters: false
                      }}
                      manualFiltering
                      manualPagination
                      manualSorting
                      onColumnFilterFnsChange={setColumnFilterFns}
                      onColumnFiltersChange={setColumnFilters}
                      onGlobalFilterChange={setGlobalFilter}
                      onPaginationChange={setPagination}
                      onSortingChange={setSorting}
                      tableInstanceRef={tableInstanceRef}
                      rowCount={rowCount}
                      enableEditing
                      state={{
                      columnFilters,
                      columnFilterFns,
                      globalFilter,
                      isLoading,
                      pagination,
                      showAlertBanner: isError,
                      showProgressBars: isRefetching,
                      sorting,
                      }}
                      positionActionsColumn="last"
                      displayColumnDefOptions={{
                      'mrt-row-actions': {
                          header: 'Action',
                      },
                      }}

                      renderRowActions={({ row, table }) => (
                        <Box sx={{ display: courseClassIdx===row.index?'none':'flex' }}>
                          {SHelper.userHasEditPermission() && 
                          <Tooltip arrow placement="left" title="Edit">
                          <IconButton onClick={() => {editCourseClass(row.original.id,row.index)}}>
                              <Edit />
                          </IconButton>
                          </Tooltip>}

                          {/* {item.courseZoomDetails && <Tooltip arrow placement="left" title="Start Meeting in Modal">
                          <IconButton style={{color:"blue"}} onClick={() => {showMeetingRoom(item.courseZoomDetails)}}>
                              <VideoCall />
                          </IconButton>
                          </Tooltip>} */}
                          
                          {row.original.courseZoomDetails && SHelper.userHasEditPermission() && <Tooltip arrow placement="left" title="Start Meeting">
                          <IconButton style={{color:"blue"}} onClick={() => {startMeeting(row.original.courseZoomDetails)}}>
                              <VideoCall />
                          </IconButton>
                          </Tooltip>}

                          {row.original.courseZoomDetails && !SHelper.userHasEditPermission() && <Tooltip arrow placement="left" title="Join Meeting">
                          <IconButton style={{color:"blue"}} onClick={() => {joinMeeting(row.original.courseZoomDetails)}}>
                              <VideoCall />
                          </IconButton>
                          </Tooltip>}

                          {SHelper.userHasEditPermission() && <>
                          <Tooltip arrow placement="top" title="Send Mail to All">
                            <IconButton onClick={() => { selectedItem.current=row.original.id; setAnnouncement_mail_modal(true) }}>
                                <Mail />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow placement="left" title="Delete">
                            <IconButton style={{color:"red"}} onClick={() => {selectedItem.current=row.original.id; setDelete_modal_center(true);}}>
                                <Delete />
                            </IconButton>
                          </Tooltip></>
                          }

                          {(new Date()> new Date(row.original.courseEndDatetime)) &&
                            <Tooltip arrow placement="left" title="Download">
                            <IconButton style={{color:"brown"}} onClick={() => {alert("Paid Version Needed")}}>
                                <Download />
                            </IconButton>
                            </Tooltip>
                          }
                      </Box>
                      )}
                  />
                }
              </AvForm>
            </CardBody>
          </Card>

          <Modal
            size="sm"
            isOpen={zoomAccessTokenModel}
            centered={true}
            closeButton={false}
            backdrop="static"
            keyboard={false}>
              
            <div className="modal-header">
              <h5 className="modal-title mt-0">No Zoom Account Found !</h5>
              <button
                type="button"
                onClick={() => {
                  setZoomAccessTokenModel(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              > 
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{padding:"0"}}>
                <CardBody>
                    <Row>
                        <Col md="12">
                            No zoom account permission found. Please allow permission to go forward.
                        </Col>
                    </Row>
                </CardBody>
            </div>
            <div className="modal-footer customBtnArea" style={{textAlign: 'right'}}>
                <Button color="primary" type="button" className="btn btn-sm" onClick={()=>zoomOauthUrl()}>
                    Yes
                </Button>
                <Button color="danger" type="button" className="btn btn-sm" onClick={()=>recheckAccessTokenValidity()}>
                    Re-Check
                </Button>
            </div>
          </Modal>



          <Modal
            size="xl"
            isOpen={zoomDetailsModalOpen}
            centered={true}
            closeButton={false}
            backdrop="static"
            keyboard={false}>
            <div className="modal-body" style={{padding:"0"}}>
                <CardBody>
                    <Row>
                        <Col md="12">
                            <iframe 
                              title="Zoom Meeting"
                              allow="microphone; camera" 
                              src={zoomJoiningLink} 
                              style={{width:"100%", height:`${window.innerHeight-150}px`}}
                              >
                            </iframe>
                        </Col>
                    </Row>
                </CardBody>
            </div>
            <div className="modal-footer customBtnArea" style={{textAlign: 'right'}}>
                <Button color="primary" type="button" className="btn btn-sm" onClick={()=>setZoomDetailsModalOpen(false)}>
                    Close
                </Button>
            </div>
          </Modal>

        </Col>
      }
      <CustomModal modelShow={delete_modal_center} handleCallback={deleteCourseClass}/>
      <CustomModal modelShow={announcement_mail_modal} handleCallback={sendAnnouncementMail} bodyMsg={"Would you like to send this class email to all of your students?"}/>
    </Row>
  )
}

export default Model
