import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle,Modal } from "reactstrap";
import Axios from "../../helpers/axios_helper";
import { Link } from "react-router-dom";

const LineChart = () => {
    const [notifications, setNotifications]=useState([])
    const [notification, setNotification]=useState(false)
    const [modal_announcement, setModal_announcement] = useState(false);

    useEffect(()=>{
        Axios.get('/notification/homepage')
      .then(function (response) {
        if (response && response.data?.data) {
            setNotifications(response.data.data);
        }
      })
      .catch(function (error) {
        // navigate('/');
      });
    },[])

    const getNoticeDetails = (id) =>{
        Axios.get(`/notification/id/${id}`)
        .then(function (response) {
          if (response && response.data?.data) {
              setNotification(response.data.data);
              setModal_announcement(true)
          }
        })
        .catch(function (error) {
          // navigate('/');
        });
    }

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-5">Notice Board</CardTitle>
                    <ul className="list-unstyled activity-wid">
                        {notifications.length>0 ? notifications.map((item,i)=>{
                            return (
                                <li key={i} className="activity-list">
                                    <div className="activity-icon avatar-xs">
                                        <span className="avatar-title bg-soft-primary text-primary rounded-circle">
                                            <i className="mdi mdi-calendar-edit"></i>
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-start">
                                        <div className="me-3">
                                            <h5 className="font-size-14">{item.updatedAt.split('T')[0]} <i
                                                className="mdi mdi-arrow-right text-primary align-middle ms-2"></i>
                                            </h5>
                                        </div>
                                        <div className="flex-1">
                                            <div>
                                            {item.notificationMessage.length > 65
                                                ? `${item.notificationMessage.substring(0, 65)}...`
                                                : item.notificationMessage
                                            }.. <Link to="#" onClick={()=>getNoticeDetails(item.id)}>Read more</Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                        :
                        <div className="col-md-12" style={{textAlign:"center"}}>
                            No Notice Found
                        </div>}
                    </ul>
                    {/* <div className="text-center mt-4">
                        <Link
                            to=""
                            className="btn btn-primary btn-sm"
                        >
                            View More <i className="mdi mdi-arrow-right ms-1" />
                        </Link>
                    </div> */}
                </CardBody>
            </Card>

            <Modal
            size="md"
            isOpen={modal_announcement}
            centered={true}>

            <div className="modal-header">
              <h5 className="modal-title mt-0">{notification.notificationSubject}</h5>
              <button
                type="button"
                onClick={()=>{setModal_announcement(false)}}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              > 
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p><div dangerouslySetInnerHTML={{ __html: notification.notificationMessage }} style={{padding:"10px"}}></div></p>
            </div>
          </Modal>
        </React.Fragment>
    )
}

export default LineChart