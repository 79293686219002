import React, { useState, useEffect, useRef } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Label,
  CardImg,
  Table
} from "reactstrap"
import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import { AvForm, AvField } from "availity-reactstrap-validation"

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';

import Axios from "../../helpers/axios_helper"
import * as SHelper from "../../helpers/session_helper"
import { useHistory } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../assets/images/media.jpeg";
const moment = require('moment');

const Model = (props) => {
  const history = useHistory()
  const [modules, setModules] = useState([]);
  const [files, setFiles] = useState([]);


  const [syllabusId, setSyllabusId] = useState(0);
  const [overviewState, setOverviewState] = useState(EditorState.createEmpty());
  const [overviewContent, setOverviewContent] = useState();

  const handleSubmit = async (event, errors, values) => {
      var courseDetails ={
        courseId:props.courseId,
        moduleId:props.moduleId,
        courseSyllabus:overviewContent
      };

      if (syllabusId>0) {
        courseDetails.id=syllabusId;

        await Axios.patch(`/courseSyllabus/${props.courseId}&${props.moduleId}`, courseDetails)
        .then(async (response) => {
          if (response.data.status === 200) {
            toast.success('Syllabus updated successfully!', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            // props.handleCallback(response.data)
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        })
        .catch((e) => {
          toast.error(e, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        })
      }
      else{
      // delete courseDetails.files;
      await Axios.post(`/courseSyllabus/${props.courseId}&${props.moduleId}`, courseDetails)
        .then(async (response) => {
          if (response.data.status === 201) {
            toast.success('Syllabus created successfully!', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            // props.handleCallback(response.data)
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        })
        .catch((e) => {
          toast.error(e, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        })
      }
      

  }

  const handleOverviewChange = (newEditorState) => {
    setOverviewState(newEditorState);
    setOverviewContent(stateToHTML(newEditorState.getCurrentContent()));
  };

  useEffect(async () => {
    if (props.courseId > 0 && props.moduleId>0) {
        await Axios.get(`/courseSyllabus/${props.courseId}&${props.moduleId}`)
            .then((response) => {
                if (response.data.status==200) {
                  setSyllabusId(response.data.data.id);

                  if(response.data.data.courseSyllabus){
                    setOverviewContent(response.data.data.courseSyllabus)
                    const courseOverview = EditorState.createWithContent(stateFromHTML(response.data.data.courseSyllabus));
                    setOverviewState(courseOverview)
                  }
                  
                }
                else {
                  setSyllabusId(0);
                  setOverviewContent('');
                  setOverviewState(EditorState.createEmpty());
                }
            });

      }
  }, [props.courseId, props.moduleId])

  return (
    <Row>
      <Col xl="12">
        <Card>
          <CardTitle style={{ fontSize: "18px", textAlign: "center", opacity:"0.7" }}>Content</CardTitle>
          <CardBody style={{ paddingTop: "0.5rem" }}>
            <AvForm className="needs-validation" onSubmit={handleSubmit}>
              <Col md="12">
                <div className="mb-3">
                  {(SHelper.isUserRole(SHelper.userRole.Administrator) || SHelper.isUserRole(SHelper.userRole.Instructor) ) ?
                  <Editor
                    editorState={overviewState}
                    onEditorStateChange={handleOverviewChange}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                  />
                  :
                  <>
                     <div dangerouslySetInnerHTML={{ __html: overviewContent }} style={{padding:"10px"}}></div>
                  </>
                }
                </div>
              </Col>
              {(SHelper.isUserRole(SHelper.userRole.Administrator) || SHelper.isUserRole(SHelper.userRole.Instructor) ) &&
              <div className="actions clearfix">
                <Button
                  style={{ float: "right", marginLeft: "10px" }}
                  type="submit"
                  color="primary"
                >Update</Button>
              </div>}
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default Model
