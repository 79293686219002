import React, { useState, useEffect, useRef } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Label,
    CardImg,
    Modal,
    Table,
} from "reactstrap";
import * as helper from '../../utils/helper'
import { ChangeCircle, Delete, List, Mail } from '@mui/icons-material';
import {
    Box,
    IconButton,
    Tooltip,
    Typography
} from '@mui/material';
import { AvForm, AvField } from "availity-reactstrap-validation"
import CustomModal from "../Common/CustomModal"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState } from "draft-js"; 
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';

import Axios from "../../helpers/axios_helper"
import * as SHelper from "../../helpers/session_helper"
import { useHistory } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../assets/images/media.jpeg";
const moment = require('moment');

const Model = (props) => {
    const history = useHistory();
    const [courseAssignments, setcourseAssignments] = useState([]);
    const [updateData, setUpdateData] = useState(false);
    const [modal_announcement, setModal_announcement] = useState(false);
    const [delete_modal_center, setDelete_modal_center] = useState(false);
    const [announcement_mail_modal, setAnnouncement_mail_modal] = useState(false);
    const [status_modal_center, setStatus_modal_center] = useState(false);
    const [announcement, setAnnouncement] = useState(false);
    const [showCreateNewAnnouncement, setShowCreateNewAnnouncement] = useState(false);

    const [courseModuleId, setCourseModuleId] = useState(0);
    const [modules, setModules] = useState([]);

    const [courseAssignmentId, setCourseAssignmentId] = useState(-1);
    const [courseAssignmentIdx, setCourseAssignmentIdx] = useState(-1);
    const [courseAnnouncement, setCourseAnnouncement] = useState('');
    const [courseAnnouncementTitle, setCourseAnnouncementTitle] = useState('');

    const [overviewState, setOverviewState] = useState(EditorState.createEmpty());
    const [overviewContent, setOverviewContent] = useState();
    
    const selectedItem = useRef(0);
    const selectedIdx = useRef(0);

    const handleOverviewChange = (newEditorState) => {
        setOverviewState(newEditorState);
        setOverviewContent(stateToHTML(newEditorState.getCurrentContent()));
    };

    const handleSubmit = async (event, errors, values) => {
        window.sLoader();
        if (!props.courseId) {
            toast.error(`Course is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else {
            var updateData = courseAssignments;
            updateData.forEach(element => {
                element.courseId = props.courseId;
                element.moduleId = props.moduleId;
                delete element.courseAnnouncementIsActive;
                delete element.createdAt;
                element.courseAnnouncement = overviewContent;
                // if (element.courseAssignmentIsActive) {
                //     element.courseAssignmentIsActive = element.courseAssignmentIsActive == 1 ? true : false;
                // }
            });

            const jsonData = {
                announcements: updateData
            }

            await Axios.post(`/courseAnnouncement/${props.courseId}&${props.moduleId}`, jsonData)
                .then(async (response) => {
                    if (response.data.status === 200) {
                        loadList();
                        toast.success('Announcement Created successfully!', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });

                    } else {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                })
                .catch((e) => {
                    toast.error(e, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                })

        }
        window.hLoader();
    }
    const addcourseAssignment = () => {
        if (courseAssignmentIdx > -1) {
            courseAssignments[courseAssignmentIdx].id = courseAssignmentId;
            courseAssignments[courseAssignmentIdx].moduleId = courseModuleId;
            courseAssignments[courseAssignmentIdx].courseAnnouncement = courseAnnouncement;
            courseAssignments[courseAssignmentIdx].courseAnnouncementTitle = courseAnnouncementTitle;
        }
        else {
            courseAssignments.push({
                moduleId : courseModuleId,
                courseAnnouncement: courseAnnouncement,
                courseAnnouncementTitle: courseAnnouncementTitle,
            });
        }
        setcourseAssignments(courseAssignments);
        cancelcourseAssignmentEdit();
        handleSubmit();
    }

    // useEffect(()=>{
    //     handleSubmit();
    // },[courseAssignments])

    const cancelcourseAssignmentEdit = () => {
        setCourseAssignmentIdx(-1);
        setCourseAssignmentId(-1);
        setCourseModuleId(0);
        setCourseAnnouncement('')
        setCourseAnnouncementTitle('')
        setOverviewState(EditorState.createEmpty());
        setOverviewContent('');
        setShowCreateNewAnnouncement(false);
    }

    const editcourseAssignment = (id, idx) => {
        setCourseAssignmentId(id);
        setCourseAssignmentIdx(idx);
        setCourseModuleId(courseAssignments[idx].moduleId);
        setCourseAnnouncement(courseAssignments[idx].courseAnnouncement);
        setCourseAnnouncementTitle(courseAssignments[idx].courseAnnouncementTitle)
    }

    const statusChangecourseAnnouncement = async (modalResponse) => {
        if(!modalResponse) return;
        setStatus_modal_center(false);
        await Axios.patch(`/courseAnnouncement/changeStatus/${selectedItem.current}`)
        .then((response) => {
            if (response.data.status==200) {
                toast.success(`Announcement status updated successfully !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                loadList();
            }
            else {
                toast.error(`Announcement status updation failed !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            }
        });
    }

    const deletecourseAnnouncement = async (modalResponse) => {
        if(!modalResponse) return;
        await Axios.delete(`/courseAnnouncement/id/${selectedItem.current}`)
        .then((response) => {
            if (response.data.status==200) {
                toast.success(`Announcement deleted successfully !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                setcourseAssignments(courseAssignments.splice(selectedIdx.current,1))
                loadList();
            }
            else {
                toast.error(`Announcement deletion failed !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            }
        });
        setDelete_modal_center(false);
    }

    const sendAnnouncementMail = async (modalResponse) => {
        if(!modalResponse) return;
        await Axios.post(`/courseAnnouncement/sendMail/${selectedItem.current}`)
        .then((response) => {
            if (response.data.status==200) {
                toast.success(`Mail Send successfully !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            }
            else {
                toast.error(`Mail Send failed !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            }
        });
        setAnnouncement_mail_modal(false);
    }

    const viewCourseAnnouncement = async (id, idx) => {
        await Axios.get(`/courseAnnouncement/id/${id}`)
        .then((response) => {
            if (response.data.status==200) {
                setModal_announcement(true);
                setAnnouncement(response.data.data);
                
                // setcourseAssignments(response.data.data);
            }
            else {

                // setcourseAssignments([])
            }
        });
    }


    const loadList = async () =>{
        setcourseAssignments([]);
        await Axios.get(`/courseAnnouncement/list/${props.courseId}&${props.moduleId}`)
        .then((response) => {
            if (response.data.recordsTotal) {
                setcourseAssignments(response.data.data);
            }
        });
    }

    useEffect(async () => {
        if (props.courseId>0) {
            await Axios.get("/module/dropdown")
                .then((response) => {
                    if (response.data.status==200) {
                        setModules(response.data.data);
                    }
                    else {
                        setModules([])
                    }
                });

                loadList();

        }
    }, [props.courseId, props.moduleId])

    return (
        <Row>
            {(!props.id || props.id === 0 || (props.id > 0 && updateData)) &&
                <Col xl="12">
                    <Card>
                        <CardTitle className="pb-2" style={{ fontSize: "18px", textAlign: "center", opacity:"0.7" }}>Announcement</CardTitle>
                        <CardBody style={{ paddingTop: "0.5rem" }}>
                            <AvForm className="needs-validation" onSubmit={handleSubmit}>
                            {SHelper.userHasEditPermission() &&
                            <>
                            {showCreateNewAnnouncement &&<>
                                <Col md="12">
                                    <Row>
                                        <Col md="12">
                                            <div className="mb-3">
                                                <Label>Title</Label>
                                                <AvField
                                                    name="courseAnnouncementTitle"
                                                    value={courseAnnouncementTitle}
                                                    onChange={(e) => { setCourseAnnouncementTitle(e.target.value); }}
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="12">
                                    <Row>
                                        <Col md="12">
                                            <div className="mb-3">
                                                <Label>Announcement</Label>
                                                {/* <AvField
                                                    name="courseTypeDescription"
                                                    value={courseAnnouncement}
                                                    onChange={(e) => { setCourseAnnouncement(e.target.value); }}
                                                    type="textarea"
                                                    className="form-control"
                                                /> */}
                                                <Editor
                                                    editorState={overviewState}
                                                    onEditorStateChange={handleOverviewChange}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <div className="actions clearfix">
                                    <Button
                                        style={{ float: "right", marginLeft: "10px" }}
                                        type="button"
                                        color="primary"
                                        onClick={addcourseAssignment}
                                    >{courseAssignmentIdx > -1 ? "Update" : "Add"}</Button>
                                    
                                    {courseAssignmentIdx > -1 &&
                                        <Button
                                            style={{ float: "right" }}
                                            type="button"
                                            color="danger"
                                            onClick={cancelcourseAssignmentEdit}
                                        >Cancel</Button>}

                                    {showCreateNewAnnouncement && 
                                    <div className="actions clearfix">
                                        <Button
                                            onClick={()=>setShowCreateNewAnnouncement(false)}
                                            style={{ float: "left" }}
                                            type="button"
                                            color="danger"
                                            >Cancel</Button>
                                    </div>}
                                    {/* {courseAssignments.length > 0 &&
                                    <div className="actions clearfix">
                                        <Button
                                            style={{ float: "right", marginLeft: "10px" }}
                                            type="submit"
                                            color="primary"
                                        >Publish Announcement</Button>
                                    </div>} */}
                                </div>
                                </>}
                                {!showCreateNewAnnouncement &&
                                <div className="actions clearfix">
                                    <Button
                                        onClick={()=>setShowCreateNewAnnouncement(true)}
                                        style={{ float: "left" }}
                                        type="button"
                                        color="primary"
                                        >Create New Announcement</Button>
                                </div>}
                                </>}
                                {/* <CardTitle typeof="h4" >Announcement List</CardTitle> */}
                                <br/>
                                {!showCreateNewAnnouncement &&
                                <Col md={12}>
                                    <div className="table-responsive">
                                        <Table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Date</th>
                                                    <th>Title</th>
                                                    {SHelper.userHasEditPermission() && <th>Status</th>}
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {courseAssignments.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <th scope="row">{i + 1}</th>
                                                            <td>{helper.formatDate(item.createdAt)}</td>
                                                            {/* <td>{item.courseAnnouncementTitle && item.courseAnnouncementTitle.length > 20 ? `${item.courseAnnouncementTitle.substring(0, 20)} ...` : item.courseAnnouncementTitle}</td> */}
                                                            <td>{item.courseAnnouncementTitle}</td>
                                                            {SHelper.userHasEditPermission() &&
                                                            <td>
                                                                <Box style={{cursor:"pointer"}} sx={{ display: courseAssignmentIdx === i ? 'none' : 'flex' }} onClick={() => { selectedItem.current=item.id; selectedIdx.current=i; setStatus_modal_center(true) }}>
                                                                    <ChangeCircle /> <Typography variant='h7' id={`academicIsActive${item.id}`}> {item.courseAnnouncementIsActive == 0 ? 'In Active' :'Active'}</Typography>
                                                                    {" "}<Tooltip arrow placement="left" title={`Make ${item.courseAnnouncementIsActive == 1 ? 'In Active' :'Active'}`}>
                                                                        <IconButton>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Box>
                                                            </td>}
                                                            <td>
                                                                <Box sx={{ display: courseAssignmentIdx === i ? 'none' : 'flex' }}>
                                                                        <Tooltip arrow placement="top" title="View">
                                                                            <IconButton onClick={() => { viewCourseAnnouncement(item.id, i) }}>
                                                                                <List />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        {SHelper.userHasEditPermission() && <>
                                                                        <Tooltip arrow placement="top" title="Delete">
                                                                            <IconButton style={{ color: "red" }} onClick={() => { selectedItem.current=item.id; selectedIdx.current=i; setDelete_modal_center(true) }}>
                                                                                <Delete />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        {item.courseAnnouncementIsActive == 1 && 
                                                                        <Tooltip arrow placement="top" title="Send Mail to All">
                                                                            <IconButton onClick={() => { selectedItem.current=item.id; selectedIdx.current=i; setAnnouncement_mail_modal(true) }}>
                                                                                <Mail />
                                                                            </IconButton>
                                                                        </Tooltip>}
                                                                        </>}
                                                                </Box>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>}
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
            }

        <Modal
            size="xl"
            isOpen={modal_announcement}
            centered={true}>

            <div className="modal-header">
              <h5 className="modal-title mt-0">{announcement.courseAnnouncementTitle}</h5>
              <button
                type="button"
                onClick={()=>{setDelete_modal_center(false); setModal_announcement(false)}}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              > 
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p><div dangerouslySetInnerHTML={{ __html: announcement.courseAnnouncement }} style={{padding:"10px"}}></div></p>
            </div>
          </Modal>

          <CustomModal modelShow={status_modal_center} handleCallback={statusChangecourseAnnouncement} bodyMsg={"Do you want to change active status ?"}/>
          <CustomModal modelShow={delete_modal_center} handleCallback={deletecourseAnnouncement} bodyMsg={"Do you want to delete this data ?"}/>
          <CustomModal modelShow={announcement_mail_modal} handleCallback={sendAnnouncementMail} bodyMsg={"Would you like to send this announcement email to all of your students?"}/>
          
        </Row>
    )
}

export default Model
