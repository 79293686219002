import React, { useMemo, useEffect, useState, useRef } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import * as SHelper from "../../helpers/session_helper";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Input,
    Label,
    Modal,
    Table,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableLoader from "../../components/Common/TableLoader"
import Axios from "../../helpers/axios_helper"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { toast,Slide } from 'react-toastify/dist/react-toastify';
import MaterialReactTable from 'material-react-table';

const options = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];

const DatatableTables = (props) => {
  const [modal_center, setmodal_center] = useState(false)
  const [quizQuestions, setQuizQuestions] = useState([])
  const [quizResults, setQuizResults] = useState(false)
  const [quizAnswers, setQuizAnswers] = useState([])

  const handleCallback = () =>{
    setmodal_center(false);
    if(props.handleCallback){
      props.handleCallback();
    }
  }

  const setQuizAnswerHandler = (questionId,selectedOption) =>{
    setQuizAnswers((prevAnswers) => {
      const existingIndex = prevAnswers.findIndex(answer => answer.questionId === questionId);

      if (existingIndex >= 0) {
        // Update existing item
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[existingIndex] = { questionId, selectedOption };
        return updatedAnswers;
      } else {
        // Add new item
        return [...prevAnswers, { questionId, selectedOption }];
      }
    });
  }

  const submitQuiz = async () => {
    const user= SHelper.getUser();
    const body = {
      courseQuizId:props.startQuiz.quizId,
      studentId:user.user_academicProfileId,
      selectedAnswers:quizAnswers
    }
    await Axios.post(`/quiz/participate`,body)
      .then((response) => {
        if (response.data.status === 200) {
          setQuizQuestions([]);
          setmodal_center(false);
          toast.success('Quiz Submission Successfull', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            transition: Slide,
          });
        }
        else{
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            transition: Slide,
          });
        }
      })
      .catch((error)=>{
          toast.error(error.message ?? "Data fetching failed !", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
              transition: Slide,
            });
      })
  }
  useEffect(async () => {
    window.sLoader();
    if(props.startQuiz && props.startQuiz.quizId){
      if(props.startQuiz.showResultOnly){
        await Axios.get(`/quiz/${props.startQuiz.quizId}`)
        .then((response) => {
          if (response.data.status === 200) {
            setQuizResults(response.data.data);
          }
        })
        .catch((error)=>{
            toast.error(error.message ?? "Data fetching failed !", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                transition: Slide,
              });
        })
      }
      else{
        await Axios.get(`/question-bank/quiz/${props.startQuiz.quizId}?setName=${props.startQuiz.setName}`)
        .then((response) => {
          if (response.data.status === 200) {
            setQuizQuestions(response.data.data);
          }
          else {
            setQuizQuestions([])
          }
        })
        .catch((error)=>{
            toast.error(error.message ?? "Data fetching failed !", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                transition: Slide,
              });
        })
      }
      setmodal_center(true);
    }
    window.hLoader();
  }, [props.startQuiz]);
  //#endregion

  return (
    <>
        <Modal
            size="xl"
            isOpen={modal_center}
            centered={true}>

            <div className="modal-header">
              <h5 className="modal-title mt-0">Quiz Questions</h5>
              <button
                type="button"
                onClick={handleCallback}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              > 
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{padding:"0"}}>
              <CardBody>
              {quizQuestions.length>0 && 
              <Row>
                {quizQuestions.map((item,i)=>{
                  var options = [];
                  try {
                    var inputString = item.options;
                    inputString =inputString.replace(/\\/g, '');
                    if (inputString.startsWith('"') && inputString.endsWith('"')) {
                        inputString = inputString.slice(1, -1);
                    }
                    options=JSON.parse(inputString);
                  } catch (error) {
                    console.log(error)
                  }
                  
                  return (
                    <Col md={6} key={i} className='mb-3'>
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label"><h5>Q {i+1}: <span style={{fontWeight:"bold"}}>{item.questionText}</span></h5></FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                          >
                            <Row>
                              {options.map((option,j)=>{
                                return (
                                  <Col md={6} key={j}>
                                    <FormControlLabel value={option} control={<Radio />} label={option} onClick={()=>{setQuizAnswerHandler(item.id,option)}}/>
                                  </Col>
                                )
                              })}
                            </Row>
                          </RadioGroup>
                        </FormControl>
                    </Col>
                  )
                })}
                
                
              </Row>}

              {quizResults &&<>
                <h5 style={{textAlign:"center"}}>Quiz Score : {quizResults.score}</h5><br/>
                <div className="table-responsive">
                      <Table className="table-centered">
                          <thead>
                              <tr>
                                  <th scope="col">Question</th>
                                  <th scope="col">Selected Answer</th>
                                  <th scope="col">Correct Answer</th>
                              </tr>
                          </thead>
                          <tbody>
                              {quizResults.questions.map((item, i)=>{
                                  return(
                                      <tr key={i}>
                                          <td>{item.questionText}</td>
                                          <td>{item.selectedAnswer}</td>
                                          <td>{item.correctAnswer}</td>
                                      </tr>
                                  )
                              })}
                              
                          </tbody>
                      </Table>
                  </div>
                  </>
              }
              </CardBody>
            </div>
            <div className="modal-footer customBtnArea" style={{textAlign: 'right'}}>
            {quizQuestions.length>0 ?
                <Button color="primary" type="button" className="btn" onClick={()=>submitQuiz()}>
                    Submit Quiz
                </Button>
                :
                <Button color="danger" type="button" className="btn" onClick={handleCallback}>
                    Close
                </Button>}
            </div>
          </Modal>
    </>
  )
}

export default DatatableTables
