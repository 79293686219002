import React, { useState, useEffect, useRef } from "react"
import {
    Label,
    Card,
    CardBody,
    Button,
    Col,
    Table,
    Input,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardTitle,
    CardImg,
} from "reactstrap";
import { Delete,Edit } from '@mui/icons-material';
import {
    Box,
    IconButton,
    Tooltip,
  } from '@mui/material';
import classnames from "classnames"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';

import Axios from "../../helpers/axios_helper"
import * as CHelper from "../../helpers/custom_helper"
import { useHistory, Link } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../assets/images/users/avatar.jpg";
import "./model.scss"

const genderList = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" }
]

const Model = (props) => {
    const history = useHistory();
    const [noPaddingStyle, setNoPaddingStyle] = useState({});
    const [activeTab, setactiveTab] = useState(1);
    const [addressDetails, setAddressDetails] = useState([]);
    const [updateData, setUpdateData] = useState(false);
    const [instructorDateOfBirth, setInstructorDateOfBirth] = useState(false);
    const [sameAsPresentAddress, setSameAsPresentAddress] = useState(false);
    const [imageSrc, setImageSrc] = useState(mediaImage);
    const [files, setFiles] = useState([]);
    const [countries, setCountries] = useState([]);
    const [divisions, setDivisions] = useState([]);

    const [presentAddress, setPresentAddress] = useState();
    const [presentVillage, setPresentVillage] = useState();
    const [presentCountryId, setPresentCountryId] = useState(0);
    const [presentDivisionId, setPresentDivisionId] = useState(0);
    const [presentZillas, setPresentZillas] = useState([]);
    const [presentZillaId, setPresentZillaId] = useState(0);
    const [presentUpazillas, setPresentUpazillas] = useState([]);
    const [presentUpazillaId, setPresentUpazillaId] = useState(0);

    const [permanentAddress, setPermanentAddress] = useState();
    const [permanentVillage, setPermanentVillage] = useState();
    const [permanentCountryId, setPermanentCountryId] = useState(0);
    const [permanentDivisionId, setPermanentDivisionId] = useState(0);
    const [permanentZillas, setPermanentZillas] = useState([]);
    const [permanentZillaId, setpermanentZillaId] = useState(0);
    const [permanentUpazillas, setpermanentUpazillas] = useState([]);
    const [permanentUpazillaId, setpermanentUpazillaId] = useState(0);

    const [paymentDetails, setPaymentDetails] = useState([{ idx: 0 }]);
    const [paymentById, setPaymentById] = useState(false);
    const [gender, setGender] = useState(0);

    const [publicationIdx, setPublicationIdx] = useState(-1);
    const [publicationId, setPublicationId] = useState(null);
    const [publicationName, setPublicationName] = useState('');
    const [publicationDescription, setPublicationDescription] = useState('');
    const [publications, setPublications] = useState([]);

    const [fellowAndFundingIdx, setFellowAndFundingIdx] = useState(-1);
    const [fellowAndFundingId, setFellowAndFundingId] = useState(null);
    const [fellowAndFundingName, setFellowAndFundingName] = useState('');
    const [fellowAndFundingYear, setFellowAndFundingYear] = useState('');
    const [fellowAndFundingAmount, setFellowAndFundingAmount] = useState('');
    const [fellowAndFundingDescription, setFellowAndFundingDescription] = useState('');
    const [fellowAndFundings, setFellowAndFundings] = useState([]);

    const [supervisionIdx, setSupervisionIdx] = useState(-1);
    const [supervisionId, setSupervisionId] = useState(null);
    const [supervisionName, setSupervisionName] = useState('');
    const [supervisionYear, setSupervisionYear] = useState('');
    const [supervisionPosition, setSupervisionPosition] = useState('');
    const [supervisionDescription, setSupervisionDescription] = useState('');
    const [supervisions, setSupervisions] = useState([]);

    const [careerState, setCareerState] = useState(EditorState.createEmpty());
    const [careerContent, setCareerContent] = useState();

    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 6) {
                setactiveTab(tab)
            }
        }
    }

    const handleCareerChange = (newEditorState) => {
        setCareerState(newEditorState);
        setCareerContent(stateToHTML(newEditorState.getCurrentContent()));
    };

    const handleSubmit = async (event, errors, values) => {
        if (errors.length > 0) {
            errors.forEach(element => {
                toast.error(`${CHelper.separateCamelCaseString(element)} is required !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }
        else if (imageSrc == mediaImage) {
            toast.error(`Profile Picture is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else {
            window.sLoader();
            // #region Address
            values.addressDetails[0].addressDetailAddressTypeId = 2;
            values.addressDetails[0].addressDetailAddress = presentAddress;
            values.addressDetails[0].addressDetailVillage = presentVillage;
            values.addressDetails[0].addressDetailCountryId = presentCountryId > 0 ? presentCountryId : null;
            values.addressDetails[0].addressDetailDivisionId = presentDivisionId > 0 ? presentDivisionId : null;
            values.addressDetails[0].addressDetailDistrictId = presentZillaId > 0 ? presentZillaId : null;
            values.addressDetails[0].addressDetailUpazilaId = presentUpazillaId > 0 ? presentUpazillaId : null;

            values.addressDetails[1].addressDetailAddressTypeId = 3;
            values.addressDetails[1].addressDetailAddress = permanentAddress;
            values.addressDetails[1].addressDetailVillage = permanentVillage;
            values.addressDetails[1].addressDetailCountryId = permanentCountryId > 0 ? permanentCountryId : null;
            values.addressDetails[1].addressDetailDivisionId = permanentDivisionId > 0 ? permanentDivisionId : null;
            values.addressDetails[1].addressDetailDistrictId = permanentZillaId > 0 ? permanentZillaId : null;
            values.addressDetails[1].addressDetailUpazilaId = permanentUpazillaId > 0 ? permanentUpazillaId : null;
            // #endregion

            if (props.id && props.id == updateData.id) {

                if (updateData.addressDetails.length > 0) {
                    for (let index = 0; index < updateData.addressDetails.length; index++) {
                        values.addressDetails[index].id = updateData.addressDetails[index].id;
                    }
                }

                const jsonData = {
                    instructorFirstName: values.instructorFirstName,
                    instructorMiddleName: values.instructorMiddleName,
                    instructorLastName: values.instructorLastName,
                    instructorBiography: values.instructorBiography,
                    instructorEmail: values.instructorEmail,
                    instructorContactNo: values.instructorContactNo,
                    instructorNID: values.instructorNID,
                    instructorBirthCertNo: values.instructorBirthCertNo,
                    instructorGender: gender,
                    instructorUsername: values.instructorUsername,
                    instructorPassword: values.instructorPassword,
                    instructorDateOfBirth: instructorDateOfBirth,
                    instructorCareer:careerContent,
                    addresses: values.addressDetails,
                    publications: publications,
                    fellowAndFundings: fellowAndFundings,
                    supervisions: supervisions
                }

                await Axios.patch(`/instructorProfile/id/${updateData.id}`, jsonData)
                    .then(async (response) => {
                        if (response.data.status === 200) {

                            let errorFound = false;
                            let formData = new FormData();
                            let removeFiles = []

                            //#region instructorProfile File Upload
                            if (files.length > 0) {
                                for (let i = 0; i < files.length; i++) {
                                    formData.append('file', files[i]);
                                }
                                updateData.instructorProfileFiles.forEach(element => {
                                    removeFiles.push(element.name)
                                });
                                formData.append('remove', JSON.stringify(removeFiles));
                                await Axios.post(`/file/upload/instructorProfile&${updateData.id}`, formData, {
                                })
                                    .catch(function (error) {
                                        toast.error("Profile Picture Uploaded Failed !", {
                                            position: toast.POSITION.TOP_RIGHT,
                                            autoClose: 3000,
                                        });
                                    });
                            }

                            //#endregion

                            if (!errorFound) {
                                if (props.handleCallback) {
                                    props.handleCallback()
                                }
                                toast.success('Instructor Profile Updated successfully!', {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 3000,
                                });
                            }
                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }
            else {
                const jsonData = {
                    instructorFirstName: values.instructorFirstName,
                    instructorMiddleName: values.instructorMiddleName,
                    instructorLastName: values.instructorLastName,
                    instructorBiography: values.instructorBiography,
                    instructorEmail: values.instructorEmail,
                    instructorContactNo: values.instructorContactNo,
                    instructorNID: values.instructorNID,
                    instructorBirthCertNo: values.instructorBirthCertNo,
                    instructorGender: gender,
                    instructorUsername: values.instructorUsername,
                    instructorPassword: values.instructorPassword,
                    instructorDateOfBirth: instructorDateOfBirth,
                    instructorCareer:careerContent,
                    addresses: values.addressDetails,
                    publications: publications,
                    fellowAndFundings: fellowAndFundings,
                    supervisions: supervisions
                }

                await Axios.post("/instructorProfile", jsonData)
                    .then(async (response) => {
                        if (response.data.status === 201) {
                            let errorFound = false;

                            //#region instructorProfile File Upload
                            let formData = new FormData();
                            for (let i = 0; i < files.length; i++) {
                                formData.append('file', files[i]);
                            }

                            await Axios.post(`/open/file/upload/instructorProfile&${response.data.id}`, formData, {
                            })
                                .catch(function (error) {
                                    toast.error("Profile Picture Uploaded Failed !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                });
                            //#endregion

                            if (!errorFound) {
                                toast.success('Instructor Profile created successfully!', {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 3000,
                                });
                                if(props.userDetailId && props.userDetailId>0 && !props.id){
                                    await Axios.patch(`/user/details/id/${props.userDetailId}`, {
                                        instructorProfileId:response.data.id
                                    })
                                }
                                else{
                                    setTimeout(() => {
                                        history.push("/instructor-profile-list");
                                    }, 1000);
                                }
                            }

                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }

            window.hLoader();
        }

    }

    const profilePictureChangeEvent = (event) => {
        let documentFile = event.target.files[0];
        setFiles([...event.target.files]);
        CHelper.convertToWebP(documentFile, setImageSrc);
    }

    const divisionChangeHandler = async (id, state = false) => {
        const data = divisions.filter(x => x.value == id)[0]
        await Axios.get("/address/districts/" + data.code)
            .then((response) => {
                if (response.data.status === 200) {
                    if (state) {
                        setPresentZillas(response.data.data);
                        setPresentZillaId(0);
                        setPresentUpazillaId(0);
                    }
                    else {
                        setPermanentZillas(response.data.data);
                        setpermanentZillaId(0);
                        setpermanentUpazillaId(0);
                    }
                }
            }
            );
    }

    const districtChangeHandler = async (id, state) => {
        const data = state ? presentZillas.filter(x => x.value == id)[0] : permanentZillas.filter(x => x.value == id)[0]
        await Axios.get("/address/upazilas/" + data.code)
            .then((response) => {
                if (response.data.status === 200) {
                    if (state) {
                        setPresentUpazillas(response.data.data);
                        setPresentUpazillaId(0);
                    }
                    else {
                        setpermanentUpazillas(response.data.data);
                        setpermanentUpazillaId(0);
                    }
                }
            });
    }

    useEffect(() => {
        if (sameAsPresentAddress) {
            setPermanentAddress(presentAddress);
            setPermanentVillage(presentVillage);
            setPermanentZillas(presentZillas);
            setpermanentUpazillas(presentUpazillas);
            setPermanentCountryId(presentCountryId);
            setPermanentDivisionId(presentDivisionId);
            setpermanentZillaId(presentZillaId);
            setpermanentUpazillaId(presentUpazillaId);
        }
    }, [presentAddress, presentVillage, sameAsPresentAddress, presentCountryId, presentDivisionId, presentZillaId, presentUpazillaId])

    //#region Publication
    const addPublication = () =>{
        if(publicationIdx>-1){
            publications[publicationIdx].id=publicationId;
            publications[publicationIdx].publicationName=publicationName;
            publications[publicationIdx].publicationDescription=publicationDescription;
        }
        else{
            publications.push({
                id:publicationId,
                publicationName:publicationName,
                publicationDescription:publicationDescription
            });
        }
        setPublications(publications);
        setPublicationId(null);
        setPublicationIdx(-1);
        setPublicationName('')
        setPublicationDescription('')
    }
    const editPublication = (id,idx) =>{
        setPublicationId(id);
        setPublicationIdx(idx);
        setPublicationName(publications[idx].publicationName);
        setPublicationDescription(publications[idx].publicationDescription);
    }
    const deletePublication = (id,idx) =>{
        
    }
    const cancelPublicationEdit = () =>{
        setPublicationId(null);
        setPublicationIdx(-1);
        setPublicationName('');
        setPublicationDescription('');
    }
    //#endregion

    //#region fellowAndFunding
    const addfellowAndFunding = () =>{
        if(fellowAndFundingIdx>-1){
            fellowAndFundings[fellowAndFundingIdx].id=fellowAndFundingId;
            fellowAndFundings[fellowAndFundingIdx].fellowAndFundingName=fellowAndFundingName;
            fellowAndFundings[fellowAndFundingIdx].fellowAndFundingDescription=fellowAndFundingDescription;
            fellowAndFundings[fellowAndFundingIdx].fellowAndFundingYear=fellowAndFundingYear;
            fellowAndFundings[fellowAndFundingIdx].fellowAndFundingAmount=fellowAndFundingAmount
        }
        else{
            fellowAndFundings.push({
                id:fellowAndFundingId,
                fellowAndFundingName:fellowAndFundingName,
                fellowAndFundingDescription:fellowAndFundingDescription,
                fellowAndFundingYear:fellowAndFundingYear,
                fellowAndFundingAmount:fellowAndFundingAmount
            });
        }
        setFellowAndFundings(fellowAndFundings);
        setFellowAndFundingId(null)
        setFellowAndFundingIdx(-1)
        setFellowAndFundingName('')
        setFellowAndFundingAmount('')
        setFellowAndFundingYear('')
        setFellowAndFundingDescription('')
    }
    const editfellowAndFunding = (id,idx) =>{
        setFellowAndFundingId(id);
        setFellowAndFundingIdx(idx);
        setFellowAndFundingName(fellowAndFundings[idx].fellowAndFundingName);
        setFellowAndFundingYear(fellowAndFundings[idx].fellowAndFundingYear);
        setFellowAndFundingAmount(fellowAndFundings[idx].fellowAndFundingAmount);
        setFellowAndFundingDescription(fellowAndFundings[idx].fellowAndFundingDescription);
    }
    const deletefellowAndFunding = (id,idx) =>{
        
    }
    const cancelfellowAndFundingEdit = () =>{
        setFellowAndFundingId(null)
        setFellowAndFundingIdx(-1)
        setFellowAndFundingName('')
        setFellowAndFundingAmount('')
        setFellowAndFundingYear('')
        setFellowAndFundingDescription('')
    }
    //#endregion

    //#region supervision
    const addSupervision = () =>{
        if(supervisionIdx>-1){
            supervisions[supervisionIdx].id=supervisionId;
            supervisions[supervisionIdx].supervisionName=supervisionName;
            supervisions[supervisionIdx].supervisionDescription=supervisionDescription;
            supervisions[supervisionIdx].supervisionYear=supervisionYear;
            supervisions[supervisionIdx].supervisionPosition=supervisionPosition
        }
        else{
            supervisions.push({
                id:supervisionId,
                supervisionName:supervisionName,
                supervisionDescription:supervisionDescription,
                supervisionYear:supervisionYear,
                supervisionPosition:supervisionPosition
            });
        }
        
        setSupervisions(supervisions);
        setSupervisionId(null)
        setSupervisionIdx(-1)
        setSupervisionName('')
        setSupervisionPosition('')
        setSupervisionYear('')
        setSupervisionDescription('')
    }
    const editSupervision = (id,idx) =>{
        setSupervisionId(id);
        setSupervisionIdx(idx);
        setSupervisionName(supervisions[idx].supervisionName);
        setSupervisionYear(supervisions[idx].supervisionYear);
        setSupervisionPosition(supervisions[idx].supervisionPosition);
        setSupervisionDescription(supervisions[idx].supervisionDescription);
    }
    const deleteSupervision = (id,idx) =>{
        
    }
    const cancelSupervisionEdit = () =>{
        setSupervisionId(null)
        setSupervisionIdx(-1)
        setSupervisionName('')
        setSupervisionPosition('')
        setSupervisionYear('')
        setSupervisionDescription('')
    }
    //#endregion

    useEffect(async () => {

        await Axios.get("/address/countries")
            .then((response) => {
                if (response.data.status === 200) {
                    setCountries(response.data.data);
                }
            });

        await Axios.get("/address/divisions")
            .then((response) => {
                if (response.data.status === 200) {
                    setDivisions(response.data.data);
                }
            });

        if (props.id && props.id > 0) {

            await Axios.get(`/instructorProfile/details/id/${props.id}`)
                .then((response) => {
                    if (response.data.status === 200) {
                        // setCourseTypeId(response.data.data.courseTypeId);
                        setUpdateData(response.data.data);
                        setInstructorDateOfBirth(response.data.data.instructorDateOfBirth)
                        setPublications(response.data.data.publications)
                        setFellowAndFundings(response.data.data.fellowAndFundings)
                        setSupervisions(response.data.data.supervisions)
                        setGender(response.data.data.instructorGender)
                        setSameAsPresentAddress(response.data.data.instructorSameAsPresentAddress)

                        setCareerContent(response.data.data.instructorCareer);
                        const eventOutline = EditorState.createWithContent(stateFromHTML(response.data.data.instructorCareer));
                        setCareerState(eventOutline)
                        
                        if (response.data.data.addressDetails && response.data.data.addressDetails.length == 0) {
                            let addresses = [];
                            addresses.push({ addressDetailAddressTypeId: 2 });
                            addresses.push({ addressDetailAddressTypeId: 3 });
                            setAddressDetails(addresses);

                        }
                        else {
                            setAddressDetails(response.data.data.addressDetails);

                            let presentAddress = response.data.data.addressDetails[0];
                            setPresentAddress(presentAddress.addressDetailAddress);
                            setPresentVillage(presentAddress.addressDetailVillage);
                            setPresentCountryId(presentAddress.addressDetailCountryId);
                            setPresentDivisionId(presentAddress.addressDetailDivisionId);
                            setPresentZillaId(presentAddress.addressDetailDistrictId);
                            setPresentUpazillaId(presentAddress.addressDetailUpazilaId);

                            let permanentAddress = response.data.data.addressDetails[1];
                            setPermanentAddress(permanentAddress.addressDetailAddress);
                            setPermanentVillage(permanentAddress.addressDetailVillage);
                            setPermanentCountryId(permanentAddress.addressDetailCountryId);
                            setPermanentDivisionId(permanentAddress.addressDetailDivisionId);
                            setpermanentZillaId(permanentAddress.addressDetailDistrictId);
                            setpermanentUpazillaId(permanentAddress.addressDetailUpazilaId);

                        }

                        if (response.data.data.instructorProfileFiles && response.data.data.instructorProfileFiles.length > 0) {
                            setImageSrc(CHelper.getsimplifyLink(response.data.data.instructorProfileFiles[0].link))
                        }
                    }
                })

            await Axios.get("/address/districts")
                .then((response) => {
                    if (response.data.status === 200) {
                        setPresentZillas(response.data.data);
                        setPermanentZillas(response.data.data);
                    }
                });

            await Axios.get("/address/upazilas")
                .then((response) => {
                    if (response.data.status === 200) {
                        setPresentUpazillas(response.data.data);
                        setpermanentUpazillas(response.data.data);
                    }
                });
        }

        if(props.noPadding){
            setNoPaddingStyle({
                paddingTop:"0"
            })
        }

        return () => {
            // Cancel any outstanding requests or subscriptions here
        };
    }, [props.id]);

    return (
        <Row>
            {(!props.id || props.id === 0 || (props.id > 0 && updateData)) &&
                <Col xl="12" style={{ margin: "auto" }}>
                    <Card>
                        <CardBody style={noPaddingStyle}>
                            <AvForm className="needs-validation" onSubmit={handleSubmit}>
                                <Row>
                                    <Col lg="12">
                                        <Card>
                                            <CardBody style={noPaddingStyle}>
                                                <div className="form-wizard-wrapper wizard clearfix">
                                                    <div className="steps instructorCustomSteps clearfix">
                                                        <ul>
                                                            <NavItem className={classnames({ current: activeTab === 1 })}>
                                                                <NavLink
                                                                    className={classnames({ current: activeTab === 1 })}
                                                                    onClick={() => {
                                                                        setactiveTab(1)
                                                                    }}
                                                                >
                                                                    {!noPaddingStyle && <span className="number">1</span>}
                                                                    General
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem className={classnames({ current: activeTab === 2 })}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === 2 })}
                                                                    onClick={() => {
                                                                        setactiveTab(2)
                                                                    }}
                                                                >
                                                                    {!noPaddingStyle && <span className="number">2</span>}
                                                                    Career
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem className={classnames({ current: activeTab === 3 })}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === 3 })}
                                                                    onClick={() => {
                                                                        setactiveTab(3)
                                                                    }}
                                                                >
                                                                    {!noPaddingStyle && <span className="number">3</span>}
                                                                    Publications
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem className={classnames({ current: activeTab === 4 })}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === 4 })}
                                                                    onClick={() => {
                                                                        setactiveTab(4)
                                                                    }}
                                                                >
                                                                    {!noPaddingStyle && <span className="number">4</span>}
                                                                    Fellows and Funding
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem className={classnames({ current: activeTab === 5 })}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === 5 })}
                                                                    onClick={() => {
                                                                        setactiveTab(5)
                                                                    }}
                                                                >
                                                                    {!noPaddingStyle && <span className="number">5</span>}
                                                                    Supervision
                                                                </NavLink>
                                                            </NavItem>
                                                        </ul>
                                                    </div>
                                                    <div className="content clearfix">
                                                        <TabContent
                                                            activeTab={activeTab}
                                                            className="body"
                                                        >
                                                            <TabPane tabId={1}>

                                                                <Row>
                                                                    <Col md={8}>
                                                                        <Row>
                                                                            <Col md="4">
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="instructorFirstName">First name <span className="requiredSign">*</span></Label>
                                                                                    <AvField
                                                                                        name="instructorFirstName"
                                                                                        defaultValue={updateData.instructorFirstName}
                                                                                        // placeholder="First Name"
                                                                                        type="text"
                                                                                        errorMessage={`Please provide ${CHelper.separateCamelCaseString('firstName')}`}
                                                                                        className="form-control"
                                                                                        required
                                                                                        id="instructorFirstName"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md="4">
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="instructorMiddleName">Middle name</Label>
                                                                                    <AvField
                                                                                        name="instructorMiddleName"
                                                                                        defaultValue={updateData.instructorMiddleName}
                                                                                        // placeholder="Middle Name"
                                                                                        type="text"
                                                                                        errorMessage={`Please provide ${CHelper.separateCamelCaseString('middleName')}`}
                                                                                        className="form-control"
                                                                                        id="instructorMiddleName"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md="4">
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="instructorLastName">Last name <span className="requiredSign">*</span></Label>
                                                                                    <AvField
                                                                                        name="instructorLastName"
                                                                                        defaultValue={updateData.instructorLastName}
                                                                                        // placeholder="Last Name"
                                                                                        type="text"
                                                                                        errorMessage={`Please provide ${CHelper.separateCamelCaseString('lastName')}`}
                                                                                        className="form-control"
                                                                                        required
                                                                                        id="instructorLastName"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md="12">
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="instructorBiography">Write a Sort Biography on yourself <span className="requiredSign">*</span></Label>
                                                                                    <AvField
                                                                                        name="instructorBiography"
                                                                                        defaultValue={updateData.instructorBiography}
                                                                                        // placeholder="Biography"
                                                                                        type="textarea"
                                                                                        errorMessage={`Please provide ${CHelper.separateCamelCaseString('biography')}`}
                                                                                        className="form-control"
                                                                                        required
                                                                                        id="instructorBiography"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md="6">
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="instructorEmail">Email <span className="requiredSign">*</span></Label>
                                                                                    <AvField
                                                                                        name="instructorEmail"
                                                                                        defaultValue={updateData.instructorEmail}
                                                                                        // placeholder="Email"
                                                                                        type="email"
                                                                                        errorMessage={`Please provide ${CHelper.separateCamelCaseString('email')}`}
                                                                                        className="form-control"
                                                                                        required
                                                                                        id="instructorEmail"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md="6">
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="instructorContactNo">Contact no <span className="requiredSign">*</span></Label>
                                                                                    <AvField
                                                                                        name="instructorContactNo"
                                                                                        defaultValue={updateData.instructorContactNo}
                                                                                        // placeholder="Contact No"
                                                                                        type="text"
                                                                                        errorMessage={`Please provide ${CHelper.separateCamelCaseString('contactNo')}`}
                                                                                        className="form-control"
                                                                                        // validate={{ required: { value: true }, match:{value:'instructorEmail'} }}
                                                                                        required
                                                                                        id="instructorContactNo"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <Col md={12}>
                                                                            <div className="mb-3" style={{ textAlign: "center" }}>
                                                                                <CardImg className="img-fluid" src={imageSrc} alt="" style={{ maxHeight: "250px", minHeight: "220px", width: "auto" }} />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={12}>
                                                                            <div className="mb-3 justify-content-center d-flex flex-row">
                                                                                <label type="button" className="btn btn btn-info col-md-5" style={{ textAlign: "center" }}><i className="fa fa-upload"></i> Profile Picture<input hidden onChange={profilePictureChangeEvent} accept="image/png, image/gif, image/jpeg, image/jpg" type="file" /></label>
                                                                                <label hidden style={{ textAlign: "center" }} type="button" onClick={() => CHelper.downloadImage(imageSrc)} className="btn btn btn-primary col-md-5"><i className="fa fa-download"></i> Download Image</label>
                                                                            </div>
                                                                        </Col>
                                                                    </Col>

                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="instructorDateOfBirth">Date of Birth <span className="requiredSign">*</span></Label>
                                                                            <Flatpickr
                                                                                name="instructorDateOfBirth"
                                                                                id="instructorDateOfBirth"
                                                                                className="form-control d-block"
                                                                                // placeholder="dd M, yyyy"
                                                                                options={{
                                                                                    altInput: true,
                                                                                    altFormat: "F j, Y",
                                                                                    dateFormat: "Y-m-d",
                                                                                    defaultDate: updateData.instructorDateOfBirth ? new Date(updateData.instructorDateOfBirth) : false
                                                                                }}
                                                                                onChange={(selectedDates, dateStr, instance) => {
                                                                                    setInstructorDateOfBirth(dateStr);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="instructorNID">National ID <span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name="instructorNID"
                                                                                defaultValue={updateData.instructorNID}
                                                                                // placeholder="National ID"
                                                                                type="text"
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('nationalID')}`}
                                                                                className="form-control"
                                                                                required
                                                                                id="instructorNID"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="instructorBirthCertNo">Birth Registration No</Label>
                                                                            <AvField
                                                                                name="instructorBirthCertNo"
                                                                                defaultValue={updateData.instructorBirthCertNo}
                                                                                // placeholder="Birth Registration No"
                                                                                type="text"
                                                                                // errorMessage={`Please provide ${CHelper.separateCamelCaseString('birthRegNo')}`}
                                                                                className="form-control"
                                                                                // validate={{ async: (value, ctx, input, cb) => { return ((ctx.instructorBirthCertNo != undefined && ctx.instructorBirthCertNo.length > 0) || (ctx.instructorNID != undefined && ctx.instructorNID.length > 0)) } }}
                                                                                id="instructorBirthCertNo"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="instructorGender">Gender <span className="requiredSign">*</span></Label>
                                                                            {/* <AvField
                                                                                name="instructorGender"
                                                                                defaultValue={updateData.instructorGender}
                                                                                // placeholder="Gender"
                                                                                type="text"
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('gender')}`}
                                                                                className="form-control"
                                                                                required
                                                                                id="instructorGender"
                                                                            /> */}

                                                                            <Select
                                                                                name={"instructorGender"}
                                                                                id={"instructorGender"}
                                                                                options={genderList}
                                                                                value={genderList.filter(x => x.value == gender)}
                                                                                onChange={(e) => { setGender(e.value); }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    {/* <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="instructorUsername">Username <span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name="instructorUsername"
                                                                                defaultValue={updateData.instructorUsername}
                                                                                // placeholder="Username"
                                                                                type="text"
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('username')}`}
                                                                                className="form-control"
                                                                                required
                                                                                id="instructorUsername"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="instructorPassword">Password <span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name="instructorPassword"
                                                                                defaultValue={updateData.instructorPassword}
                                                                                // placeholder="Password"
                                                                                type="text"
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('password')}`}
                                                                                className="form-control"
                                                                                required
                                                                                id="instructorPassword"
                                                                            />
                                                                        </div>
                                                                    </Col> */}
                                                                    <Col md={12} style={{ marginTop: "30px" }}>
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <h4 className="card-title">Present Address</h4>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="presentAddress">Address <span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name={"addressDetails[" + 0 + "].addressDetailAddress"}
                                                                                // placeholder="Address"
                                                                                type="text"
                                                                                value={presentAddress}
                                                                                onChange={(e) => setPresentAddress(e.target.value)}
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('presentAddress')}`}
                                                                                className="form-control"
                                                                                required
                                                                                id="presentAddress"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="presentCountry">Country <span className="requiredSign">*</span></Label>
                                                                            <Select
                                                                                name={"addressDetails[" + 0 + "].addressDetailCountryId"}
                                                                                id="presentCountry"
                                                                                options={countries}
                                                                                value={countries ? countries.filter(x => x.value == presentCountryId)[0] : null}
                                                                                onChange={(e) => { setPresentCountryId(e.value); }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="presentDivision">Division <span className="requiredSign">*</span></Label>
                                                                            <Select
                                                                                name={"addressDetails[" + 0 + "].addressDetailDivisionId"}
                                                                                id="presentDivision"
                                                                                options={divisions}
                                                                                value={divisions ? divisions.filter(x => x.value == presentDivisionId)[0] : null}
                                                                                onChange={(e) => { setPresentDivisionId(e.value); divisionChangeHandler(e.value, true); }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="presentZilla">Zilla <span className="requiredSign">*</span></Label>
                                                                            <Select
                                                                                name={"addressDetails[" + 0 + "].addressDetailDistrictId"}
                                                                                id="presentZilla"
                                                                                options={presentZillas}
                                                                                value={presentZillas && presentZillaId > 0 ? presentZillas.filter(x => x.value == presentZillaId)[0] : null}
                                                                                onChange={(e) => { setPresentZillaId(e.value); districtChangeHandler(e.value, true); }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="presentUpazila">Upazilla <span className="requiredSign">*</span></Label>
                                                                            <Select
                                                                                name={"addressDetails[" + 0 + "].addressDetailUpazilaId"}
                                                                                id="presentUpazila"
                                                                                options={presentUpazillas}
                                                                                value={presentUpazillas && presentUpazillaId > 0 ? presentUpazillas.filter(x => x.value == presentUpazillaId)[0] : null}
                                                                                onChange={(e) => { setPresentUpazillaId(e.value); }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="presentVillage">Vill/Suburb</Label>
                                                                            <AvField
                                                                                name={"addressDetails[" + 0 + "].addressDetailVillage"}
                                                                                // placeholder="Village"
                                                                                type="text"
                                                                                value={presentVillage}
                                                                                onChange={(e) => setPresentVillage(e.target.value)}
                                                                                className="form-control"
                                                                                id={"addressDetailVillage[" + 0 + "]"}
                                                                            />
                                                                        </div>
                                                                    </Col>

                                                                    <Col md={12} style={{ marginTop: "30px" }}>
                                                                        <Row>
                                                                            <Col md={12} style={{ margin: "auto", display: "flex" }}>
                                                                                <h4 className="card-title" style={{}}>Permanent Address</h4>
                                                                                <div className="form-check form-switch" dir="ltr" style={{ marginLeft: "3%" }}>

                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="form-check-input"
                                                                                        id="sameAsPresentAddress"
                                                                                        checked={sameAsPresentAddress}
                                                                                        onClick={e => {
                                                                                            setSameAsPresentAddress(!sameAsPresentAddress);
                                                                                        }}
                                                                                    />

                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="sameAsPresentAddress"
                                                                                        style={{ marginTop: "0" }}
                                                                                    >
                                                                                        Same as present address
                                                                                    </label>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="permanentAddress">Address <span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name={"addressDetails[" + 1 + "].addressDetailAddress"}
                                                                                // placeholder="Address"
                                                                                type="text"
                                                                                value={permanentAddress}
                                                                                onChange={(e) => setPermanentAddress(e.target.value)}
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('permanentAddress')}`}
                                                                                className="form-control"
                                                                                readOnly={sameAsPresentAddress}
                                                                                required
                                                                                id="permanentAddress"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="permanentCountry">Country <span className="requiredSign">*</span></Label>
                                                                            <Select
                                                                                name={"addressDetails[" + 1 + "].permanentCountryId"}
                                                                                id="permanentCountry"
                                                                                isDisabled={sameAsPresentAddress}
                                                                                options={countries}
                                                                                value={countries ? countries.filter(x => x.value == permanentCountryId)[0] : null}
                                                                                onChange={(e) => { setPermanentCountryId(e.value); }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="permanentDivision">Division <span className="requiredSign">*</span></Label>
                                                                            <Select
                                                                                name={"addressDetails[" + 1 + "].permanentDivisionId"}
                                                                                id="permanentDivision"
                                                                                isDisabled={sameAsPresentAddress}
                                                                                options={divisions}
                                                                                value={divisions ? divisions.filter(x => x.value == permanentDivisionId)[0] : null}
                                                                                onChange={(e) => { setPermanentDivisionId(e.value); divisionChangeHandler(e.value); }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="permanentZilla">Zilla <span className="requiredSign">*</span></Label>
                                                                            <Select
                                                                                name={"addressDetails[" + 1 + "].permanentZillaId"}
                                                                                id="permanentZilla"
                                                                                isDisabled={sameAsPresentAddress}
                                                                                options={permanentZillas}
                                                                                value={permanentZillas && permanentZillaId > 0 ? permanentZillas.filter(x => x.value == permanentZillaId)[0] : null}
                                                                                onChange={(e) => { setpermanentZillaId(e.value); districtChangeHandler(e.value); }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="permanentUpazila">Upazilla <span className="requiredSign">*</span></Label>
                                                                            <Select
                                                                                name={"addressDetails[" + 1 + "].permanentUpazillaId"}
                                                                                id="permanentUpazila"
                                                                                isDisabled={sameAsPresentAddress}
                                                                                options={permanentUpazillas}
                                                                                value={permanentUpazillas && permanentUpazillaId > 0 ? permanentUpazillas.filter(x => x.value == permanentUpazillaId)[0] : null}
                                                                                onChange={(e) => { setpermanentUpazillaId(e.value); }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="permanentVillage">Vill/Suburb</Label>
                                                                            <AvField
                                                                                name={"addressDetails[" + 1 + "].addressDetailVillage"}
                                                                                // placeholder="Village"
                                                                                type="text"
                                                                                value={permanentVillage}
                                                                                readOnly={sameAsPresentAddress}
                                                                                onChange={(e) => setPermanentVillage(e.target.value)}
                                                                                className="form-control"
                                                                                id={"addressDetailVillage[" + 1 + "]"}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>

                                                            <TabPane tabId={2}>
                                                                <Row>
                                                                    <Col xs={12}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="CareerState">Career Details</Label>
                                                                            <Editor
                                                                                editorState={careerState}
                                                                                onEditorStateChange={handleCareerChange}
                                                                                toolbarClassName="toolbarClassName"
                                                                                wrapperClassName="wrapperClassName"
                                                                                editorClassName="editorClassName"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>

                                                            <TabPane tabId={3}>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor={"publicationName"}>Name / Title <span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name={"publicationName"}
                                                                                // placeholder="Designation"
                                                                                type="text"
                                                                                value={publicationName}
                                                                                onChange={(e)=>setPublicationName(e.target.value)}
                                                                                className="form-control"
                                                                                id={"publicationName"}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="publicationDescription">Description<span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name="publicationDescription"
                                                                                value={publicationDescription}
                                                                                onChange={(e)=>setPublicationDescription(e.target.value)}
                                                                                type="textarea"
                                                                                className="form-control"
                                                                                id="publicationDescription"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <div className="actions clearfix">
                                                                        <Button
                                                                            style={{ float: "right", marginLeft: "10px" }}
                                                                            type="button"
                                                                            color="primary"
                                                                            onClick={addPublication}
                                                                        >{ publicationIdx>-1 ? "Update":"Add"}</Button>
                                                                        { publicationIdx >-1 && 
                                                                        <Button
                                                                            style={{ float: "right" }}
                                                                            type="button"
                                                                            color="danger"
                                                                            onClick={cancelPublicationEdit}
                                                                        >Cancel</Button>}
                                                                    </div>
                                                                    <CardTitle typeof="h4" >Publications List</CardTitle>
                                                                    <Col md={12}>
                                                                        <div className="table-responsive">
                                                                            <Table className="table mb-0">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>#</th>
                                                                                        <th>Name / Title</th>
                                                                                        <th>Description</th>
                                                                                        <th>Action</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {publications.map((item,i)=>{
                                                                                        return (
                                                                                            <tr key={i}>
                                                                                                <th scope="row">{i+1}</th>
                                                                                                <td>{item.publicationName.length>20?`${item.publicationName.substring(0, 20)} ...`:item.publicationName}</td>
                                                                                                <td>{item.publicationDescription.length>20?`${item.publicationDescription.substring(0, 20)} ...`:item.publicationDescription}</td>
                                                                                                <td>
                                                                                                    <Box sx={{ display: publicationIdx===i?'none':'flex' }}>
                                                                                                        <Tooltip arrow placement="left" title="Edit">
                                                                                                        <IconButton onClick={() => {editPublication(item.id,i)}}>
                                                                                                            <Edit />
                                                                                                        </IconButton>
                                                                                                        </Tooltip>
                                                                                                        <Tooltip arrow placement="left" title="Delete">
                                                                                                        <IconButton style={{color:"red"}} onClick={() => {deletePublication(item.id,i)}}>
                                                                                                            <Delete />
                                                                                                        </IconButton>
                                                                                                        </Tooltip>
                                                                                                    </Box>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>

                                                            <TabPane tabId={4}>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor={"ffName"}>Name / Title <span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name={"ffName"}
                                                                                // placeholder="Designation"
                                                                                type="text"
                                                                                value={fellowAndFundingName}
                                                                                onChange={(e)=>setFellowAndFundingName(e.target.value)}
                                                                                className="form-control"
                                                                                id={"ffName"}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor={"ffAmount"}>Amount <span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name={"ffAmount"}
                                                                                // placeholder="Designation"
                                                                                type="number"
                                                                                value={fellowAndFundingAmount}
                                                                                onChange={(e)=>setFellowAndFundingAmount(e.target.value)}
                                                                                className="form-control"
                                                                                id={"ffAmount"}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor={"ffYear"}>Year <span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name={"ffYear"}
                                                                                // placeholder="Designation"
                                                                                type="number"
                                                                                min={1900}
                                                                                max={9999}
                                                                                value={fellowAndFundingYear}
                                                                                onChange={(e)=>setFellowAndFundingYear(e.target.value)}
                                                                                className="form-control"
                                                                                id={"ffYear"}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="ffDescription">Description<span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name="ffDescription"
                                                                                value={fellowAndFundingDescription}
                                                                                onChange={(e)=>setFellowAndFundingDescription(e.target.value)}
                                                                                // placeholder="Biography"
                                                                                type="textarea"
                                                                                className="form-control"
                                                                                id="ffDescription"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <div className="actions clearfix">
                                                                        <Button
                                                                            style={{ float: "right", marginLeft: "10px" }}
                                                                            type="button"
                                                                            color="primary"
                                                                            onClick={addfellowAndFunding}
                                                                        >{ fellowAndFundingIdx >-1 ? "Update":"Add"}</Button>
                                                                        { fellowAndFundingIdx >-1 && 
                                                                        <Button
                                                                            style={{ float: "right" }}
                                                                            type="button"
                                                                            color="danger"
                                                                            onClick={cancelfellowAndFundingEdit}
                                                                        >Cancel</Button>}
                                                                    </div>
                                                                    <CardTitle typeof="h4" >Fellows and Fundings List</CardTitle>
                                                                    <Col md={12}>
                                                                        <div className="table-responsive">
                                                                            <Table className="table mb-0">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>#</th>
                                                                                        <th>Name</th>
                                                                                        <th>Description</th>
                                                                                        <th>Amount</th>
                                                                                        <th>Year</th>
                                                                                        <th>Action</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {fellowAndFundings.map((item,i)=>{
                                                                                        return (
                                                                                            <tr key={i}>
                                                                                                <th scope="row">{i+1}</th>
                                                                                                <td>{item.fellowAndFundingName.length>20?`${item.fellowAndFundingName.substring(0, 20)} ...`:item.fellowAndFundingName}</td>
                                                                                                <td>{item.fellowAndFundingDescription.length>20?`${item.fellowAndFundingDescription.substring(0, 20)} ...`:item.fellowAndFundingDescription}</td>
                                                                                                <td>{item.fellowAndFundingAmount}</td>
                                                                                                <td>{item.fellowAndFundingYear}</td>
                                                                                                <td>
                                                                                                    <Box sx={{ display: fellowAndFundingIdx===i?'none':'flex' }}>
                                                                                                        <Tooltip arrow placement="left" title="Edit">
                                                                                                        <IconButton onClick={() => {editfellowAndFunding(item.id,i)}}>
                                                                                                            <Edit />
                                                                                                        </IconButton>
                                                                                                        </Tooltip>
                                                                                                        <Tooltip arrow placement="left" title="Delete">
                                                                                                        <IconButton style={{color:"red"}} onClick={() => {deletefellowAndFunding(item.id,i)}}>
                                                                                                            <Delete />
                                                                                                        </IconButton>
                                                                                                        </Tooltip>
                                                                                                    </Box>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>

                                                            <TabPane tabId={5}>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor={"supervisionName"}>Name / Title <span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name={"supervisionName"}
                                                                                // placeholder="Designation"
                                                                                type="text"
                                                                                value={supervisionName}
                                                                                onChange={(e)=>setSupervisionName(e.target.value)}
                                                                                className="form-control"
                                                                                id={"supervisionName"}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor={"supervisionPosition"}>Position <span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name={"supervisionPosition"}
                                                                                // placeholder="Designation"
                                                                                type="text"
                                                                                value={supervisionPosition}
                                                                                onChange={(e)=>setSupervisionPosition(e.target.value)}
                                                                                className="form-control"
                                                                                id={"supervisionPosition"}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor={"supervisionYear"}>Year <span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name={"supervisionYear"}
                                                                                // placeholder="Designation"
                                                                                type="number"
                                                                                min={1900}
                                                                                max={9999}
                                                                                value={supervisionYear}
                                                                                onChange={(e)=>setSupervisionYear(e.target.value)}
                                                                                className="form-control"
                                                                                id={"supervisionYear"}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="supervisionDescription">Description<span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name="supervisionDescription"
                                                                                value={supervisionDescription}
                                                                                onChange={(e)=>setSupervisionDescription(e.target.value)}
                                                                                // placeholder="Biography"
                                                                                type="textarea"
                                                                                className="form-control"
                                                                                id="supervisionDescription"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <div className="actions clearfix">
                                                                        <Button
                                                                            style={{ float: "right", marginLeft: "10px" }}
                                                                            type="button"
                                                                            color="primary"
                                                                            onClick={addSupervision}
                                                                        >{ supervisionIdx >-1 ? "Update":"Add"}</Button>
                                                                        { supervisionIdx >-1 && 
                                                                        <Button
                                                                            style={{ float: "right" }}
                                                                            type="button"
                                                                            color="danger"
                                                                            onClick={cancelSupervisionEdit}
                                                                        >Cancel</Button>}
                                                                    </div>
                                                                    <CardTitle typeof="h4" >Supervision List</CardTitle>
                                                                    <Col md={12}>
                                                                        <div className="table-responsive">
                                                                            <Table className="table mb-0">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>#</th>
                                                                                        <th>Name</th>
                                                                                        <th>Description</th>
                                                                                        <th>Position</th>
                                                                                        <th>Year</th>
                                                                                        <th>Action</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                {supervisions.map((item,i)=>{
                                                                                        return (
                                                                                            <tr key={i}>
                                                                                                <th scope="row">{i+1}</th>
                                                                                                <td>{item.supervisionName.length>20?`${item.supervisionName.substring(0, 20)} ...`:item.supervisionName}</td>
                                                                                                <td>{item.supervisionDescription.length>20?`${item.supervisionDescription.substring(0, 20)} ...`:item.supervisionDescription}</td>
                                                                                                <td>{item.supervisionPosition}</td>
                                                                                                <td>{item.supervisionYear}</td>
                                                                                                <td>
                                                                                                    <Box sx={{ display: supervisionIdx===i?'none':'flex' }}>
                                                                                                        <Tooltip arrow placement="left" title="Edit">
                                                                                                        <IconButton onClick={() => {editSupervision(item.id,i)}}>
                                                                                                            <Edit />
                                                                                                        </IconButton>
                                                                                                        </Tooltip>
                                                                                                        <Tooltip arrow placement="left" title="Delete">
                                                                                                        <IconButton style={{color:"red"}} onClick={() => {deleteSupervision(item.id,i)}}>
                                                                                                            <Delete />
                                                                                                        </IconButton>
                                                                                                        </Tooltip>
                                                                                                    </Box>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                        </TabContent>
                                                    </div>
                                                    <div className="actions clearfix">
                                                        {activeTab != 1 &&
                                                            <Button
                                                                style={{ float: "left" }}
                                                                type="button"
                                                                onClick={() => {
                                                                    toggleTab(activeTab - 1)
                                                                }}
                                                            >Previous</Button>
                                                        }
                                                        {activeTab === 5 ?
                                                            <Button color="primary" type="submit">
                                                                Submit
                                                            </Button>
                                                            :
                                                            <Link
                                                                to="#"
                                                                className="btn btn-primary"
                                                                onClick={() => {
                                                                    toggleTab(activeTab + 1)
                                                                }}
                                                            >Next</Link>
                                                        }
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
            }
        </Row>
    )
}

export default Model
