import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Card,
} from "reactstrap"
import * as SHelper from "../../helpers/session_helper"

const EmailSideBar = (props) => {
  
  const [page , setPage]=useState('announcement')

  const goto = (pageName) =>{
    return `/course?page=${pageName}`;
  }

  useEffect(()=>{
    if(props.pageName){
      setPage(props.pageName)
    }
  },[props.pageName])

  return (
    <React.Fragment>
      <Card className="email-leftbar">
        <div className="mail-list mt-1">
        {SHelper.userHasEditPermission() ? <>
          <Link to={goto('instructors')} className={page=="instructors"?"active":""}>
            <i className="mdi mdi-clipboard-account me-2"></i>Coordination Team
          </Link>
          <Link to={goto('announcement')} className={page=="announcement"?"active":""}>
            <i className="mdi mdi-star-outline me-2"></i>Announcement
          </Link>
          <h6 className="mt-2" style={{fontWeight:"bold"}}>Course Outline</h6>
          <div className="mail-list mt-1" style={{marginLeft:"10%"}}>
            <Link to={goto('syllabus')} className={page=="syllabus"?"active":""}>
              <i className="mdi mdi-star-outline me-2"></i>Content
            </Link>
            <Link to={goto('assesment-system')} className={page=="assesment-system"?"active":""}>
              <i className="mdi mdi-star-outline me-2"></i>Assessment
            </Link>
          </div>
          <Link to={goto('participants')} className={page=="participants"?"active":""}>
            <i className="mdi mdi-clipboard-account me-2"></i>Learners
          </Link>
          <Link to={goto('classes')} className={page=="classes"?"active":""}>
            <i className="mdi mdi-email-check-outline me-2"></i>Classes
          </Link>
          <Link to={goto('attendances')} className={page=="attendances"?"active":""}>
            <i className="mdi mdi-clipboard-account me-2"></i>Attendances
          </Link>
          <Link to={goto('files')} className={page=="files"?"active":""}>
            <i className="mdi mdi-email-check-outline me-2"></i>Materials
          </Link>
          <Link to={goto('rubric ')} className={page=="rubric"?"active":""}>
            <i className="mdi mdi-diamond-stone me-2"></i>Rubric
          </Link>
          <h6 className="mt-2" style={{fontWeight:"bold"}}>Assesment</h6>
          <div className="mail-list mt-1" style={{marginLeft:"10%"}}>
            <Link to={goto('course-quiz')} className={page=="course-quiz"?"active":""}>
              <i className="mdi mdi-diamond-stone me-2"></i>Quiz
            </Link>
            <Link to={goto('assignments')} className={page=="assignments"?"active":""}>
              <i className="mdi mdi-diamond-stone me-2"></i>Assignment
            </Link>
          </div>
          <Link to={goto('grade-center')} className={page=="grade-center"?"active":""}>
            <i className="mdi mdi-email-outline me-2"></i>Grade Center
          </Link>
          <Link to={goto('discussion')} className={page=="discussion"?"active":""}>
            <i className="mdi mdi-email-outline me-2"></i>Group Discussion
          </Link>
        </>
        :
        <>
        <Link to={goto('instructors')} className={page=="instructors"?"active":""}>
            <i className="mdi mdi-clipboard-account me-2"></i>Coordination Team
          </Link>
          <Link to={goto('announcement')} className={page=="announcement"?"active":""}>
            <i className="mdi mdi-star-outline me-2"></i>Announcement
          </Link>
          <h6 className="mt-2" style={{fontWeight:"bold"}}>Course Outline</h6>
          <div className="mail-list mt-1" style={{marginLeft:"10%"}}>
            <Link to={goto('syllabus')} className={page=="syllabus"?"active":""}>
              <i className="mdi mdi-star-outline me-2"></i>Content
            </Link>
            <Link to={goto('assesment-system')} className={page=="assesment-system"?"active":""}>
              <i className="mdi mdi-star-outline me-2"></i>Assessment
            </Link>
          </div>
          
          <Link to={goto('classes')} className={page=="classes"?"active":""}>
            <i className="mdi mdi-email-check-outline me-2"></i>Classes
          </Link>
          <Link to={goto('files')} className={page=="files"?"active":""}>
            <i className="mdi mdi-email-check-outline me-2"></i>Materials
          </Link>
          <Link to={goto('course-quiz')} className={page=="course-quiz"?"active":""}>
            <i className="mdi mdi-diamond-stone me-2"></i>Quiz
          </Link>
          <Link to={goto('assignments')} className={page=="assignments"?"active":""}>
            <i className="mdi mdi-diamond-stone me-2"></i>Assignments
          </Link>
          <Link to={goto('grade-center')} className={page=="grade-center"?"active":""}>
            <i className="mdi mdi-email-outline me-2"></i>Grade Center
          </Link>
          <Link to={goto('discussion')} className={page=="discussion"?"active":""}>
            <i className="mdi mdi-email-outline me-2"></i>Group Discussion
          </Link>
        </>}
        </div>
      </Card>

    </React.Fragment>
  )
}

export default EmailSideBar