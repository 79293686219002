import React, { useState, useEffect, useRef } from "react"


import {
    Label,
    Card,
    CardBody,
    Button,
    Col,
    Form,
    Input,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardTitle,
    CardImg,
} from "reactstrap"
import classnames from "classnames"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';
import Axios from "../../helpers/axios_helper"
import * as CHelper from "../../helpers/custom_helper"
import { useHistory, Link } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../assets/images/media.jpeg";
import './model.scss'
const moment = require('moment');

const Model = (props) => {
    const history = useHistory()
    const [activeTab, setactiveTab] = useState(1)
    const [roles, setroles] = useState([])
    const [roleId, setRoleId] = useState(0)
    const [updateData, setUpdateData] = useState(false)
    const [imageSrc, setImageSrc] = useState(mediaImage);

    const handleSubmit = async (event, errors, values) => {
        window.sLoader();
        if (errors.length > 0) {
            errors.forEach(element => {
                toast.error(`${CHelper.separateCamelCaseString(element)} is required !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }
        // else if (props.id && props.id > 0 && imageSrc == mediaImage) {
        //     toast.error(`Feature image is required !`, {
        //         position: toast.POSITION.TOP_RIGHT,
        //         autoClose: 3000,
        //     });
        // }
        // else if (!props.id && files.length == 0) {
        //     toast.error(`Feature image is required !`, {
        //         position: toast.POSITION.TOP_RIGHT,
        //         autoClose: 3000,
        //     });
        // }
        else {
            if (props.id && props.id == updateData.id) {
                const jsonData = {
                    id:updateData.id,
                    courseModuleName:values['courseModuleName'],
                    courseModuleDescription:values['courseModuleDescription']
                }

                await Axios.patch(`/module/id/${updateData.id}`, jsonData)
                    .then(async (response) => {
                        if (response.data.status === 200) {
                                toast.success('Updated successfully!', {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 3000,
                                });
                                props.handleCallback(response.data)
                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }
            else {
                const jsonData = {
                    courseModuleName:values['courseModuleName'],
                    courseModuleDescription:values['courseModuleDescription']
                }

                await Axios.post("/module", jsonData)
                    .then(async (response) => {
                        if (response.data.status === 201) {

                            toast.success('Subjects / Modules created successfully!', {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                            setTimeout(() => {
                                history.push("/course-module-list");
                            }, 1000);

                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }

        }
        
        window.hLoader();
    }
    useEffect(async () => {
        if (props.id && props.id > 0) {
            await Axios.get(`/module/id/${props.id}`)
                .then((response) => {
                    if (response.data.status === 200) {
                        console.log(response)
                        setRoleId(response.data.data['userDetail.role.id']);
                        setUpdateData(response.data.data);
                    }
                })
        }

    }, [props.id]);


    return (
        <Row>
        {(!props.id || props.id===0 || (props.id>0 && updateData) ) &&
        <Col xl="12">
            <Card>
                <CardBody>
                    <AvForm className="needs-validation" onSubmit={handleSubmit}>
                    <Row>
                    <Col md="12">
                        <div className="mb-3">
                            <Label htmlFor="courseModuleName">Subject / Module Name</Label>
                            <AvField
                                name="courseModuleName"
                                defaultValue={updateData['courseModuleName']}
                                placeholder="Subject / Module Name"
                                type="text"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                errorMessage="Please provide a Module Name."
                                id="courseModuleName"
                            />
                        </div>
                        </Col>
                        <Col md="12">
                        <div className="mb-3">
                            <Label htmlFor="courseModuleDescription">Subject / Module Description</Label>
                            <AvField
                            name="courseModuleDescription"
                            defaultValue={updateData['courseModuleDescription']}
                            placeholder="Description"
                            type="textarea"
                            validate={{ required: { value: false } }}
                            className="form-control"
                            id="courseModuleDescription"
                            />
                        </div>
                        </Col>
                    </Row>
                    <Col style={{textAlign: 'right'}}>
                    <Button color="primary" type="submit">
                        Submit
                    </Button>
                    </Col>
                    </AvForm>
                </CardBody>
            </Card>
        </Col>
        }
    </Row>
    )
}

export default Model
