import React, { useState, useEffect, useRef } from "react"
import Academic from "../../AcademicProfile/paymentHistory"

const Model = (props) => {  

    const [academicProfileId, setAcademicProfileId] =useState(false);

    useEffect(()=>{
        if(props.userDetailsAll.userDetail){
            setAcademicProfileId(props.userDetailsAll.userDetail.academicProfileId)
        }
    },[props.userDetailsAll])

    return (
        <>{academicProfileId!=false && <Academic id={academicProfileId} userDetailId={props.userDetailsAll.userDetail.id} noPadding={true}/>}</>
    )
}

export default Model
