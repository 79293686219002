import React, { useMemo, useEffect, useState, useRef } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Input,
    Label,
    Modal,
    Table,
} from "reactstrap";
import * as helper from '../../utils/helper'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import QuizSubmission from "./quizSubmission"
import Axios from "../../helpers/axios_helper"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { toast,Slide } from 'react-toastify/dist/react-toastify';
import MaterialReactTable from 'material-react-table';
//Import Flatepicker
import { List,Delete,Edit,PlayArrow,Grading } from '@mui/icons-material';
import {
  Box,
//   Button,
  IconButton,
  Typography,
  Tooltip,
} from '@mui/material';
import * as SHelper from "../../helpers/session_helper"
import AcademicProfileModal from "../AcademicProfile/model"
import CustomModal from "../Common/CustomModal"

const options = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];

const DatatableTables = (props) => {
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const selectedItem = useRef(0)
  const [modal_center, setmodal_center] = useState(false)
  const [update_status_modal_center, setUpdate_status_modal_center] = useState(false);
  const [criteriaDeleteModal, setCriteriaDeleteModal] = useState(false);

  const [columnFilters, setColumnFilters] = useState([]);
  const [columnFilterFns, setColumnFilterFns] = useState({
    numberOfQuestions: 'contains',
    title: 'contains',
    questionSet: 'contains',
    published: 'equal'
  });
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const tableInstanceRef = useRef(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5
  });

    const [showAddCriteria, setShowAddCriteria] = useState(false);
    const [startQuiz, setStartQuiz] = useState(false);
    const [showCreateNewUI, setShowCreateNewUI] = useState(false);
    const [rubricId, setRubricId] = useState(null);
    const [rubricIdx, setRubricIdx] = useState(-1);
    const [criteriaIdx, setCriteriaIdx] = useState(-1);
    const [rubricTitle, setRubricTitle] = useState('');
    const [criteriaDescription, setCriteriaDescription] = useState('');
    const [criteriaPoints, setCriteriaPoints] = useState('');
    const [criteriaList, setCriteriaList] = useState([]);

    const startQuizHandler = (id, setName)=>{
        setStartQuiz({
            quizId:id,
            setName:setName
        });
    };

    const seeQuizResultHandler = (id, setName)=>{
      setStartQuiz({
          quizId:id,
          setName:setName,
          showResultOnly:true
      });
    };
  

    const addCriteriahandler = () =>{
      if(criteriaDescription.length<1){
        toast.error(`Description is required !`, {
          position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
        });
        return;
      }
      else if(criteriaPoints<1){
        toast.error(`Points is required !`, {
          position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
        });
        return;
      }
      else{
        const criteriaData = {
          description:criteriaDescription,
          maxPoints:criteriaPoints
        }
        if(criteriaIdx>-1){
          const updatedList = criteriaList.map((criteria, idx) => 
            idx === criteriaIdx ? { ...criteria, ...criteriaData } : criteria
          );
          setCriteriaList(updatedList);
        }
        else{
          setCriteriaList([...criteriaList, criteriaData]);
        }
        setShowAddCriteria(false);
        cancelCriteriaEdit();
      }
    }


    const handleSubmit = async () => {
        
        if (rubricTitle.length<1) {
            toast.error(`Title is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else {
          window.sLoader();
            const jsonData = {
                id: rubricId,
                title: rubricTitle,
                rubricCriteria: criteriaList,
            }
            const url = rubricId?`/rubric/id/${rubricId}`:`/rubric`;
            await Axios.post(url, jsonData)
                .then(async (response) => {
                    if (response.data.status === 200) {
                        toast.success('Rubric Created/Updated successfully!', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                        setShowCreateNewUI(false);
                        setRubricTitle('');
                        setCriteriaList([]);
                        cancelCriteriaEdit();
                        fetchDataFromAPI(true);
                    } else {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                    cancelCriteriaEdit();
                })
                .catch((e) => {
                    toast.error(e, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                })
              window.hLoader();
        }
    }

    const addcourseQuiz = () => {
        handleSubmit();
    }

    const editRubricHandler = async (id)=>{
      if(id){
        await Axios.get(`/rubric/id/${id}`)
        .then((response) => {
            if (response.data.status==200) {
                setRubricId(response.data.data.id);
                setRubricTitle(response.data.data.title);
                setCriteriaList(response.data.data.rubricCriteria);
                // setQuizSubmissions(response.data.data);
                setShowCreateNewUI(true);
            }
            else{

            }
        })
        .catch(error=>{

        })
      }
    }
    
    const editCriteriahandler = async (idx) => {
        setCriteriaIdx(idx);
        const selectedCriteria = criteriaList[idx];
        setCriteriaDescription(selectedCriteria.description);
        setCriteriaPoints(selectedCriteria.maxPoints);
        setShowAddCriteria(true)
    }

    const cancelCriteriaEdit = () => {
        setRubricIdx(0);
        setCriteriaDescription('');
        setCriteriaPoints('');
        setCriteriaIdx(-1);
        setShowAddCriteria(false)
    }

  //#region Datatable

  const columns = useMemo(
    () => {
      const iColums = [
      {
        size:50,
        accessorKey: 'sl',
        header: "#SL",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: false,
        enableColumnActions:false,
        enableSorting: false
      },
      {
        accessorKey: 'title',
        header: "Title",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      }
    ]
    return iColums;
  },
    [],
  );

  const findFilterCondition = (name) => {
    var condition = '';
    if (columnFilterFns[name]) {
      condition = columnFilterFns[name];
    }
    return condition;
  }

  function checkDateRange(fromDate, toDate) {
    const currentDate = new Date();
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);

    if (currentDate >= startDate && currentDate <= endDate) {
        return ["badge badge-soft-success font-size-12","Ongoing",true]; //'Current date is within the range'
    } else if (currentDate > startDate) {
        return ["badge badge-soft-danger font-size-12","Completed",false]; //'Current date is before the range'
    } else {
        return ["badge badge-soft-warning font-size-12","Upcoming", false]; //'Current date is after the range'
    }
  }

  const fetchDataFromAPI = async (reset = false) => {
    
    window.sLoader();
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    let filterQuery = '';
    let sorterQuery = '';

    if (!reset) {
      if (columnFilters.length > 0) {
        columnFilters.forEach(element => {
          if (element.id === 'invoicedate' || element.id === 'duedate') {
            // filterQuery += `&filter=${element.id},${findFilterCondition(element.id)},${AuthVerify.getDate(element.value)}`;
          }
          else {
            filterQuery += `&filter=${element.id},${findFilterCondition(element.id)},${element.value}`;
          }
        });
      }
      if (sorting.length > 0) {
        sorting.forEach(element => {
          sorterQuery += `&sortby=${element.id}&orderby=${element.desc ? 'desc' : 'asc'}`;
        });
      }
    } else {
      setPagination({
        pageIndex: 0,
        pageSize: 5
      });
      tableInstanceRef.current.resetRowSelection();
    }

    const url = `/rubric/list?limit=${pagination.pageSize}&offset=${pagination.pageIndex * pagination.pageSize}${sorterQuery}${filterQuery}`;
    // let url = `/page/list`;
    await Axios.get(url)
      .then(function (response) {
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
        if (response && response.data?.data) {
          setData(response.data?.data);
          setRowCount(response.data.recordsTotal);
        }
        else {
          toast.error(response.statusText ?? "Data fetching failed !", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            transition: Slide,
          });
        }
      })
      .catch(function (error) {
        // navigate('/');
      });
      window.hLoader();
  };

  useEffect(() => {
    if(!showCreateNewUI) fetchDataFromAPI();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    showCreateNewUI
  ]);

  useEffect(async () => {
    
  }, []);
  //#endregion

  //#region Modal

  const handleCallback = (response) =>{
    selectedItem.current=0;
    setmodal_center(false);
    // loadList();
  }

  const modalCallbackStatus = async (result) =>{
    if(result){
      deleteRubricHandler(selectedItem.current);
    }
    setUpdate_status_modal_center(false);
    selectedItem.current=0;
  }

  const deleteRubricHandler = async (id) =>{
    window.sLoader();
    const url = `/rubric/id/${id}`;
    await Axios.delete(url)
      .then(function (response) {
        if (response && response.data?.status==200) {
          fetchDataFromAPI(true);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            transition: Slide,
          });
        }
        else {
          toast.error(response.statusText ?? "Data fetching failed !", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            transition: Slide,
          });
        }
      })
      .catch(function (error) {
        // navigate('/');
      });
      window.hLoader();
  }

  
  const removeCriteriaHandler = (result) => {
    if(result){
      const id = criteriaList[criteriaIdx].id;
      deleteCriteriaHandler(id);
      const updatedList = criteriaList.filter((_, idx) => idx !== criteriaIdx);
      setCriteriaList(updatedList);
      setCriteriaDeleteModal(false);
      cancelCriteriaEdit();
    }
    
  };

  const deleteCriteriaHandler = async (id) =>{
      window.sLoader();
      const url = `/rubric/criteria/id/${id}`;
      await Axios.delete(url)
        .then(function (response) {
          if (response && response.data?.status==200) {
            fetchDataFromAPI(true);
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
              transition: Slide,
            });
          }
          else {
            toast.error(response.statusText ?? "Data fetching failed !", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
              transition: Slide,
            });
          }
        })
        .catch(function (error) {
          // navigate('/');
        });
        window.hLoader();
  }

  const courseQuizSubmissions = async (id) => {
    await Axios.get(`/quiz/submissions/${id}`)
    .then((response) => {
        if (response.data.status==200) {
            // setQuizSubmissions(response.data.data);
            setShowAddCriteria(true);
        }
        else{

        }
    });
    // fetchSubmittedFiles(id);
}

  //#endregion
  
  return (
    <>
        <Row>
          <Col className="col-12">
            <Card>
              <Col xl="12">
                  <Card>
                      <CardTitle style={{ fontSize: "18px", textAlign: "center", opacity:"0.7" }}>Rubric</CardTitle>
                      <CardBody style={{ paddingTop: "0.5rem" }}>
                          <AvForm className="needs-validation" onSubmit={handleSubmit}>
                          {showCreateNewUI &&
                          <>
                              <Col md="12">
                                  <Row>
                                      <Col md="12">
                                          <div className="mb-3">
                                              <Label>Title</Label>
                                              <AvField
                                                  name="rubricTitle"
                                                  value={rubricTitle}
                                                  onChange={(e) => { setRubricTitle(e.target.value); }}
                                                  type="text"
                                                  className="form-control"
                                              />
                                          </div>
                                      </Col>
                                      <br/>
                                      <Col md="6">
                                        <Label>Rubric Criteria</Label>
                                      </Col>
                                      <Col md="6">
                                        <div className="actions clearfix">
                                          <Button
                                              style={{ float: "right" }}
                                              type="button"
                                              color="primary"
                                              onClick={()=>setShowAddCriteria(true)}
                                          >+ Add Criteria</Button>
                                        </div>
                                      </Col>
                                      <Col md="12">
                                        <div className="table-responsive">
                                          <Table className="table-centered">
                                              <thead>
                                                  <tr>
                                                      <th scope="col">#SL</th>
                                                      <th scope="col">Criteria</th>
                                                      <th scope="col">Points</th>
                                                      <th scope="col">Actions</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  {criteriaList.map((item, i)=>{
                                                      return(
                                                          <tr key={i}>
                                                              <td>{i+1}</td>
                                                              <td>{item.description}</td>
                                                              <td>{item.maxPoints}</td>
                                                              <td>
                                                                <Box sx={{ display: 'flex' }}>
                                                                  <Tooltip arrow placement="left" title="Edit">
                                                                  <IconButton onClick={() => {editCriteriahandler(i);}}>
                                                                      <Edit />
                                                                  </IconButton>
                                                                  </Tooltip>
                                                                  <Tooltip arrow placement="left" title="Delete">
                                                                  <IconButton style={{ color: "red" }} onClick={() => {setCriteriaDeleteModal(true); setCriteriaIdx(i);}}>
                                                                      <Delete />
                                                                  </IconButton>
                                                                  </Tooltip>
                                                              </Box>
                                                              </td>
                                                          </tr>
                                                      )
                                                  })}
                                              </tbody>
                                          </Table>
                                      </div>
                                      </Col>
                                  </Row>
                              </Col>
                              <div className="actions clearfix">
                                  <Button
                                      style={{ float: "right", marginLeft: "10px" }}
                                      type="button"
                                      color="primary"
                                      onClick={addcourseQuiz}
                                  >Save Changes</Button>
                                  {showCreateNewUI &&
                                    <div className="actions clearfix">
                                        <Button
                                            onClick={()=>{cancelCriteriaEdit();setShowCreateNewUI(false)}}
                                            style={{ float: "left" }}
                                            type="button"
                                            color="secondary"
                                            >Cancel</Button>
                                    </div>}
                              </div>
                              </>}
                              {SHelper.userHasEditPermission() && !showCreateNewUI &&
                                  <div className="actions clearfix">
                                      <Button
                                          onClick={()=>setShowCreateNewUI(true)}
                                          style={{ float: "left" }}
                                          type="button"
                                          color="primary"
                                          >Create New Rubric</Button>
                                  </div>}
                          </AvForm>

                          {!showCreateNewUI &&
                          <MaterialReactTable
                              columns={columns}
                              data={data}
                              enableColumnActions={false}
                              enableGlobalFilter={false}
                              enableColumnFilterModes
                              initialState={{
                              showColumnFilters: false
                              }}
                              manualFiltering
                              manualPagination
                              manualSorting
                              onColumnFilterFnsChange={setColumnFilterFns}
                              onColumnFiltersChange={setColumnFilters}
                              onGlobalFilterChange={setGlobalFilter}
                              onPaginationChange={setPagination}
                              onSortingChange={setSorting}
                              tableInstanceRef={tableInstanceRef}
                              rowCount={rowCount}
                              enableEditing
                              state={{
                              columnFilters,
                              columnFilterFns,
                              globalFilter,
                              isLoading,
                              pagination,
                              showAlertBanner: isError,
                              showProgressBars: isRefetching,
                              sorting,
                              }}
                              positionActionsColumn="last"
                              displayColumnDefOptions={{
                              'mrt-row-actions': {
                                  header: 'Action',
                              },
                              }}

                              renderRowActions={({ row, table }) => (
                              <Box sx={{ display: 'flex' }}>
                                  {!SHelper.userHasEditPermission() ? 
                                  <>
                                  {checkDateRange(row.original.startDatetime,row.original.endDatetime)[2] ?
                                  <Tooltip arrow placement="left" title="Start Quiz">
                                  <IconButton onClick={() => {startQuizHandler(row.original.id,row.original['questionSet']);}}>
                                      <PlayArrow />
                                  </IconButton>
                                  </Tooltip>:
                                  <Tooltip arrow placement="left" title="See Quiz Result">
                                  <IconButton onClick={() => {seeQuizResultHandler(row.original.id,row.original['questionSet']);}}>
                                      <Grading />
                                  </IconButton>
                                  </Tooltip>}
                                  </>:
                                  <>
                                  <Tooltip arrow placement="left" title="Edit">
                                  <IconButton onClick={() => {editRubricHandler(row.original.id);}}>
                                      <Edit />
                                  </IconButton>
                                  </Tooltip>
                                  <Tooltip arrow placement="left" title="Delete">
                                  <IconButton style={{ color: "red" }} onClick={() => {setUpdate_status_modal_center(true); selectedItem.current=row.original.id;}}>
                                      <Delete />
                                  </IconButton>
                                  </Tooltip>
                                  </>}
                              </Box>
                              )}
                          />
                      }
                      </CardBody>
                  </Card>
              </Col>
            </Card>
          </Col>

          <Modal
            size="md"
            isOpen={showAddCriteria}
            centered={true}>

            <div className="modal-header">
              <h5 className="modal-title mt-0">Add Criteria</h5>
              <button
                type="button"
                onClick={()=>{setShowAddCriteria(false)}}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              > 
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
               <Row>
                  <Col md="12">
                      <div className="mb-3">
                          <Label>Description</Label>
                          <Input
                              name="courseQuizNoOfQuestions"
                              value={criteriaDescription}
                              onChange={(e) => { setCriteriaDescription(e.target.value); }}
                              type="test"
                              className="form-control"
                          />
                      </div>
                  </Col>
                  <Col md="12">
                      <div className="mb-3">
                          <Label>Points</Label>
                          <Input
                              name="courseQuizPoints"
                              value={criteriaPoints}
                              onChange={(e) => { setCriteriaPoints(e.target.value); }}
                              type="number"
                              className="form-control"
                          />
                      </div>
                  </Col>
               </Row>
            </div>
            <div className="modal-footer customBtnArea" style={{textAlign: 'right'}}>
                <Button color="primary" type="submit" className="btn" onClick={()=>addCriteriahandler()}>
                    Add
                </Button>
            </div>
          </Modal>

          <CustomModal modelShow={update_status_modal_center} handleCallback={modalCallbackStatus} bodyMsg={"Do you want to delete this data ?"}/>
          <CustomModal modelShow={criteriaDeleteModal} handleCallback={removeCriteriaHandler} bodyMsg={"Do you want to delete this data ?"}/>
          {/* <CustomModal modelShow={reset_modal_center} handleCallback={modalCallbackReset} bodyMsg={"New password will sent to the user through user email address."}/> */}
        </Row>
        <QuizSubmission startQuiz={startQuiz} handleCallback={cancelCriteriaEdit}/>
    </>
  )
}

export default DatatableTables
