import React, {useState, useEffect} from "react"
import PropTypes from 'prop-types'
import {Link} from "react-router-dom"
import {Row, Col, BreadcrumbItem} from "reactstrap"
import * as Session from "../../helpers/session_helper"
import Select from "react-select";
import Axios from "../../helpers/axios_helper";

const Breadcrumb = (props) => {

    const [participantUser, setParticipantUser] = useState(true);
    const [courseId, setCourseId] = useState(0);
    const [courseList, setCourseList] = useState([]);
    const [moduleId, setModuleId] = useState(0);
    const [moduleList, setModuleList] = useState([]);

    // useEffect(async () => {
    //     const data = Session.getUser()
    //     if (data && (data.role_code == '333' || data.role_code == '444')) {
    //         setParticipantUser(true);

    //         await Axios.get("/course/dropdown")
    //           .then((response) => {
    //             if (response.data.status === 200) {
    //               setCourseList(response.data.data);
    //             }
    //             else {
    //               setCourseList([])
    //             }
    //           });
    //     }
    // }, []);

    useEffect(async () => {
        const data = Session.getUser()
        if (data && data.role_code == '111') {
            setParticipantUser(false);
    
            await Axios.get("/course/details/id/"+courseId)
            .then((response) => {
                if (response.data.status === 200) {
                const customModules = response.data.data.modules.map(({ courseModuleName, id }) => ({ label: courseModuleName, value: id }));
                setModuleList(customModules);
                }
                else {
                setModuleList([])
                }
            });

        }
    }, [courseId]);


    return (
        <Row>
            {!participantUser &&
            <Col className="col-12">
                <div
                    className="page-title-box d-flex align-items-start align-items-center justify-content-between">
                    <h4 className="page-title mb-0 font-size-18">{props.breadcrumbItem}</h4>
                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <BreadcrumbItem>
                                <Link to="#">{props.title}</Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>
                                <Link to="#">{props.breadcrumbItem}</Link>
                            </BreadcrumbItem>
                        </ol>
                    </div>
                </div>
            </Col>
            }
        </Row>
    )
}

Breadcrumb.propTypes = {
    breadcrumbItem: PropTypes.string,
    title: PropTypes.string
}

export default Breadcrumb
