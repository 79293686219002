module.exports = {
  applicationInfo:{
    // appName: "PGDSCM, BIM",
    appName: "eduFlex & BIM",
    devOrgName: "DataCo Systems Limited",
    devOrgLink: "http://www.bim.gov.bd/"
  },
  applicationSettings:{
    developmentBaseURL : "https://bim-api.braintechsolution.com/api/",//"http://localhost:3334/api/",//"https://api.sustainability.com.bd/api/",//"https://bim-api.braintechsolution.com/api/",
    productionBaseURL : "https://bim-api.braintechsolution.com/api/",//"http://localhost:3334/api/",//"http://localhost:3334/api/",//"https://api.sustainability.com.bd/api/",//"https://bim-api.braintechsolution.com/api/",
    session_name : "Supplychain",
    secretKey : "eduFlex@Shik"
  },
  zoomSettings:{
    baseURL : "http://localhost:3001/",
  }
}


