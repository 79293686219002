import React, { useState, useEffect, useRef } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Label,
    CardImg
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';

import Axios from "../../../helpers/axios_helper"
import * as CHelper from "../../../helpers/custom_helper"
import { useHistory } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../../assets/images/media.jpeg";
const moment = require('moment');

const Model = (props) => {
    const history = useHistory()
    const [menuHeads, setMenuHeads] = useState([]);
    const [menuHeadId, setMenuHeadId] = useState()
    const [updateData, setUpdateData] = useState(false)
    const [imageSrc, setImageSrc] = useState(mediaImage);
    const [files, setFiles] = useState([]);

    const [overviewState, setOverviewState] = useState(EditorState.createEmpty());
    const [overviewContent, setOverviewContent] = useState();

    const handleSubmit = async (event, errors, values) => {
        
        window.sLoader();
        if(errors.length>0){
            errors.forEach(element => {
                toast.error(`${CHelper.separateCamelCaseString(element)} is required !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }
        else{
            if(props.id && props.id==updateData.id){
                const jsonData = {
                    whyChooseUsIcon: values.whyChooseUsIcon,
                    whyChooseUsTitle: values.whyChooseUsTitle,
                    whyChooseUsSequence: 1,
                    whyChooseUsShortNote: values.whyChooseUsShortNote,
                    whyChooseUsDescription: overviewContent
                }
                
                await Axios.patch(`/whyChooseUs/id/${updateData.id}`, jsonData)
                .then(async (response) => {
                    if (response.data.status === 200) {
                        
                        toast.success('Updated successfully!', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                        props.handleCallback(response.data)

                    } else {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                })
                .catch((e) => {
                    toast.error(e, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                })
            }
            else{
                const jsonData = {
                    whyChooseUsIcon: values.whyChooseUsIcon,
                    whyChooseUsTitle: values.whyChooseUsTitle,
                    whyChooseUsSequence: 1,
                    whyChooseUsShortNote: values.whyChooseUsShortNote,
                    whyChooseUsDescription: overviewContent
                }
                
                await Axios.post("/whyChooseUs", jsonData)
                .then(async (response) => {
                    if (response.data.status === 201) {
                        toast.success('Created successfully!', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                        setTimeout(() => {
                            history.push("/why-choose-us-list");
                        }, 1000);
                        
                    } else {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                })
                .catch((e) => {
                    toast.error(e, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                })
            }
            
        }

        window.hLoader();
    }

    useEffect(async () => {
        window.sLoader();
        if (props.id && props.id > 0) {
            await Axios.get(`/whyChooseUs/id/${props.id}`)
            .then((response) => { 
            if(response.data.status===200){
                setUpdateData(response.data.data);
                setOverviewContent(response.data.data.whyChooseUsDescription)
                const eventOverview = EditorState.createWithContent(stateFromHTML(response.data.data.whyChooseUsDescription));
                setOverviewState(eventOverview)
            }
            })
        }
        window.hLoader();
    }, [props.id]);


    const handleOverviewChange = (newEditorState) => {
        setOverviewState(newEditorState);
        setOverviewContent(stateToHTML(newEditorState.getCurrentContent()));
    };

    return (
        <Row>
            {(!props.id || props.id === 0 || (props.id > 0 && updateData)) &&
                <Col xl="12">
                    <Card>
                        <CardBody>
                            <AvForm className="needs-validation" onSubmit={handleSubmit}>
                                <Row>
                                    <Col md="6">
                                        <div className="mb-3">
                                            <Label>Icon</Label>
                                            <AvField
                                                name="whyChooseUsIcon"
                                                placeholder="Icon"
                                                defaultValue={updateData.whyChooseUsIcon}
                                                // onChange={(e)=>{amountChangeEvent(e.target.value);}}
                                                type="text"
                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('whyChooseIcon')}`}
                                                validate={{ required: { value: true } }}
                                                className="form-control"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3">
                                            <Label>Title</Label>
                                            <AvField
                                                name="whyChooseUsTitle"
                                                placeholder="Title"
                                                defaultValue={updateData.whyChooseUsTitle}
                                                // onChange={(e)=>{amountChangeEvent(e.target.value);}}
                                                type="text"
                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('whyChooseUsTitle')}`}
                                                validate={{ required: { value: true } }}
                                                className="form-control"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label htmlFor="validationCustom05">Short note</Label>
                                            <AvField
                                                name="whyChooseUsShortNote"
                                                defaultValue={updateData.whyChooseUsShortNote}
                                                placeholder="Description"
                                                type="textarea"
                                                className="form-control"
                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('whyChooseUsShortNote')}`}
                                                validate={{ required: { value: true } }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label>Description</Label>
                                            <Editor
                                                editorState={overviewState}
                                                onEditorStateChange={handleOverviewChange}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Col style={{ textAlign: 'right' }}>
                                    <Button color="primary" type="submit">
                                        Submit
                                    </Button>
                                </Col>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
            }
        </Row>
    )
}

export default Model
