import React, { useState, useEffect, useRef } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Label,
    CardImg,
    Table,
} from "reactstrap";
import { Delete, Edit } from '@mui/icons-material';
import {
    Box,
    IconButton,
    Tooltip,
} from '@mui/material';
import { AvForm, AvField } from "availity-reactstrap-validation"
import * as CHelper from "../../../helpers/custom_helper"
import Axios from "../../../helpers/axios_helper"
import { useHistory } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../../assets/images/media.jpeg";

const Model = (props) => {
    const history = useHistory()
    const [updateData, setUpdateData] = useState(false)
    const [file, setFile] = useState([]);
    const [sliders, setSliders] = useState([]);
    const [imageSrc, setImageSrc] = useState(mediaImage);

    const [sliderIdx, setSliderIdx] = useState(-1);
    const [sliderText, setSliderText] = useState('');
    const [sliderSequence, setSliderSequence] = useState('');

    const handleSubmit = async (event, errors, values) => {
        window.sLoader();
        var updatedSliders =[];
        var updatedSliderImages =[];
        sliders.forEach((element, i) => {
            updatedSliders.push({
                sliderText : element.sliderText,
                sliderSequence : element.sliderSequence
            })
            updatedSliderImages.push({
                sliderSequence : element.sliderSequence,
                sliderImage : element.sliderImage
            })
        });
        
        const jsonData = {
            homePageSlider: JSON.stringify(updatedSliders)
        }

        const fileData = [JSON.stringify(updatedSliderImages)];
        const newFile = new File(fileData,'sliders.txt', { type: "text/plain" });

        await Axios.post("/page/homePageSlider", jsonData)
            .then(async (response) => {
                if (response.data.status === 200) {

                    const formData = new FormData();
                    formData.append('file', newFile);
                    await Axios.post(`/file/upload/homePage&slider`, formData, {
                    })
                    .then(response => {
                        toast.success('Slider Created/Updated successfully!', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
                    .catch(function (error) {
                        toast.error("File Uploaded Failed !", {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    });
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                }
            })
            .catch((e) => {
                toast.error(e, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            })
        window.hLoader();
    }
    const addfaq = () => {
        if (sliderText.length == 0) {
            toast.error(`Text is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        if (!file) {
            toast.error(`ImageLink is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else if (sliderSequence == 0) {
            toast.error(`Sequence is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else {

            if (sliderIdx > -1) {
                sliders[sliderIdx].sliderText = sliderText;
                sliders[sliderIdx].sliderSequence = sliderSequence;
                sliders[sliderIdx].sliderImage = imageSrc;
                sliders[sliderIdx].file = file;
            }
            else {
                sliders.push({
                    idx:sliders.length,
                    sliderText : sliderText,
                    sliderSequence : sliderSequence,
                    sliderImage : imageSrc,
                    file : file
                });
            }
            setSliders(sliders);
            cancelfaqEdit();
        }
    }

    const cancelfaqEdit = () => {
        setSliderIdx(-1);
        setSliderText('');
        setSliderSequence('');
        setImageSrc(mediaImage);
    }

    const editSlider = (id, idx) => {
        setSliderIdx(idx);
        setSliderText(sliders[idx].sliderText);
        setSliderSequence(sliders[idx].sliderSequence);
        setImageSrc(sliders[idx].sliderImage);
        setFile(sliders[idx].file);
    }

    const deleteSlider = (id, idx) => {
        var filterFaq= sliders.filter(x=>x.idx!=idx);
        setSliders(filterFaq)
    }

    const fileChangeEvent = (event) => {
        let documentFile = event.target.files[0];
        setFile(documentFile);
        CHelper.convertToWebP(documentFile, setImageSrc);
    }

    useEffect(async () => {
        window.sLoader();
        await Axios.get(`/page/homePageSlider`)
            .then((response) => {
                if (response.data.status == 200 && response.data.data && response.data.data.homePageSlider && response.data.data.homePageSlider.length>0) {
                    response.data.data.homePageSlider.forEach((element,i) => {
                        element.idx=i;
                    });
                    setSliders(response.data.data.homePageSlider);
                }
            });
        window.hLoader();
    }, [])

    return (
        <div className="page-content">
            <Breadcrumbs title="Page" breadcrumbItem="Slider" />
            <Row>
                {(!props.id || props.id === 0 || (props.id > 0 && updateData)) &&
                    <Col xl="12">
                        <Card>
                            <CardBody style={{ paddingTop: "0.5rem" }}>
                                <AvForm className="needs-validation" onSubmit={handleSubmit}>
                                    <Col md="12">
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label>Text</Label>
                                                    <AvField
                                                        name="faqName"
                                                        value={sliderText}
                                                        onChange={(e) => { setSliderText(e.target.value); }}
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label>Sequence</Label>
                                                    <AvField
                                                        name="faqName"
                                                        value={sliderSequence}
                                                        onChange={(e) => { setSliderSequence(e.target.value); }}
                                                        type="number"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="mb-3" style={{ textAlign: "center" }}>
                                                            {/* <CardTitle className="h4">Feature Image</CardTitle> */}
                                                            <CardImg className="img-fluid" src={imageSrc} alt="" style={{ maxHeight: "200px", width: "auto" }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="mb-3 justify-content-center d-flex flex-row">
                                                            <label type="button" className="btn btn btn-info col-md-5" style={{ textAlign: "center" }}><i className="fa fa-upload"></i> Change Image<input hidden onChange={fileChangeEvent} accept="image/png, image/gif, image/jpeg, image/jpg" type="file" multiple="multiple" /></label>
                                                            <label hidden style={{ textAlign: "center" }} type="button" onClick={() => CHelper.downloadImage(imageSrc)} className="btn btn btn-primary col-md-5"><i className="fa fa-download"></i> Download Image</label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <div className="actions clearfix">
                                        <Button
                                            style={{ float: "right", marginLeft: "10px" }}
                                            type="button"
                                            color="primary"
                                            onClick={addfaq}
                                        >{sliderIdx > -1 ? "Update" : "Add"}</Button>
                                        {sliderIdx > -1 &&
                                            <Button
                                                style={{ float: "right" }}
                                                type="button"
                                                color="danger"
                                                onClick={cancelfaqEdit}
                                            >Cancel</Button>}
                                    </div>
                                    <CardTitle typeof="h4" >Sliders List</CardTitle>
                                    <Col md={12}>
                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Text</th>
                                                        <th>Sequence</th>
                                                        <th>Image</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sliders.map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <th scope="row">{i + 1}</th>
                                                                <td>{item.sliderText && item.sliderText.length > 20 ? `${item.sliderText.substring(0, 20)} ...` : item.sliderText}</td>
                                                                <td>{item.sliderSequence}</td>
                                                                <td><img src={item.sliderImage} style={{maxHeight:"50px"}}/></td>
                                                                <td>
                                                                    <Box sx={{ display: sliderIdx === i ? 'none' : 'flex' }}>
                                                                        <Tooltip arrow placement="left" title="Edit">
                                                                            <IconButton onClick={() => { editSlider(item.id, i) }}>
                                                                                <Edit />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip arrow placement="left" title="Delete">
                                                                            <IconButton style={{ color: "red" }} onClick={() => { deleteSlider(item.id, i) }}>
                                                                                <Delete />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Box>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                    {sliders.length > 0 &&
                                        <div className="actions clearfix">
                                            <Button
                                                style={{ float: "right", marginLeft: "10px" }}
                                                type="submit"
                                                color="primary"
                                            >Update</Button>
                                        </div>}
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                }
            </Row>
        </div>
    )
}

export default Model
