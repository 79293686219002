import React, { useState, useEffect, useRef } from "react"


import {
    Label,
    Card,
    CardBody,
    Button,
    Col,
    Form,
    Input,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardTitle,
    CardImg,
} from "reactstrap"
import classnames from "classnames"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"
import { MDBDataTable } from "mdbreact";
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';
import Axios from "../../helpers/axios_helper"
import * as CHelper from "../../helpers/custom_helper"
import { useHistory, Link } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../assets/images/media.jpeg";
import CustomModal from "../Common/CustomModal"
import "flatpickr/dist/themes/material_blue.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import './model.scss';

const tabledata = {
    columns: [
      {
        label: "Subject / Module",
        field: "courseModuleName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      }
    ],
    rows:[]
  };

const courseFeePaymentTypeDD = [
    {label:'One-time payment',value:0},
    {label:'Installment-based',value:1}
];

const Model = (props) => {
    const history = useHistory()
    const [activeTab, setactiveTab] = useState(1)
    const [courseTypes, setCourseTypes] = useState([]);
    const [modulesList, setModulesList] = useState([]);
    const [courseModules, setCourseModules] = useState([]);
    const [courseTypeId, setCourseTypeId] = useState()
    const [courseModulesId, setCourseModulesId] = useState([])
    const [updateData, setUpdateData] = useState(false)
    const [imageSrc, setImageSrc] = useState(mediaImage);
    const [files, setFiles] = useState([]);

    const [haveApplicationFee, setHaveApplicationFee] = useState(0);
    const [applicationFee, setApplicationFee] = useState(0);
    const [installmentBasedPayment, setInstallmentBasedPayment] = useState(0);
    const [numberOfInstallment, setNumberOfInstallment] = useState(0);

    const [paymentPolicy, setPaymentPolicy] = useState(false);

    const [overviewState, setOverviewState] = useState(EditorState.createEmpty());
    const [overviewContent, setOverviewContent] = useState();
    const [outlineState, setOutlineState] = useState(EditorState.createEmpty());
    const [outlineContent, setOutlineContent] = useState();

    const [delete_modal_center, setDelete_modal_center] = useState(false)
    const selectedItem = useRef(0)

    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 5) {
                setactiveTab(tab)
            }
        }
    }

    const handleOverviewChange = (newEditorState) => {
        setOverviewState(newEditorState);
        setOverviewContent(stateToHTML(newEditorState.getCurrentContent()));
    };

    const handleOutlineChange = (newEditorState) => {
        setOutlineState(newEditorState);
        setOutlineContent(stateToHTML(newEditorState.getCurrentContent()));
    };

    const handleSubmit = async (event, errors, values) => {
        window.sLoader();
        if (errors.length > 0) {
            errors.forEach(element => {
                toast.error(`${CHelper.separateCamelCaseString(element,1)} is required !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }
        else if(values.numberOfInstallment != values.paymentPolicyDetail.length && installmentBasedPayment==1){
            toast.error(`Installment settings not configured properly !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else {
            if (props.id && props.id == updateData.id) {
                const jsonData = {
                    courseTypeId: courseTypeId,
                    courseName: values.courseName,
                    courseCode: values.courseCode,
                    courseOverview: overviewContent,
                    courseOutline: outlineContent,
                    courseDurationInHours: values.courseDurationInHours,
                    courseYoutubeLink: values.courseYoutubeLink,
                    courseSession: values.courseSession,
                    courseStatus: 1,
                    moduleId:courseModulesId.map(item => item.value),
                    paymentPolicy:{
                        id : paymentPolicy?.id,
                        courseId : updateData.id,
                        haveApplicationFee : haveApplicationFee==1,
                        applicationFee : haveApplicationFee==1 ? applicationFee : 0,
                        installmentBasedPayment : installmentBasedPayment==1,
                        numberOfInstallment : numberOfInstallment,
                        paymentPolicyIsActive : true,
                        paymentPolicyDetail : values.paymentPolicyDetail
                    }
                }

                await Axios.patch(`/course/id/${updateData.id}`, jsonData)
                    .then(async (response) => {
                        if (response.data.status === 200 || response.data.status === 201) {

                            const formData = new FormData();
                            for (let i = 0; i < files.length; i++) {
                                formData.append('file', files[i]);
                            }
                            if (files.length > 0 && updateData.files) {
                                const removeFilesName = [];
                                for (let i = 0; i < updateData.files.length; i++) {
                                    removeFilesName.push(updateData.files[i].name);
                                }
                                formData.append(`remove`, JSON.stringify(removeFilesName));
                            }
                            await Axios.post(`/file/upload/course&${updateData.id}`, formData, {
                            })
                                .then(response => {
                                    toast.success('Course updated successfully!', {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                    props.handleCallback(response.data)
                                })
                                .catch(function (error) {
                                    toast.error("File Uploaded Failed !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                });
                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }
            else {
                const jsonData = {
                    courseTypeId: courseTypeId,
                    courseName: values.courseName,
                    courseCode: values.courseCode,
                    courseOverview: overviewContent,
                    courseOutline: outlineContent,
                    courseDurationInHours: values.courseDurationInHours,
                    courseYoutubeLink: values.courseYoutubeLink,
                    courseSession: values.courseSession,
                    courseStatus: 1,
                    moduleId:courseModulesId.map(item => item.value),
                    paymentPolicy:{
                        haveApplicationFee : haveApplicationFee==1,
                        applicationFee : haveApplicationFee==1 ? applicationFee : 0,
                        installmentBasedPayment : installmentBasedPayment==1,
                        numberOfInstallment : numberOfInstallment,
                        paymentPolicyIsActive : true,
                        paymentPolicyDetail : values.paymentPolicyDetail
                    }
                }

                await Axios.post("/course", jsonData)
                    .then(async (response) => {
                        if (response.data.status === 201) {

                            const formData = new FormData();
                            for (let i = 0; i < files.length; i++) {
                                formData.append('file', files[i]);
                            }

                            await Axios.post(`/file/upload/course&${response.data.id}`, formData, {
                            })
                                .then(res => {
                                    toast.success('Course created successfully!', {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                    setTimeout(() => {
                                        history.push("/course-list");
                                    }, 1000);
                                })
                                .catch(function (error) {
                                    toast.error("File Uploaded Failed !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                });
                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }

        }
        
        window.hLoader();
    }

    const fileChangeEvent = (event) => {
        let documentFile = event.target.files[0];
        setFiles([...event.target.files]);
        CHelper.convertToWebP(documentFile, setImageSrc);
    }


    const modalCallbackStatus = async (result) =>{
        if(result){
            await Axios.delete(`/courseModule/id/${selectedItem.current}`)
            .then((response) => {
                // if(response.data.status===200){
                //     loadList()
                // }else{
                //     alert(response.data.message)
                // }
                // tabledata.rows=response.data.data.modules;
                // setModulesList(tabledata);
            })
            .catch((e)=>{
                console.log(e)
            })
        }
        setDelete_modal_center(false);
    }

    useEffect(async () => {
        if (props.id && props.id > 0) {
            await Axios.get(`/course/details/id/${props.id}`)
                .then(async (response) => {
                    if (response.data.status === 200) {
                        setCourseTypeId(response.data.data.courseTypeId);
                        setPaymentPolicy(response.data.data.paymentPolicy);
                        setHaveApplicationFee(response.data.data.paymentPolicy?.haveApplicationFee?1:0);
                        setApplicationFee(response.data.data.paymentPolicy?.applicationFee);
                        setInstallmentBasedPayment(response.data.data.paymentPolicy?.installmentBasedPayment?1:0);
                        setNumberOfInstallment(response.data.data.paymentPolicy?.numberOfInstallment);

                        setUpdateData(response.data.data);
                        
                        setOverviewContent(response.data.data.courseOverview)
                        const courseOverview = EditorState.createWithContent(stateFromHTML(response.data.data.courseOverview));
                        setOverviewState(courseOverview)

                        setOutlineContent(response.data.data.courseOutline);
                        const courseOutline = EditorState.createWithContent(stateFromHTML(response.data.data.courseOutline));
                        setOutlineState(courseOutline)

                        if(response.data.data.files && response.data.data.files.length>0){
                            setImageSrc(CHelper.getsimplifyLink(response.data.data.files[0].link))
                        }

                        if(response.data.data.modules){
                            response.data.data.modules.map((item, index) => {
                                item.action = (
                                <div style={{ display: "flex" }} className="customBtnArea">
                                    <button
                                    type="button"
                                    className={`btn-danger`}
                                    style={{
                                        cursor: "pointer",
                                        color: "white",
                                        fontSize: ".7em",
                                        padding: ".3rem",
                                        borderRadius: ".3rem"
                                    }}
                                    onClick={() => {selectedItem.current=item.courseModules.id; setDelete_modal_center(true);}}
                                    >Delete
                                    </button>
                                </div>
                                );
                            });
                            tabledata.rows=response.data.data.modules;
                            setModulesList(tabledata);
                        }
                        
                    }
                    else{
                        setModulesList(tabledata)
                    }
                })
            }
            else{
                setNumberOfInstallment(1);
            }
            await Axios.get("/courseType/dropdown")
                .then((response) => {
                    if (response.data.status === 200) {
                        setCourseTypes(response.data.data);
                    }
                    else {
                        setCourseTypes([])
                    }
                });
            
                
            await Axios.get("/module/dropdown")
            .then((response) => {
                if (response.data.status === 200) {
                    setCourseModules(response.data.data);
                }
            });

        return () => {
            // Cancel any outstanding requests or subscriptions here
        };
    }, [props.id]);


    return (
        <Row>
            {(!props.id || props.id === 0 || (props.id > 0 && updateData)) &&
                <Col xl="12">
                    <Card>
                        <CardBody>
                            <AvForm className="needs-validation" onSubmit={handleSubmit}>
                                <Row>
                                    <Col lg="12">
                                        <Card>
                                            <CardBody>
                                                {/* <h4 className="card-title">Jquery Steps Wizard</h4>
                                                    <p className="card-title-desc">A powerful jQuery wizard plugin that supports
                                                                        accessibility and HTML5</p> */}
                                                <div className="form-wizard-wrapper wizard wizard2 clearfix">
                                                    <div className="steps courseCustomSteps clearfix">
                                                        <ul>
                                                            <NavItem
                                                                className={classnames({ current: activeTab === 1 })}>
                                                                <NavLink
                                                                    className={classnames({ current: activeTab === 1 })}
                                                                    onClick={() => {
                                                                        props.id && props.id > 0 && setactiveTab(1)
                                                                    }}
                                                                >
                                                                    <span className="number">1</span>{" "}
                                                                    Basic Details
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem className={classnames({ current: activeTab === 2 })}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === 2 })}
                                                                    onClick={() => {
                                                                        props.id && props.id > 0 && setactiveTab(2)
                                                                    }}
                                                                >
                                                                    <span className="number">2</span>{" "}
                                                                    Overview
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem className={classnames({ current: activeTab === 3 })}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === 3 })}
                                                                    onClick={() => {
                                                                        props.id && props.id > 0 && setactiveTab(3)
                                                                    }}
                                                                >
                                                                    <span className="number">3</span>
                                                                    Outline
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem className={classnames({ current: activeTab === 4 })}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === 4 })}
                                                                    onClick={() => {
                                                                        props.id && props.id > 0 && setactiveTab(4)
                                                                    }}
                                                                >
                                                                    <span className="number">4</span>
                                                                    Modules
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem className={classnames({ current: activeTab === 5 })}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === 5 })}
                                                                    onClick={() => {
                                                                        props.id && props.id > 0 && setactiveTab(5)
                                                                    }}
                                                                >
                                                                    <span className="number">5</span>
                                                                    Payment Policy
                                                                </NavLink>
                                                            </NavItem>
                                                        </ul>
                                                    </div>
                                                    <div className="content clearfix">
                                                        <TabContent
                                                            activeTab={activeTab}
                                                            className="body"
                                                        >
                                                            <TabPane tabId={1}>

                                                                <Row>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="courseTypeId">Course Type</Label>
                                                                            <Select
                                                                                options={courseTypes}
                                                                                value={courseTypes ? courseTypes.filter(x => x.value == courseTypeId)[0] : null}
                                                                                onChange={(e) => { setCourseTypeId(e.value); }}
                                                                                name="courseTypeId"
                                                                                id="courseTypeId"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="courseName">Course name</Label>
                                                                            <AvField
                                                                                name="courseName"
                                                                                defaultValue={updateData.courseName}
                                                                                placeholder="Name"
                                                                                type="text"
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('courseName')}`}
                                                                                className="form-control"
                                                                                validate={{ required: { value: true } }}
                                                                                id="courseName"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="courseCode">Course Code</Label>
                                                                            <AvField
                                                                                name="courseCode"
                                                                                defaultValue={updateData.courseCode}
                                                                                placeholder="Code"
                                                                                type="text"
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('courseCode')}`}
                                                                                className="form-control"
                                                                                validate={{ required: { value: true } }}
                                                                                id="courseCode"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="courseSession">Course Session</Label>
                                                                            <AvField
                                                                                name="courseSession"
                                                                                defaultValue={updateData.courseSession}
                                                                                placeholder="2023-2024"
                                                                                type="text"
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('courseSession')}`}
                                                                                className="form-control"
                                                                                validate={{ required: { value: true } }}
                                                                                id="courseSession"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="courseDurationInHours">Course Duration</Label>
                                                                            {/* <Flatpickr
                                                                                className="form-control d-block"
                                                                                placeholder="dd M,yyyy"
                                                                                options={{
                                                                                    mode: "range",
                                                                                    dateFormat: "d-m-y",
                                                                                }}
                                                                                name="courseDurationInHours"
                                                                                id="courseDurationInHours"
                                                                            /> */}
                                                                            <AvField
                                                                                name="courseDurationInHours"
                                                                                defaultValue={updateData.courseDurationInHours}
                                                                                placeholder="Hours"
                                                                                type="number"
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('courseDurationInHours')}`}
                                                                                className="form-control"
                                                                                validate={{ required: { value: true } }}
                                                                                id="courseDurationInHours"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="courseYoutubeLink">Course Youtube Link</Label>
                                                                            <AvField
                                                                                name="courseYoutubeLink"
                                                                                defaultValue={updateData.courseYoutubeLink}
                                                                                placeholder="YoutubeLink"
                                                                                type="text"
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('courseYoutubeLink')}`}
                                                                                className="form-control"
                                                                                validate={{ required: { value: false } }}
                                                                                id="courseYoutubeLink"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    {/* <Col md="6">
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="validationCustom03">Active</Label>
                                                                        <div className="square-switch">
                                                                            <input
                                                                            type="checkbox"
                                                                            id="isActive"
                                                                            name="isActive"
                                                                            switch="none"
                                                                            checked={active}
                                                                            onChange={() => {
                                                                                setActive(!active);
                                                                            }}
                                                                            />
                                                                            <label
                                                                            htmlFor="isActive"
                                                                            data-on-label="On"
                                                                            data-off-label="Off"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    </Col> */}
                                                                </Row>
                                                            </TabPane>
                                                            <TabPane tabId={2}>
                                                                <Row>
                                                                    <Col md={7}>
                                                                        <Col md={12}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="overviewState">Overview</Label>
                                                                                <Editor
                                                                                    editorState={overviewState}
                                                                                    onEditorStateChange={handleOverviewChange}
                                                                                    toolbarClassName="toolbarClassName"
                                                                                    wrapperClassName="wrapperClassName"
                                                                                    editorClassName="editorClassName"
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Col>
                                                                    <Col md={5}>
                                                                        <Col md={12}>
                                                                            <div className="mb-3" style={{ textAlign: "center" }}>
                                                                                <CardImg className="img-fluid" src={imageSrc} alt="" style={{ maxHeight: "300px", width: "auto" }} />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={12}>
                                                                            <div className="mb-3 justify-content-center d-flex flex-row">
                                                                                <label type="button" className="btn btn btn-info col-md-5" style={{ textAlign: "center" }}><i className="fa fa-upload"></i> Change Image<input hidden onChange={fileChangeEvent} accept="image/png, image/gif, image/jpeg, image/jpg" type="file" /></label>
                                                                                <label hidden style={{ textAlign: "center" }} type="button" onClick={() => CHelper.downloadImage(imageSrc)} className="btn btn btn-primary col-md-5"><i className="fa fa-download"></i> Download Image</label>
                                                                            </div>
                                                                        </Col>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                            <TabPane tabId={3}>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="outlineState">Outline</Label>
                                                                            <Editor
                                                                                editorState={outlineState}
                                                                                onEditorStateChange={handleOutlineChange}
                                                                                toolbarClassName="toolbarClassName"
                                                                                wrapperClassName="wrapperClassName"
                                                                                editorClassName="editorClassName"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                            </TabPane>
                                                            <TabPane tabId={4}>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="outlineState">Subjects / Modules</Label>
                                                                            <Select
                                                                                isMulti={true}
                                                                                options={courseModules}
                                                                                value={courseModulesId}
                                                                                onChange={(e) => { setCourseModulesId(e) }}
                                                                                name="courseTypeId"
                                                                                id="courseTypeId"
                                                                            />

                                                                            <br/>
                                                                            <br/>
                                                                            <Col style={{textAlign:'center'}}>
                                                                                <h4>Subject / Modules List</h4>
                                                                            </Col>
                                                                            <MDBDataTable 
                                                                                responsive
                                                                                striped 
                                                                                bordered 
                                                                                hover
                                                                                noBottomColumns={true} 
                                                                                data={modulesList} 
                                                                                displayEntries={false} 
                                                                                searching={false}
                                                                                info={false} 
                                                                                paging={false}
                                                                                />
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                            </TabPane>
                                                            <TabPane tabId={5}>
                                                                <Row>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="haveApplicationFee">Have Application Fee</Label>
                                                                            <div>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        name="paymentPolicy.haveApplicationFee"
                                                                                        id="haveApplicationFeeYes"
                                                                                        checked={haveApplicationFee === 1}
                                                                                        onChange={(e) => setHaveApplicationFee(1)}
                                                                                    />
                                                                                    <label className="form-check-label" htmlFor="haveApplicationFeeYes"  style={{margin:0}}>
                                                                                        Yes
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        name="paymentPolicy.haveApplicationFee"
                                                                                        id="haveApplicationFeeNo"
                                                                                        checked={haveApplicationFee === 0}
                                                                                        onChange={(e) => setHaveApplicationFee(0)}
                                                                                    />
                                                                                    <label className="form-check-label" htmlFor="haveApplicationFeeNo" style={{margin:0}}>
                                                                                        No
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>

                                                                    {haveApplicationFee==1 &&
                                                                        <Col md="6">
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="applicationFee">Application Fee (in Tk.)</Label>
                                                                                <AvField
                                                                                    name="paymentPolicy.applicationFee"
                                                                                    defaultValue={applicationFee}
                                                                                    placeholder="0"
                                                                                    min={0}
                                                                                    type="number"
                                                                                    onChange={(e)=>{setApplicationFee(e.target.value)}}
                                                                                    errorMessage={`Please provide ${CHelper.separateCamelCaseString('applicationFee')}`}
                                                                                    className="form-control"
                                                                                    validate={{ required: { value: true } }}
                                                                                    id="applicationFee"
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    }
                                                                </Row>
                                                                <Row>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="installmentBasedPayment">Course Fee type</Label>
                                                                            <div>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        name="paymentPolicy.installmentBasedPayment"
                                                                                        id="installmentBasedPaymentYes"
                                                                                        checked={installmentBasedPayment === 1}
                                                                                        onChange={(e) => setInstallmentBasedPayment(1)}
                                                                                    />
                                                                                    <label className="form-check-label" htmlFor="installmentBasedPaymentYes"  style={{margin:0}}>
                                                                                        Installment-based
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        name="paymentPolicy.installmentBasedPayment"
                                                                                        id="installmentBasedPaymentNo"
                                                                                        checked={installmentBasedPayment === 0}
                                                                                        onChange={(e) => {setInstallmentBasedPayment(0); setNumberOfInstallment(1);}}
                                                                                    />
                                                                                    <label className="form-check-label" htmlFor="installmentBasedPaymentNo" style={{margin:0}}>
                                                                                        One-time payment
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>

                                                                    {installmentBasedPayment==1 &&
                                                                        <Col md="6">
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="numberOfInstallment">Number of installments</Label>
                                                                                <AvField
                                                                                    name="numberOfInstallment"
                                                                                    defaultValue={numberOfInstallment}
                                                                                    placeholder="1"
                                                                                    type="number"
                                                                                    errorMessage={`Please provide ${CHelper.separateCamelCaseString('numberOfInstallment')}`}
                                                                                    className="form-control"
                                                                                    onChange={(e)=>{setNumberOfInstallment(e.target.value)}}
                                                                                    validate={{ required: { value: true } }}
                                                                                    id="numberOfInstallment"
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    } 

                                                                    {numberOfInstallment > 0 && Array.from({ length: numberOfInstallment }, (_, index) => {
                                                                        const installmentDetail = paymentPolicy?paymentPolicy?.paymentInstallmentDetails[index] || {} : {}; // Fallback to empty object if undefined
                                                                        const lastInstallment = (numberOfInstallment > 1 && numberOfInstallment == (index + 1));
                                                                        return (
                                                                            <Row key={index}>
                                                                                <Col md="4">
                                                                                    <div className="mb-3">
                                                                                        <Label htmlFor={`perInstallmentAmount[${index}]`}>Installment <b>{index + 1}</b> amount (in Tk.)</Label>
                                                                                        <AvField
                                                                                            name={`paymentPolicyDetail[${index}].perInstallmentAmount`}
                                                                                            defaultValue={installmentDetail.perInstallmentAmount || ''} // Default to empty string
                                                                                            placeholder="0"
                                                                                            type="number"
                                                                                            errorMessage={`Please provide ${CHelper.separateCamelCaseString('perInstallmentAmount')}`}
                                                                                            className="form-control"
                                                                                            validate={{
                                                                                                required: { value: true },
                                                                                                min: { value: 0, errorMessage: 'Minimum value is 0' },
                                                                                            }}
                                                                                            id={`perInstallmentAmount[${index}]`}
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col md="4">
                                                                                    <div className="mb-3">
                                                                                        <Label htmlFor={`daysBetweenInstallment[${index}]`}>Days between next installment (in days)</Label>
                                                                                        <AvField
                                                                                            name={`paymentPolicyDetail[${index}].daysBetweenInstallment`}
                                                                                            defaultValue={
                                                                                                numberOfInstallment > 1 && numberOfInstallment == index + 1
                                                                                                    ? ""
                                                                                                    : lastInstallment? '0' : installmentDetail.daysBetweenInstallment || ''
                                                                                            }
                                                                                            placeholder="0"
                                                                                            readOnly={lastInstallment}
                                                                                            type="number"
                                                                                            errorMessage={`Please provide ${CHelper.separateCamelCaseString('daysBetweenInstallment')}`}
                                                                                            className="form-control"
                                                                                            validate={{ required: { value: false } }}
                                                                                            id={`daysBetweenInstallment[${index}]`}
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col md="4">
                                                                                    <div className="mb-3">
                                                                                        <Label htmlFor={`gracePeriod[${index}]`}>Grace period for installment <b>{index + 1}</b> (in days)</Label>
                                                                                        <AvField
                                                                                            name={`paymentPolicyDetail[${index}].gracePeriod`}
                                                                                            defaultValue={installmentDetail.gracePeriod || ''} // Default to "5"
                                                                                            placeholder="0"
                                                                                            type="number"
                                                                                            errorMessage={`Please provide ${CHelper.separateCamelCaseString('gracePeriod')}`}
                                                                                            className="form-control"
                                                                                            validate={{
                                                                                                required: { value: true },
                                                                                                min: { value: 0, errorMessage: 'Minimum value is 0' },
                                                                                            }}
                                                                                            id={`gracePeriod[${index}]`}
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        );
                                                                    })}

                                                                    
                                                                    
                                                                </Row>
                                                            </TabPane>
                                                        </TabContent>
                                                    </div>
                                                    <div className="actions clearfix">
                                                        {activeTab != 1 && 
                                                            <Button
                                                            style={{float:"left"}}
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => {
                                                                toggleTab(activeTab - 1)
                                                            }}
                                                            >Previous</Button>
                                                        }
                                                        {activeTab === 5 ?
                                                            <Button color="primary" type="submit">
                                                                Submit
                                                            </Button>
                                                            :
                                                            <Link
                                                                to="#"
                                                                className="btn btn-primary"
                                                                onClick={() => {
                                                                    toggleTab(activeTab + 1)
                                                                }}
                                                            >Next</Link>
                                                        }
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
            }

        <CustomModal modelShow={delete_modal_center} handleCallback={modalCallbackStatus}/>
        </Row>
    )
}

export default Model
