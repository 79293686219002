import React, { useState, useEffect, useRef } from "react"
import {
    Label,
    Card,
    CardBody,
    Button,
    Col,
    Form,
    Input,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Row,
    CardTitle,
    CardImg,
    Modal
} from "reactstrap"
import classnames from "classnames"
import Header from "../../components/VerticalLayout/Header"
import PaymentPolicy from "./paymentPolicy"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"
import CustomLoader from "../../components/Common/CustomLoader"
import Axios from "../../helpers/axios_helper"
import * as CHelper from "../../helpers/custom_helper"
import { useHistory, Link } from "react-router-dom"
import { toast, Slide } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../assets/images/users/avatar.jpg";
import * as sessionHelper from '../../helpers/session_helper';
import * as Session from "../../helpers/session_helper"
import "./model.scss"

const genderList = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" }
]

const statusList = [
    { label: "Approved", value: "1" },
    { label: "Reject", value: "0" }
]

const Model = (props) => { 
    const history = useHistory();
    const [activeTab, setActiveTab] = useState(1);
    const [noPaddingStyle, setNoPaddingStyle] = useState({});
    const [educationDetails, setEducationDetails] = useState([]);//{ id: 0 }
    const [workingDetails, setWorkingDetails] = useState([]);
    const [trainingDetails, setTrainingDetails] = useState([]);
    const [certificationDetails, setCertificationDetails] = useState([]);
    const [addressDetails, setAddressDetails] = useState([]);
    const [updateData, setUpdateData] = useState(false);
    const [academicDateOfBirth, setAcademicDateOfBirth] = useState(false);
    const [sameAsPresentAddress, setSameAsPresentAddress] = useState(false);
    const [imageSrc, setImageSrc] = useState(mediaImage);
    const [files, setFiles] = useState([]);
    const [courses, setCourses] = useState([]);
    const [countries, setCountries] = useState([]);
    const [divisions, setDivisions] = useState([]);

    const [academicProfileId, setAcademicProfileId] = useState(0);
    const [courseId, setCourseId] = useState(null);

    const [presentAddress, setPresentAddress] = useState();
    const [presentVillage, setPresentVillage] = useState();
    const [presentCountryId, setPresentCountryId] = useState(0);
    const [presentDivisionId, setPresentDivisionId] = useState(0);
    const [presentZillas, setPresentZillas] = useState([]);
    const [presentZillaId, setPresentZillaId] = useState(0);
    const [presentUpazillas, setPresentUpazillas] = useState([]);
    const [presentUpazillaId, setPresentUpazillaId] = useState(0);

    const [permanentAddress, setPermanentAddress] = useState();
    const [permanentVillage, setPermanentVillage] = useState();
    const [permanentCountryId, setPermanentCountryId] = useState(0);
    const [permanentDivisionId, setPermanentDivisionId] = useState(0);
    const [permanentZillas, setPermanentZillas] = useState([]);
    const [permanentZillaId, setpermanentZillaId] = useState(0);
    const [permanentUpazillas, setpermanentUpazillas] = useState([]);
    const [permanentUpazillaId, setpermanentUpazillaId] = useState(0);

    const [paymentDetails, setPaymentDetails] = useState([{idx:0}]);
    const [paymentPolicy, setPaymentPolicy] = useState(false);
    const [transactions, setTransactions] = useState(false);
    const [gender, setGender] = useState(0);

    const [userStatus, setUserStatus] = useState('');
    const [ userHasEditPermission, setUserHasEditPermission ] = useState(false);

    const [isParticipant, setIsParticipant]=useState(false);


    const [showFinalSubmitBtn, setShowFinalSubmitBtn]=useState(false);


    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 6) {
                setActiveTab(tab)
            }
        }
    }

    async function removeRow(type, idx) {
        let requestUrl = false;
        if (typeof idx != "undefined" && type == "education") {
            // document.getElementById("repeater" + id).innerHTML = ""
            const filtered = educationDetails.filter(x => x.idx != idx);
            setEducationDetails(filtered);
            if (props.id) requestUrl = `/academicProfile/education/id/${filtered.id}`
        }
        else if (typeof idx != "undefined" && type == "working") {
            // document.getElementById("repeater" + id).innerHTML = ""
            const filtered = workingDetails.filter(x => x.idx != idx);
            setWorkingDetails(filtered);
            if (props.id) requestUrl = `/academicProfile/experience/id/${filtered.id}`
        }
        else if (typeof idx != "undefined" && type == "training") {
            // document.getElementById("repeater" + id).innerHTML = ""
            const filtered = trainingDetails.filter(x => x.idx != idx);
            setTrainingDetails(filtered);
            if (props.id) requestUrl = `/academicProfile/training/id/${filtered.id}`
        }
        else if (typeof idx != "undefined" && type == "certification") {
            // document.getElementById("repeater" + id).innerHTML = ""
            const filtered = certificationDetails.filter(x => x.idx != idx);
            setCertificationDetails(filtered);
            if (props.id) requestUrl = `/academicProfile/certificate/id/${filtered.id}`
        }

        if (requestUrl) {
            await Axios.delete(requestUrl)
                .then(async (response) => {
                    if (response.data.status === 200) {
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                })
        }
    }

    const changeEvent = async (type, idx, name, value) => {
        if (typeof idx != "undefined" && type == "education") {
            educationDetails.map((item, index) => {
                if (item.idx == idx) {
                    item[name] = value
                }
            });
            setEducationDetails(educationDetails);
        }
        else if (typeof idx != "undefined" && type == "working") {
            workingDetails.map((item, index) => {
                if (item.idx == idx) {
                    item[name] = value
                }
            });
            setWorkingDetails(workingDetails);
        }
        else if (typeof idx != "undefined" && type == "training") {
            trainingDetails.map((item, index) => {
                if (item.idx == idx) {
                    item[name] = value
                }
            });
            setTrainingDetails(trainingDetails);
        }
        else if (typeof idx != "undefined" && type == "certification") {
            certificationDetails.map((item, index) => {
                if (item.idx == idx) {
                    item[name] = value
                }
            });
            setCertificationDetails(certificationDetails);
        }
        else if (typeof idx != "undefined" && type == "enrolledCourse") {
            paymentDetails.map((item, index) => {
                if (item.idx == idx) {
                    item[name] = value
                }
            });
            setPaymentDetails(paymentDetails);
            // fetchPaymentDetails(value);
        }
    }

    const fetchPaymentDetails = async (courseId, profileId=null) => {
            //course/paymentPolicy/id
            setPaymentPolicy(false);
            await Axios.get(`/open/course/paymentPolicy/${courseId}&${profileId??academicProfileId}`)
            .then((response) => {
                if (response.data?.status === 200) {
                    if(response.data.paymentPolicy){
                        setPaymentPolicy(response.data.paymentPolicy);
                    }
                    else if(response.data.transactions){
                        setTransactions(response.data.transactions);
                        const selectedTransaction = response.data.transactions.find(
                            x => x.transactionType == '2' || x.transactionType == '3'
                        );
                        if(selectedTransaction?.id) setShowFinalSubmitBtn(true);
                    }
                    setPaymentPolicy(response.data.data);
                }
            });
    }

    const handleSubmit = async (event, errors, values) => {
        if (errors.length > 0) {
            errors.forEach(element => {
                toast.error(`${CHelper.separateCamelCaseString(element)} is required !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }
        // else if (imageSrc == mediaImage) {
        //     toast.error(`Profile Picture is required !`, {
        //         position: toast.POSITION.TOP_RIGHT,
        //         autoClose: 3000,
        //     });
        // }
        else if (activeTab == 6) {
            history.push("/page-200");
        }
        else {
            window.sLoader();
            // #region Address
            values.addressDetails[0].addressDetailAddressTypeId = 2;
            values.addressDetails[0].addressDetailAddress = presentAddress;
            values.addressDetails[0].addressDetailVillage = presentVillage;
            values.addressDetails[0].addressDetailCountryId = presentCountryId>0?presentCountryId:null;
            values.addressDetails[0].addressDetailDivisionId = presentDivisionId>0?presentDivisionId:null;
            values.addressDetails[0].addressDetailDistrictId = presentZillaId>0?presentZillaId:null;
            values.addressDetails[0].addressDetailUpazilaId = presentUpazillaId>0?presentUpazillaId:null;

            values.addressDetails[1].addressDetailAddressTypeId = 3;
            values.addressDetails[1].addressDetailAddress = permanentAddress;
            values.addressDetails[1].addressDetailVillage = permanentVillage;
            values.addressDetails[1].addressDetailCountryId = permanentCountryId>0?permanentCountryId:null;
            values.addressDetails[1].addressDetailDivisionId = permanentDivisionId>0?permanentDivisionId:null;
            values.addressDetails[1].addressDetailDistrictId = permanentZillaId>0?permanentZillaId:null;
            values.addressDetails[1].addressDetailUpazilaId = permanentUpazillaId>0?permanentUpazillaId:null;
            // #endregion

            if ((props.id && props.id == updateData.id) || (requestData && requestData.academicProfileId == updateData.id)) {

                if (updateData.addressDetails.length > 0) {
                    for (let index = 0; index < updateData.addressDetails.length; index++) {
                        values.addressDetails[index].id = updateData.addressDetails[index].id;
                    }
                }

                paymentDetails.forEach(element => {
                    delete element.course;
                });
                // const [payment,...oPayments]=paymentDetails;

                const jsonData = {
                    academicStatus: 1,
                    academicIsActive: userStatus,
                    academicFirstName: values.academicFirstName,
                    academicMiddleName: values.academicMiddleName,
                    academicLastName: values.academicLastName,
                    academicBiography: values.academicBiography,
                    academicEmail: values.academicEmail,
                    academicContactNo: values.academicContactNo,
                    academicNID: values.academicNID,
                    academicBirthCertNo: values.academicBirthCertNo,
                    academicGender: gender,
                    academicSameAsPresentAddress: sameAsPresentAddress ? 1 : 0,
                    academicDateOfBirth:academicDateOfBirth,
                    // enrolledCourse:paymentDetails,
                    addresses: values.addressDetails,
                    educations: educationDetails,
                    experiences: workingDetails,
                    trainings: trainingDetails,
                    certificates: certificationDetails
                }


                const token = !requestData.token ? {} : {
                    headers: {
                        'Authorization': `Bearer ${requestData.token}`
                    }
                };
                await Axios.patch(`/open/academicProfile/id/${updateData.id}`, jsonData, token)
                    .then(async (response) => {
                        if (response.data.status === 200) {
                            
                            
                            let errorFound = false;
                            let formData = new FormData();
                            let removeFiles=[]

                            //#region academicProfile File Upload
                            if(files.length>0){
                                for (let i = 0; i < files.length; i++) {
                                    formData.append('file', files[i]);
                                }
                                updateData.academicProfileFiles.forEach(element => {
                                    removeFiles.push(element.name)
                                });
                                formData.append('remove', JSON.stringify(removeFiles));
                                await Axios.post(`/open/file/upload/academicProfile&${updateData.id}`, formData, {
                                })
                                .catch(function (error) {
                                    toast.error("Profile Picture Uploaded Failed !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                });
                            }
                            
                            //#endregion

                            if (!errorFound) {
                                
                                if (props.handleCallback) {
                                    props.handleCallback()
                                }
                                if(activeTab===5){
                                    setActiveTab(6);
                                    loadAcademicProfileData(updateData.id);
                                }
                                else{
                                    toast.success('Academic Profile Updated successfully!', {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                }
                                
                                if(requestData.token){
                                    setTimeout(() => {
                                        history.push("/page-200");
                                    }, 1000);
                                }
                            }
                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }
            else {
                const jsonData = {
                    academicStatus: 1,
                    academicIsActive: userStatus,
                    academicFirstName: values.academicFirstName,
                    academicMiddleName: values.academicMiddleName,
                    academicLastName: values.academicLastName,
                    academicBiography: values.academicBiography,
                    academicEmail: values.academicEmail,
                    academicContactNo: values.academicContactNo,
                    academicNID: values.academicNID,
                    academicBirthCertNo: values.academicBirthCertNo,
                    academicGender: gender,
                    academicDateOfBirth:academicDateOfBirth,
                    // enrolledCourse:paymentDetails,
                    addresses: values.addressDetails,
                    educations: educationDetails,
                    experiences: workingDetails,
                    trainings: trainingDetails,
                    certificates: certificationDetails
                }
                

                await Axios.post("/open/academicProfile", jsonData)
                    .then(async (response) => {
                        if (response.data.status === 201) {
                            let errorFound = false;
                            
                            if(files.length>0){
                                //#region academicProfile File Upload
                                let formData = new FormData();
                                for (let i = 0; i < files.length; i++) {
                                    formData.append('file', files[i]);
                                }

                                await Axios.post(`/open/file/upload/academicProfile&${response.data.id}`, formData, {
                                })
                                    .catch(function (error) {
                                        toast.error("Profile Picture Uploaded Failed !", {
                                            position: toast.POSITION.TOP_RIGHT,
                                            autoClose: 3000,
                                        });
                                    });
                                //#endregion
                            }
                            

                            if (!errorFound) {
                                if(activeTab != 5){
                                    toast.success('Academic Profile created successfully!', {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                }
                                else{
                                    loadAcademicProfileData(response.data.id);
                                }

                                if(props.userDetailId && props.userDetailId>0 && !props.id){
                                    await Axios.patch(`/user/details/id/${props.userDetailId}`, {
                                        academicProfileId:response.data.id
                                    })
                                }
                                else{
                                    if(activeTab===5){
                                        setActiveTab(6);
                                    }
                                    else{
                                        setTimeout(() => {
                                            history.push("/page-200");
                                        }, 1000);
                                    }
                                }
                                
                            }

                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }
            window.hLoader();
        }

    }

    const profilePictureChangeEvent = (event) => {
        let documentFile = event.target.files[0];
        setFiles([...event.target.files]);
        CHelper.convertToWebP(documentFile, setImageSrc);
    }

    const divisionChangeHandler = async (id, state = false) => {
        const data = divisions.filter(x => x.value == id)[0]
        await Axios.get("/address/districts/" + data.code)
            .then((response) => {
                if (response.data.status === 200) {
                    if (state) {
                        setPresentZillas(response.data.data);
                        setPresentZillaId(0);
                        setPresentUpazillaId(0);
                    }
                    else {
                        setPermanentZillas(response.data.data);
                        setpermanentZillaId(0);
                        setpermanentUpazillaId(0);
                    }
                }
            }
            );
    }

    const districtChangeHandler = async (id, state) => {
        const data = state ? presentZillas.filter(x => x.value == id)[0] : permanentZillas.filter(x => x.value == id)[0]
        await Axios.get("/address/upazilas/" + data.code)
            .then((response) => {
                if (response.data.status === 200) {
                    if (state) {
                        setPresentUpazillas(response.data.data);
                        setPresentUpazillaId(0);
                    }
                    else {
                        setpermanentUpazillas(response.data.data);
                        setpermanentUpazillaId(0);
                    }
                }
            });
    }

    useEffect(() => {
        if (sameAsPresentAddress) {
            setPermanentAddress(presentAddress);
            setPermanentVillage(presentVillage);
            setPermanentZillas(presentZillas);
            setpermanentUpazillas(presentUpazillas);
            setPermanentCountryId(presentCountryId);
            setPermanentDivisionId(presentDivisionId);
            setpermanentZillaId(presentZillaId);
            setpermanentUpazillaId(presentUpazillaId);
        }
    }, [presentAddress, presentVillage, sameAsPresentAddress, presentCountryId, presentDivisionId, presentZillaId, presentUpazillaId])


    const [ urlRequestdata, setUrlRequestdata ] = useState(false);
    const [ requestData, setRequestData ] = useState(null);
    const [ loginBackUrl, setLoginBackUrl ] = useState('/login');

    useEffect(()=>{
        if(courses.length>0 && urlRequestdata){
            
            const courseByValue = courses.filter(x => x.value == requestData?.courseId)[0]
            if(courseByValue) changeEvent("payment", 0, "paymentCourseId", courseByValue.value);
        }
    },[courses]);

    async function loadAcademicProfileData(id) {
        id=id??props.id??requestData.academicProfileId;
        if(id)
            return await Axios.get(`/open/academicProfile/details/id/${id}`)
            .then((response) => {
                if (response.data.status === 200) {
                    
                    setAcademicProfileId(id);
                    // setCourseTypeId(response.data.data.courseTypeId);
                    setUpdateData(response.data.data);
                    setAcademicDateOfBirth(response.data.data.academicDateOfBirth)
                    setEducationDetails(response.data.data.educations)
                    setWorkingDetails(response.data.data.experiences)
                    setTrainingDetails(response.data.data.trainings)
                    setCertificationDetails(response.data.data.certifications)
                    if(requestData && requestData.academicProfileId){
                        if(activeTab===5)setPaymentDetails([{idx:0}]);
                    }
                    else{
                        if(response.data.data.enrolledCourses && response.data.data.enrolledCourses.length>0) setPaymentDetails(response.data.data.enrolledCourses)
                    }
                    setGender(response.data.data.academicGender)
                    setSameAsPresentAddress(response.data.data.academicSameAsPresentAddress)
                    if (response.data.data.addressDetails && response.data.data.addressDetails.length == 0) {
                        let addresses = [];
                        addresses.push({ addressDetailAddressTypeId: 2 });
                        addresses.push({ addressDetailAddressTypeId: 3 });
                        setAddressDetails(addresses);

                    }
                    else {
                        setAddressDetails(response.data.data.addressDetails);

                        let presentAddress = response.data.data.addressDetails[0];
                        setPresentAddress(presentAddress.addressDetailAddress);
                        setPresentVillage(presentAddress.addressDetailVillage);
                        setPresentCountryId(presentAddress.addressDetailCountryId);
                        setPresentDivisionId(presentAddress.addressDetailDivisionId);
                        setPresentZillaId(presentAddress.addressDetailDistrictId);
                        setPresentUpazillaId(presentAddress.addressDetailUpazilaId);

                        let permanentAddress = response.data.data.addressDetails[1];
                        setPermanentAddress(permanentAddress.addressDetailAddress);
                        setPermanentVillage(permanentAddress.addressDetailVillage);
                        setPermanentCountryId(permanentAddress.addressDetailCountryId);
                        setPermanentDivisionId(permanentAddress.addressDetailDivisionId);
                        setpermanentZillaId(permanentAddress.addressDetailDistrictId);
                        setpermanentUpazillaId(permanentAddress.addressDetailUpazilaId);
                    }

                    if (response.data.data.academicProfileFiles && response.data.data.academicProfileFiles.length > 0) {
                        setImageSrc(CHelper.getsimplifyLink(response.data.data.academicProfileFiles[0].link))
                    }
                    return response.data.data;
                }
                else{
                    return false;
                }
            })

        else
            return false
    }

    const propsFoundAndCalled = useRef(false);
    useEffect(async () => {
        if(!propsFoundAndCalled.current && props){
            propsFoundAndCalled.current=true;
            let urlData;
            if(props.requestData){
                setUrlRequestdata(props.requestData);
                urlData = decodeURIComponent(props.requestData);
                urlData = CHelper.decryptData(props.requestData);
                urlData = JSON.parse(urlData);
            }

            if(props.loginBackUrl){
                setLoginBackUrl(props.loginBackUrl);
            }
            const user=Session.getUser()
            if(user && user.role_code=='444') {
                setIsParticipant(true)
            }

            await Axios.get("/address/countries")
                .then((response) => {
                    if (response.data.status === 200) {
                        setCountries(response.data.data);
                    }
                });

            await Axios.get("/address/divisions")
                .then((response) => {
                    if (response.data.status === 200) {
                        setDivisions(response.data.data);
                    }
                });

            if ((props.id && props.id > 0) || (urlData && urlData.academicProfileId>0) ) {
                await loadAcademicProfileData(props.id??urlData.academicProfileId)
                .then(async result=>{
                    if(result){
                        if(urlData?.courseId){
                            setCourseId(urlData.courseId);
                            await fetchPaymentDetails(urlData.courseId,urlData.academicProfileId);
                        }
                        if(urlData?.activeTab)
                            setActiveTab(urlData.activeTab);

                        
                        await Axios.get("/address/districts")
                            .then((response) => {
                                if (response.data.status === 200) {
                                    setPresentZillas(response.data.data);
                                    setPermanentZillas(response.data.data);
                                }
                            });

                        await Axios.get("/address/upazilas")
                            .then((response) => {
                                if (response.data.status === 200) {
                                    setPresentUpazillas(response.data.data);
                                    setpermanentUpazillas(response.data.data);
                                }
                            });
                        
                        
                            await Axios.get("/open/course/dropdown")
                            .then((response) => {
                                if (response.data.status === 200) {
                                    let activeCourses = response.data.data;
                                    if(sessionHelper.userHasEditPermission()){
                                        if(result.enrolledCourses?.length>0){
                                            activeCourses = response.data.data.filter(course =>
                                                result.enrolledCourses.some(active => active.courseId == course.value)
                                            );
                                        }
                                    }
                                    setCourses(activeCourses);
                                }
                            });
                        
                    }
                    else{
                        setUrlRequestdata(false);  
                    }
                })
            }
            else{
                await Axios.get("/open/course/dropdown")
                .then((response) => {
                    if (response.data.status === 200) {
                        setCourses(response.data.data);
                    }
                });
            }

            if(props.noPadding){
                setNoPaddingStyle({
                    paddingTop:"0"
                })
            }
            
            if(sessionHelper.userHasEditPermission()){
                setUserHasEditPermission(true);
            }
            
            return () => {
                // Cancel any outstanding requests or subscriptions here
            };
        }
        
    }, [props]);

    
    const passwordResetHandler = async () => {
        const values ={
            username:username,
            password:userPassword
        }
        await Axios.post("/auth/login", values)
            .then(function (response) {
            if(response.data.status===200){
                const user = JSON.parse(response.data.details)
                if (user.role_code == '444') {

                    var data = JSON.stringify({
                        academicProfileId : user.user_academicProfileId,
                        token: response.data.token,
                    });
                    
                    data = CHelper.encryptData(data);
                    data = encodeURIComponent(data);
                    window.open(`/participant-registration?status=new&requestData=${data}`)
                    
                }
                else{
                    toast.error('Only participant can enroll the course !', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                }
            }else{
                // setNotification(response.data.message)
            }
        })
        .catch(function (error) {
            // setNotification(error.message)
        })
    }

    const [status_modal_center, setStatus_modal_center] = useState(false);
    const [status_modal_message, setStatus_modal_message] = useState('');
    const [showPasswordEntry, setShowPasswordEntry] = useState(false);
    const [username, setUsername] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [duplicateAcademicProfileId, setDuplicateAcademicProfileId] = useState(0);

    const [academicNID, setAcademicNID] = useState('');
    useEffect(()=>{
        if(updateData.academicNID){
            setAcademicNID(updateData.academicNID)
        }
    },[updateData])

    const checkNidUsed = async (e) =>{
        if(updateData || e.target.value.length==0) return;
        const value = e.target.value;
        const body = {
            academicNID:value
        }
        await Axios.post(`/open/academicProfile/checkDuplicate`, body)
        .then((response) => {
            if(response.data.data){
                setDuplicateAcademicProfileId(response.data.data.id);
                if(response.data.data.user){
                    setUsername(response.data.data.user.username);
                    setShowPasswordEntry(true);
                    setStatus_modal_message(`There already a profile found by this NID.
                        <br/>You can't use same NID to create another profile.
                        <br/>Please <a href="${loginBackUrl??"/login"}">login</a> to your user or enter your password here for enroll a new course.
                        <br/><br/>Username : <b>${response.data.data.user.username}</b>
                        <br/>
                        <br/>For any query please contact with authority.`);
                }
                else{
                    setStatus_modal_message(`There already a profile found by this NID.
                        <br/>You can't use same NID to create another profile in deactivate mode.
                        <br/>Please contact with authority to activate your profile.`);
                }
                setAcademicNID('');
                setStatus_modal_center(true);
            }
        })
    }
    
    const [academicContactNo, setAcademicContactNo] = useState('');
    useEffect(()=>{
        if(updateData.academicContactNo){
            setAcademicContactNo(updateData.academicContactNo)
        }
    },[updateData])
    
    const checkPhoneUsed = async (e) =>{
        if(updateData || e.target.value.length==0) return;
        const value = e.target.value;
        const body = {
            academicContactNo:value
        }
        await Axios.post(`/open/academicProfile/checkDuplicate`, body)
        .then((response) => {
            if(response.data.data){
                setDuplicateAcademicProfileId(response.data.data.id);
                if(response.data.data.user){
                    setUsername(response.data.data.user.username);
                    setShowPasswordEntry(true);
                    setStatus_modal_message(`There already a profile found by this Contact No.
                        <br/>You can't use same Contact No to create another profile.
                        <br/>Please <a href="${loginBackUrl??"/login"}">login</a> to your user for enroll a new course.
                        <br/><br/>Username : <b>${response.data.data.user.username}</b>
                        <br/>
                        <br/>For any query please contact with authority.`);
                }
                else{
                    setStatus_modal_message(`There already a profile found by this NID.
                        <br/>You can't use same NID to create another profile in deactivate mode.
                        <br/>Please contact with authority to activate your profile.`);
                }
                setAcademicContactNo('');
                setStatus_modal_center(true);
            }
        })
    }
    
    const [academicEmail, setAcademicEmail] = useState('');
    useEffect(()=>{
        if(updateData.academicEmail){
            setAcademicEmail(updateData.academicEmail)
        }
    },[updateData])
    
    const checkEmailUsed = async (e) =>{
        if(updateData || e.target.value.length==0) return;
        const value = e.target.value;
        const body = {
            academicEmail:value
        }
        await Axios.post(`/open/academicProfile/checkDuplicate`, body)
        .then((response) => {
            if(response.data.data){
                setDuplicateAcademicProfileId(response.data.data.id);
                if(response.data.data.user){
                    setUsername(response.data.data.user.username);
                    setShowPasswordEntry(true);
                    setStatus_modal_message(`There already a profile found by this Email.
                        <br/>You can't use same Email to create another profile.
                        <br/>Please <a href="${loginBackUrl??"/login"}">login</a> to your user for enroll a new course.
                        <br/><br/>Username : <b>${response.data.data.user.username}</b>
                        <br/>
                        <br/>For any query please contact with authority.`);
                }
                else{
                    setStatus_modal_message(`There already a profile found by this Email.
                        <br/>You can't use same Email to create another profile in deactivate mode.
                        <br/>Please contact with authority to activate your profile.`);
                }
                setAcademicEmail('');
                setStatus_modal_center(true);
            }
        })
    }


    return (
        <Row>
            {props.status && <Header applyCustomSettings={true} />}
            {/* {((!props.id && !props.requestData)  || (requestData && updateData)) ? */}
            {(((!props.id && !props.requestData) || props.id === 0 || (props.id > 0 && updateData) || (props.requestData && updateData))) ?
                <Col xl="12" style={{ margin: "auto" }}>
                    <Card>
                        <CardBody style={noPaddingStyle}>
                            <AvForm className="needs-validation" onSubmit={handleSubmit}>
                                <Row>
                                    <Col lg="12">
                                        <Card>
                                            <CardBody style={noPaddingStyle}>
                                                {!props.id && !noPaddingStyle && <CardTitle typeof="h4">REGISTRATION</CardTitle>}
                                                <div className="form-wizard-wrapper wizard clearfix">
                                                    <div className="steps customSteps clearfix">
                                                        <ul>
                                                            <NavItem className={classnames({ current: activeTab === 1 })}>
                                                                <NavLink
                                                                    className={classnames({ current: activeTab === 1 })}
                                                                    onClick={() => {
                                                                        setActiveTab(1)
                                                                    }}
                                                                >
                                                                    {!noPaddingStyle && <span className="number">1</span>}
                                                                    General
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem className={classnames({ current: activeTab === 2 })}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === 2 })}
                                                                    onClick={() => {
                                                                        setActiveTab(2)
                                                                    }}
                                                                >
                                                                    {!noPaddingStyle && <span className="number">2</span>}
                                                                    {" "}
                                                                    Education
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem className={classnames({ current: activeTab === 3 })}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === 3 })}
                                                                    onClick={() => {
                                                                        setActiveTab(3)
                                                                    }}
                                                                >
                                                                    {!noPaddingStyle && <span className="number">3</span>}
                                                                    Experience
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem className={classnames({ current: activeTab === 4 })}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === 4 })}
                                                                    onClick={() => {
                                                                        setActiveTab(4)
                                                                    }}
                                                                >
                                                                   {!noPaddingStyle && <span className="number">4</span>}
                                                                    Training
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem className={classnames({ current: activeTab === 5 })}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === 5 })}
                                                                    onClick={() => {
                                                                        setActiveTab(5)
                                                                    }}
                                                                >
                                                                    {!noPaddingStyle && <span className="number">5</span>}
                                                                    Certification
                                                                </NavLink>
                                                            </NavItem>
                                                            {!isParticipant && <NavItem className={classnames({ current: activeTab === 6 })}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === 6 })}
                                                                    onClick={() => {
                                                                        userHasEditPermission && setActiveTab(6)
                                                                    }}
                                                                >
                                                                    {!noPaddingStyle && <span className="number">6</span>}
                                                                    Payment
                                                                </NavLink>
                                                            </NavItem>
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className="content clearfix">
                                                        <TabContent
                                                            activeTab={activeTab}
                                                            className="body"
                                                        >
                                                            <TabPane tabId={1}>

                                                                <Row>
                                                                    <Col md={8}>
                                                                        <Row>
                                                                            <Col md="4">
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="academicFirstName">First name <span className="requiredSign">*</span></Label>
                                                                                    <AvField
                                                                                        name="academicFirstName"
                                                                                        defaultValue={updateData.academicFirstName}
                                                                                        // placeholder="First Name"
                                                                                        type="text"
                                                                                        errorMessage={`Please provide ${CHelper.separateCamelCaseString('firstName')}`}
                                                                                        className="form-control"
                                                                                        required
                                                                                        id="academicFirstName"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md="4">
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="academicMiddleName">Middle name</Label>
                                                                                    <AvField
                                                                                        name="academicMiddleName"
                                                                                        defaultValue={updateData.academicMiddleName}
                                                                                        // placeholder="Middle Name"
                                                                                        type="text"
                                                                                        errorMessage={`Please provide ${CHelper.separateCamelCaseString('middleName')}`}
                                                                                        className="form-control"
                                                                                        id="academicMiddleName"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md="4">
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="academicLastName">Last name <span className="requiredSign">*</span></Label>
                                                                                    <AvField
                                                                                        name="academicLastName"
                                                                                        defaultValue={updateData.academicLastName}
                                                                                        // placeholder="Last Name"
                                                                                        type="text"
                                                                                        errorMessage={`Please provide ${CHelper.separateCamelCaseString('lastName')}`}
                                                                                        className="form-control"
                                                                                        required
                                                                                        id="academicLastName"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md="12">
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="academicBiography">Write a Sort Biography on yourself <span className="requiredSign">*</span></Label>
                                                                                    <AvField
                                                                                        name="academicBiography"
                                                                                        defaultValue={updateData.academicBiography}
                                                                                        // placeholder="Biography"
                                                                                        type="textarea"
                                                                                        errorMessage={`Please provide ${CHelper.separateCamelCaseString('biography')}`}
                                                                                        className="form-control"
                                                                                        required
                                                                                        id="academicBiography"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md="6">
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="academicEmail">Email <span className="requiredSign">*</span></Label>
                                                                                    <AvField
                                                                                        name="academicEmail"
                                                                                        // defaultValue={updateData.academicEmail}
                                                                                        value={academicEmail}
                                                                                        onChange={e=>setAcademicEmail(e.target.value)}
                                                                                        // placeholder="Email"
                                                                                        readOnly={requestData!=null && updateData.academicEmail}
                                                                                        type="email"
                                                                                        onBlur={checkEmailUsed}
                                                                                        errorMessage={`Please provide ${CHelper.separateCamelCaseString('email')}`}
                                                                                        className="form-control"
                                                                                        required
                                                                                        id="academicEmail"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md="6">
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="academicContactNo">Contact no <span className="requiredSign">*</span></Label>
                                                                                    <AvField
                                                                                        name="academicContactNo"
                                                                                        // defaultValue={updateData.academicContactNo}
                                                                                        value={academicContactNo}
                                                                                        onChange={e=>setAcademicContactNo(e.target.value)}
                                                                                        readOnly={requestData!=null && updateData.academicContactNo}
                                                                                        // placeholder="Contact No"
                                                                                        type="text"
                                                                                        onBlur={checkPhoneUsed}
                                                                                        errorMessage={`Please provide ${CHelper.separateCamelCaseString('contactNo')}`}
                                                                                        className="form-control"
                                                                                        // validate={{ required: { value: true }, match:{value:'academicEmail'} }}
                                                                                        required
                                                                                        id="academicContactNo"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <Col md={12}>
                                                                            <div className="mb-3" style={{ textAlign: "center" }}>
                                                                                <CardImg className="img-fluid" src={imageSrc} alt="" style={{ maxHeight: "250px", minHeight: "220px", width: "auto" }} />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={12}>
                                                                            <div className="mb-3 justify-content-center d-flex flex-row">
                                                                                <label type="button" className="btn btn btn-info col-md-5" style={{ textAlign: "center" }}><i className="fa fa-upload"></i> Profile Picture<input hidden onChange={profilePictureChangeEvent} accept="image/png, image/gif, image/jpeg, image/jpg" type="file" /></label>
                                                                                <label hidden style={{ textAlign: "center" }} type="button" onClick={() => CHelper.downloadImage(imageSrc)} className="btn btn btn-primary col-md-5"><i className="fa fa-download"></i> Download Image</label>
                                                                            </div>
                                                                        </Col>
                                                                    </Col>
                                                                    
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="academicDateOfBirth">Date of Birth <span className="requiredSign">*</span></Label>
                                                                            <Flatpickr
                                                                                name="academicDateOfBirth"
                                                                                id="academicDateOfBirth"
                                                                                className="form-control d-block"
                                                                                readOnly={requestData!=null && updateData.academicDateOfBirth}
                                                                                // placeholder="dd M, yyyy"
                                                                                options={{
                                                                                    altInput: true,
                                                                                    altFormat: "F j, Y",
                                                                                    dateFormat: "Y-m-d",
                                                                                    defaultDate: updateData.academicDateOfBirth?new Date(updateData.academicDateOfBirth):false
                                                                                }}
                                                                                onChange={(selectedDates, dateStr, instance) => {
                                                                                    setAcademicDateOfBirth(dateStr);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="academicNID">National ID <span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name="academicNID"
                                                                                // defaultValue={updateData.academicNID}
                                                                                readOnly={requestData!=null && updateData.academicNID}
                                                                                // placeholder="National ID"
                                                                                type="text"
                                                                                onBlur={checkNidUsed}
                                                                                value={academicNID}
                                                                                onChange={e=>setAcademicNID(e.target.value)}
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('nationalID')}`}
                                                                                className="form-control"
                                                                                validate={{ async: (value, ctx, input, cb) => { return ((ctx.academicBirthCertNo != undefined && ctx.academicBirthCertNo.length > 0) || (ctx.academicNID != undefined && ctx.academicNID.length > 0)) } }}
                                                                                id="academicNID"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    {/* <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="academicBirthCertNo">Birth Registration No</Label>
                                                                            <AvField
                                                                                name="academicBirthCertNo"
                                                                                defaultValue={updateData.academicBirthCertNo}
                                                                                // placeholder="Birth Registration No"
                                                                                type="text"
                                                                                // errorMessage={`Please provide ${CHelper.separateCamelCaseString('birthRegNo')}`}
                                                                                className="form-control"
                                                                                // validate={{ async: (value, ctx, input, cb) => { return ((ctx.academicBirthCertNo != undefined && ctx.academicBirthCertNo.length > 0) || (ctx.academicNID != undefined && ctx.academicNID.length > 0)) } }}
                                                                                id="academicBirthCertNo"
                                                                            />
                                                                        </div>
                                                                    </Col> */}
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="academicGender">Gender <span className="requiredSign">*</span></Label>
                                                                            {/* <AvField
                                                                                name="academicGender"
                                                                                defaultValue={updateData.academicGender}
                                                                                // placeholder="Gender"
                                                                                type="text"
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('gender')}`}
                                                                                className="form-control"
                                                                                required
                                                                                id="academicGender"
                                                                            /> */}

                                                                            <Select
                                                                                name={"academicGender"}
                                                                                id={"academicGender"}
                                                                                options={genderList}
                                                                                value={genderList.filter(x => x.value == gender)}
                                                                                onChange={(e) => { setGender(e.value); }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    {/* <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="academicUsername">Username <span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name="academicUsername"
                                                                                defaultValue={updateData.academicUsername}
                                                                                // placeholder="Username"
                                                                                type="text"
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('username')}`}
                                                                                className="form-control"
                                                                                required
                                                                                id="academicUsername"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="academicPassword">Password <span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name="academicPassword"
                                                                                defaultValue={updateData.academicPassword}
                                                                                // placeholder="Password"
                                                                                type="text"
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('password')}`}
                                                                                className="form-control"
                                                                                required
                                                                                id="academicPassword"
                                                                            />
                                                                        </div>
                                                                    </Col> */}
                                                                    <Col md={12} style={{ marginTop: "30px" }}>
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <h4 className="card-title">Present Address</h4>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="presentAddress">Address <span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name={"addressDetails[" + 0 + "].addressDetailAddress"}
                                                                                // placeholder="Address"
                                                                                type="text"
                                                                                value={presentAddress}
                                                                                onChange={(e) => setPresentAddress(e.target.value)}
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('presentAddress')}`}
                                                                                className="form-control"
                                                                                required
                                                                                id="presentAddress"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="presentCountry">Country <span className="requiredSign">*</span></Label>
                                                                            <Select
                                                                                name={"addressDetails[" + 0 + "].addressDetailCountryId"}
                                                                                id="presentCountry"
                                                                                options={countries}
                                                                                value={countries ? countries.filter(x => x.value == presentCountryId) : null}
                                                                                onChange={(e) => { setPresentCountryId(e.value); }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="presentDivision">Division <span className="requiredSign">*</span></Label>
                                                                            <Select
                                                                                name={"addressDetails[" + 0 + "].addressDetailDivisionId"}
                                                                                id="presentDivision"
                                                                                options={divisions}
                                                                                value={divisions ? divisions.filter(x => x.value == presentDivisionId) : null}
                                                                                onChange={(e) => { setPresentDivisionId(e.value); divisionChangeHandler(e.value, true); }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="presentZilla">Zilla <span className="requiredSign">*</span></Label>
                                                                            <Select
                                                                                name={"addressDetails[" + 0 + "].addressDetailDistrictId"}
                                                                                id="presentZilla"
                                                                                options={presentZillas}
                                                                                value={presentZillas && presentZillaId > 0 ? presentZillas.filter(x => x.value == presentZillaId) : null}
                                                                                onChange={(e) => { setPresentZillaId(e.value); districtChangeHandler(e.value, true); }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="presentUpazila">Upazilla <span className="requiredSign">*</span></Label>
                                                                            <Select
                                                                                name={"addressDetails[" + 0 + "].addressDetailUpazilaId"}
                                                                                id="presentUpazila"
                                                                                options={presentUpazillas}
                                                                                value={presentUpazillas && presentUpazillaId > 0 ? presentUpazillas.filter(x => x.value == presentUpazillaId) : null}
                                                                                onChange={(e) => { setPresentUpazillaId(e.value); }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="presentVillage">Vill/Suburb</Label>
                                                                            <AvField
                                                                                name={"addressDetails[" + 0 + "].addressDetailVillage"}
                                                                                // placeholder="Village"
                                                                                type="text"
                                                                                value={presentVillage}
                                                                                onChange={(e) => setPresentVillage(e.target.value)}
                                                                                className="form-control"
                                                                                id={"addressDetailVillage[" + 0 + "]"}
                                                                            />
                                                                        </div>
                                                                    </Col>

                                                                    <Col md={12} style={{ marginTop: "30px" }}>
                                                                        <Row>
                                                                            <Col md={12} style={{ margin: "auto", display: "flex" }}>
                                                                                <h4 className="card-title" style={{}}>Permanent Address</h4>
                                                                                <div className="form-check form-switch" dir="ltr" style={{ marginLeft: "3%" }}>

                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="form-check-input"
                                                                                        id="sameAsPresentAddress"
                                                                                        checked={sameAsPresentAddress}
                                                                                        onClick={e => {
                                                                                            setSameAsPresentAddress(!sameAsPresentAddress);
                                                                                        }}
                                                                                    />

                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="sameAsPresentAddress"
                                                                                        style={{ marginTop: "0" }}
                                                                                    >
                                                                                        Same as present address
                                                                                    </label>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="permanentAddress">Address <span className="requiredSign">*</span></Label>
                                                                            <AvField
                                                                                name={"addressDetails[" + 1 + "].addressDetailAddress"}
                                                                                // placeholder="Address"
                                                                                type="text"
                                                                                value={permanentAddress}
                                                                                onChange={(e) => setPermanentAddress(e.target.value)}
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('permanentAddress')}`}
                                                                                className="form-control"
                                                                                readOnly={sameAsPresentAddress}
                                                                                required
                                                                                id="permanentAddress"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="permanentCountry">Country <span className="requiredSign">*</span></Label>
                                                                            <Select
                                                                                name={"addressDetails[" + 1 + "].permanentCountryId"}
                                                                                id="permanentCountry"
                                                                                isDisabled={sameAsPresentAddress}
                                                                                options={countries}
                                                                                value={countries ? countries.filter(x => x.value == permanentCountryId)[0] : null}
                                                                                onChange={(e) => { setPermanentCountryId(e.value); }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="permanentDivision">Division <span className="requiredSign">*</span></Label>
                                                                            <Select
                                                                                name={"addressDetails[" + 1 + "].permanentDivisionId"}
                                                                                id="permanentDivision"
                                                                                isDisabled={sameAsPresentAddress}
                                                                                options={divisions}
                                                                                value={divisions ? divisions.filter(x => x.value == permanentDivisionId) : null}
                                                                                onChange={(e) => { setPermanentDivisionId(e.value); divisionChangeHandler(e.value); }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="permanentZilla">Zilla <span className="requiredSign">*</span></Label>
                                                                            <Select
                                                                                name={"addressDetails[" + 1 + "].permanentZillaId"}
                                                                                id="permanentZilla"
                                                                                isDisabled={sameAsPresentAddress}
                                                                                options={permanentZillas}
                                                                                value={permanentZillas && permanentZillaId > 0 ? permanentZillas.filter(x => x.value == permanentZillaId) : null}
                                                                                onChange={(e) => { setpermanentZillaId(e.value); districtChangeHandler(e.value); }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="permanentUpazila">Upazilla <span className="requiredSign">*</span></Label>
                                                                            <Select
                                                                                name={"addressDetails[" + 1 + "].permanentUpazillaId"}
                                                                                id="permanentUpazila"
                                                                                isDisabled={sameAsPresentAddress}
                                                                                options={permanentUpazillas}
                                                                                value={permanentUpazillas && permanentUpazillaId > 0 ? permanentUpazillas.filter(x => x.value == permanentUpazillaId) : null}
                                                                                onChange={(e) => { setpermanentUpazillaId(e.value); }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="permanentVillage">Vill/Suburb</Label>
                                                                            <AvField
                                                                                name={"addressDetails[" + 1 + "].addressDetailVillage"}
                                                                                // placeholder="Village"
                                                                                type="text"
                                                                                value={permanentVillage}
                                                                                readOnly={sameAsPresentAddress}
                                                                                onChange={(e) => setPermanentVillage(e.target.value)}
                                                                                className="form-control"
                                                                                id={"addressDetailVillage[" + 1 + "]"}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>

                                                            <TabPane tabId={2}>
                                                                <Row>
                                                                    <CardTitle typeof="h4">Educational Credentials (List your academic credentials, Last Degree at the First)</CardTitle>
                                                                    <Col xs={12}>
                                                                        <div className="repeater">
                                                                            {educationDetails && educationDetails.map((item, idx) => (
                                                                                <React.Fragment key={idx}>
                                                                                    <div data-repeater-list="group-a" id={"repeater" + idx} style={{ paddingTop: "10px" }} className="customBackground">
                                                                                        <div data-repeater-item className="row">
                                                                                            <div className="mb-3 col-md-4">
                                                                                                <Label htmlFor={"degree[" + idx + "]"}>Degree <span className="requiredSign">*</span></Label>
                                                                                                <AvField
                                                                                                    name={"degree[" + idx + "]"}
                                                                                                    // placeholder="Degree"
                                                                                                    type="text"
                                                                                                    defaultValue={item.educationDegree}
                                                                                                    onChange={(e) => { changeEvent("education", idx, "educationDegree", e.target.value); }}
                                                                                                    className="form-control"
                                                                                                    id={"degree[" + idx + "]"}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-4">
                                                                                                <Label htmlFor={"degreeName[" + idx + "]"}>Name of Degree <span className="requiredSign">*</span></Label>
                                                                                                <AvField
                                                                                                    name={"degreeName[" + idx + "]"}
                                                                                                    // placeholder="Degree Name"
                                                                                                    type="text"
                                                                                                    defaultValue={item.educationDegreeName}
                                                                                                    onChange={(e) => { changeEvent("education", idx, "educationDegreeName", e.target.value); }}
                                                                                                    className="form-control"
                                                                                                    id={"degreeName[" + idx + "]"}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-4">
                                                                                                <Label htmlFor={"degreeStatus[" + idx + "]"}>Status <span className="requiredSign">*</span></Label>
                                                                                                <AvField
                                                                                                    name={"degreeStatus[" + idx + "]"}
                                                                                                    // placeholder="Degree Status"
                                                                                                    type="text"
                                                                                                    defaultValue={item.educationStatus}
                                                                                                    onChange={(e) => { changeEvent("education", idx, "educationStatus", e.target.value); }}
                                                                                                    className="form-control"
                                                                                                    id={"degreeStatus[" + idx + "]"}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-6">
                                                                                                <Label htmlFor={"institution[" + idx + "]"}>Institution <span className="requiredSign">*</span></Label>
                                                                                                <AvField
                                                                                                    name={"institution[" + idx + "]"}
                                                                                                    // placeholder="Institution"
                                                                                                    type="text"
                                                                                                    defaultValue={item.educationInstitute}
                                                                                                    onChange={(e) => { changeEvent("education", idx, "educationInstitute", e.target.value); }}
                                                                                                    className="form-control"
                                                                                                    id={"institution[" + idx + "]"}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-3">
                                                                                                <Label htmlFor={"country[" + idx + "]"}>Country <span className="requiredSign">*</span></Label>
                                                                                                <Select
                                                                                                    name={"country[" + idx + "]"}
                                                                                                    id={"country[" + idx + "]"}
                                                                                                    options={countries}
                                                                                                    value={countries ? countries.filter(x => x.value == item.educationCountryId) : null}
                                                                                                    onChange={(e) => { changeEvent("education", idx, "educationCountryId", e.value); }}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-3">
                                                                                                <Label htmlFor={"campus[" + idx + "]"}>Campus </Label>
                                                                                                <AvField
                                                                                                    name={"campus[" + idx + "]"}
                                                                                                    // placeholder="Campus"
                                                                                                    type="text"
                                                                                                    defaultValue={item.educationCampus}
                                                                                                    onChange={(e) => { changeEvent("education", idx, "educationCampus", e.target.value); }}
                                                                                                    className="form-control"
                                                                                                    id={"campus[" + idx + "]"}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-6">
                                                                                                <Label htmlFor={"degreeAddress[" + idx + "]"}>Address</Label>
                                                                                                <AvField
                                                                                                    name={"degreeAddress[" + idx + "]"}
                                                                                                    // placeholder="Address"
                                                                                                    type="text"
                                                                                                    defaultValue={item.educationAddress}
                                                                                                    onChange={(e) => { changeEvent("education", idx, "educationAddress", e.target.value); }}
                                                                                                    className="form-control"
                                                                                                    id={"degreeAddress[" + idx + "]"}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-3">
                                                                                                <Label htmlFor={"degreeStartDate[" + idx + "]"}>Start Date <span className="requiredSign">*</span></Label>
                                                                                                <Flatpickr
                                                                                                    name={"degreeStartDate[" + idx + "]"}
                                                                                                    id={"degreeStartDate[" + idx + "]"}
                                                                                                    className="form-control d-block"
                                                                                                    // placeholder="dd M, yyyy"
                                                                                                    options={{
                                                                                                        altInput: true,
                                                                                                        altFormat: "F j, Y",
                                                                                                        dateFormat: "Y-m-d",
                                                                                                        defaultDate: new Date(item.educationStartDate)
                                                                                                    }}
                                                                                                    onChange={(selectedDates, dateStr, instance) => {
                                                                                                        changeEvent("education", idx, "educationStartDate", dateStr);
                                                                                                    }}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-3">
                                                                                                <Label htmlFor={"degreeEndDate[" + idx + "]"}>End Date <span className="requiredSign">*</span></Label>
                                                                                                <Flatpickr
                                                                                                    name={"degreeEndDate[" + idx + "]"}
                                                                                                    id={"degreeEndDate[" + idx + "]"}
                                                                                                    className="form-control d-block"
                                                                                                    // placeholder="dd M, yyyy"
                                                                                                    options={{
                                                                                                        altInput: true,
                                                                                                        altFormat: "F j, Y",
                                                                                                        dateFormat: "Y-m-d",
                                                                                                        defaultDate: new Date(item.educationEndDate)
                                                                                                    }}
                                                                                                    onChange={(selectedDates, dateStr, instance) => {
                                                                                                        changeEvent("education", idx, "educationEndDate", dateStr);
                                                                                                    }}
                                                                                                />
                                                                                            </div>

                                                                                            <Col md={12} className="align-self-center" style={{ textAlign: "center" }}>
                                                                                                <button
                                                                                                    data-repeater-delete
                                                                                                    type="button"
                                                                                                    className="btn btn-danger waves-effect waves-light"
                                                                                                    onClick={e => {
                                                                                                        removeRow("education", item.idx)
                                                                                                    }}>
                                                                                                    <i className="bx bx-trash font-size-20 align-middle"></i>
                                                                                                </button>
                                                                                            </Col>
                                                                                        </div>

                                                                                    </div>
                                                                                    <hr />
                                                                                </React.Fragment>
                                                                            ))}
                                                                            <Col md={12} style={{ textAlign: "center" }}>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        setEducationDetails([...educationDetails, { idx: educationDetails.length }])
                                                                                    }}
                                                                                    color="success"
                                                                                    className="btn btn-success mt-3 mt-lg-0"
                                                                                >{educationDetails.length>0 ?"Add Education More":"Add Education"}</Button>
                                                                            </Col>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>

                                                            <TabPane tabId={3}>
                                                                <Row>
                                                                    <CardTitle typeof="h4">Working Experience (List your professional experiences, Latest/Recent Profession at the First)</CardTitle>
                                                                    <Col xs={12}>
                                                                        <div className="repeater">
                                                                            {workingDetails && workingDetails.map((item, idx) => (
                                                                                <React.Fragment key={idx}>
                                                                                    <div data-repeater-list="group-a" id={"repeater" + idx} className="customBackground">
                                                                                        <div data-repeater-item className="row">
                                                                                            <div className="mb-3 col-md-4">
                                                                                                <Label htmlFor={"designation[" + idx + "]"}>Designation <span className="requiredSign">*</span></Label>
                                                                                                <AvField
                                                                                                    name={"designation[" + idx + "]"}
                                                                                                    // placeholder="Designation"
                                                                                                    type="text"
                                                                                                    defaultValue={item.experienceDesignation}
                                                                                                    onChange={(e) => { changeEvent("working", idx, "experienceDesignation", e.target.value); }}
                                                                                                    className="form-control"
                                                                                                    id={"designation[" + idx + "]"}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-4">
                                                                                                <Label htmlFor={"organization[" + idx + "]"}>Organization <span className="requiredSign">*</span></Label>
                                                                                                <AvField
                                                                                                    name={"organization[" + idx + "]"}
                                                                                                    // placeholder="Organization"
                                                                                                    type="text"
                                                                                                    defaultValue={item.experienceOrganization}
                                                                                                    onChange={(e) => { changeEvent("working", idx, "experienceOrganization", e.target.value); }}
                                                                                                    className="form-control"
                                                                                                    id={"organization[" + idx + "]"}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-4">
                                                                                                <Label htmlFor={"industry[" + idx + "]"}>Industry <span className="requiredSign">*</span></Label>
                                                                                                <AvField
                                                                                                    name={"industry[" + idx + "]"}
                                                                                                    // placeholder="Industry"
                                                                                                    type="text"
                                                                                                    defaultValue={item.experienceIndustry}
                                                                                                    onChange={(e) => { changeEvent("working", idx, "experienceIndustry", e.target.value); }}
                                                                                                    className="form-control"
                                                                                                    id={"industry[" + idx + "]"}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-12">
                                                                                                <Label htmlFor={"orgAddress[" + idx + "]"}>Organization Address <span className="requiredSign">*</span></Label>
                                                                                                <AvField
                                                                                                    name={"orgAddress[" + idx + "]"}
                                                                                                    // placeholder="Address"
                                                                                                    type="text"
                                                                                                    defaultValue={item.experienceOrgAddress}
                                                                                                    onChange={(e) => { changeEvent("working", idx, "experienceOrgAddress", e.target.value); }}
                                                                                                    className="form-control"
                                                                                                    id={"orgAddress[" + idx + "]"}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-3">
                                                                                                <Label htmlFor={"expStartDate[" + idx + "]"}>Start Date <span className="requiredSign">*</span></Label>
                                                                                                <Flatpickr
                                                                                                    name={"expStartDate[" + idx + "]"}
                                                                                                    id={"expStartDate[" + idx + "]"}
                                                                                                    className="form-control d-block"
                                                                                                    // placeholder="dd M, yyyy"
                                                                                                    options={{
                                                                                                        altInput: true,
                                                                                                        altFormat: "F j, Y",
                                                                                                        dateFormat: "Y-m-d",
                                                                                                        defaultDate: new Date(item.experienceStartDate)
                                                                                                    }}
                                                                                                    onChange={(selectedDates, dateStr, instance) => {
                                                                                                        changeEvent("working", idx, "experienceStartDate", dateStr);
                                                                                                    }}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-3">
                                                                                                <Label htmlFor={"expEndDate[" + idx + "]"}>End Date</Label>
                                                                                                <Flatpickr
                                                                                                    name={"expEndDate[" + idx + "]"}
                                                                                                    id={"expEndDate[" + idx + "]"}
                                                                                                    className="form-control d-block"
                                                                                                    // placeholder="dd M, yyyy"
                                                                                                    options={{
                                                                                                        altInput: true,
                                                                                                        altFormat: "F j, Y",
                                                                                                        dateFormat: "Y-m-d",
                                                                                                        defaultDate: item.experienceEndDate?new Date(item.experienceEndDate):false
                                                                                                    }}
                                                                                                    onChange={(selectedDates, dateStr, instance) => {
                                                                                                        changeEvent("working", idx, "experienceEndDate", dateStr);
                                                                                                    }}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-6">
                                                                                                <Label htmlFor={"continuing[" + idx + "]"}>Continuing</Label>
                                                                                                {/* <AvField
                                                                                                    name={"continuing[" + idx + "]"}
                                                                                                    // placeholder="Continuing"
                                                                                                    type="checkbox"
                                                                                                    defaultValue={item.experienceContinuing}
                                                                                                    onChange={(e) => { changeEvent("working", idx, "experienceContinuing", e.target.value); }}
                                                                                                    className="form-control"
                                                                                                    id={"continuing[" + idx + "]"}
                                                                                                /> */}
                                                                                                <div className="form-check form-switch" dir="ltr" style={{ marginTop: "8px" }}>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        className="form-check-input"
                                                                                                        id={"continuing[" + idx + "]"}
                                                                                                        defaultChecked={item.experienceContinuing}
                                                                                                        onChange={(e) => { changeEvent("working", idx, "experienceContinuing", e.target.checked); }}
                                                                                                    />
                                                                                                    <label
                                                                                                        className="form-check-label"
                                                                                                        htmlFor={"continuing[" + idx + "]"}
                                                                                                        style={{ marginTop: "0" }}
                                                                                                    >
                                                                                                        Currently Working
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                            <Col md={12} className="align-self-center" style={{ textAlign: "center" }}>
                                                                                                <button
                                                                                                    data-repeater-delete
                                                                                                    type="button"
                                                                                                    className="btn btn-danger waves-effect waves-light"
                                                                                                    onClick={e => {
                                                                                                        removeRow("working", item.idx)
                                                                                                    }}>
                                                                                                    <i className="bx bx-trash font-size-20 align-middle"></i>
                                                                                                </button>
                                                                                            </Col>
                                                                                        </div>

                                                                                    </div>
                                                                                    <hr />
                                                                                </React.Fragment>
                                                                            ))}
                                                                            <Col md={12} style={{ textAlign: "center" }}>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        setWorkingDetails([...workingDetails, { idx: workingDetails.length }])
                                                                                    }}
                                                                                    color="success"
                                                                                    className="btn btn-success mt-3 mt-lg-0"
                                                                                >{workingDetails.length>0 ?"Add Experience More":"Add Experience"}</Button>
                                                                            </Col>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>

                                                            <TabPane tabId={4}>
                                                                <Row>
                                                                    <CardTitle typeof="h4">Training Profile (List your professional trainings, Latest/Recent Training at the First)</CardTitle>
                                                                    <Col xs={12}>
                                                                        <div className="repeater">
                                                                            {trainingDetails && trainingDetails.map((item, idx) => (
                                                                                <React.Fragment key={idx}>
                                                                                    <div data-repeater-list="group-a" id={"repeater" + idx} className="customBackground">
                                                                                        <div data-repeater-item className="row">
                                                                                            <div className="mb-3 col-md-9">
                                                                                                <Label htmlFor={"trainingTitle[" + idx + "]"}>Title of the training</Label>
                                                                                                <AvField
                                                                                                    name={"trainingTitle[" + idx + "]"}
                                                                                                    // placeholder="Title"
                                                                                                    type="text"
                                                                                                    defaultValue={item.trainingTitle}
                                                                                                    onChange={(e) => { changeEvent("training", idx, "trainingTitle", e.target.value); }}
                                                                                                    className="form-control"
                                                                                                    id={"trainingTitle[" + idx + "]"}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-3">
                                                                                                <Label htmlFor={"trainingStatus[" + idx + "]"}>Status</Label>
                                                                                                <AvField
                                                                                                    name={"trainingStatus[" + idx + "]"}
                                                                                                    // placeholder="Status"
                                                                                                    type="text"
                                                                                                    defaultValue={item.trainingStatus}
                                                                                                    onChange={(e) => { changeEvent("training", idx, "trainingStatus", e.target.value); }}
                                                                                                    className="form-control"
                                                                                                    id={"trainingStatus[" + idx + "]"}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-6">
                                                                                                <Label htmlFor={"trainingInstitution[" + idx + "]"}>Institution</Label>
                                                                                                <AvField
                                                                                                    name={"trainingInstitution[" + idx + "]"}
                                                                                                    // placeholder="Institution"
                                                                                                    type="text"
                                                                                                    defaultValue={item.trainingInstitution}
                                                                                                    onChange={(e) => { changeEvent("training", idx, "trainingInstitution", e.target.value); }}
                                                                                                    className="form-control"
                                                                                                    id={"trainingInstitution[" + idx + "]"}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-3">
                                                                                                <Label htmlFor={"trainingCountry[" + idx + "]"}>Country</Label>
                                                                                                <Select
                                                                                                    name={"trainingCountry[" + idx + "]"}
                                                                                                    id={"trainingCountry[" + idx + "]"}
                                                                                                    options={countries}
                                                                                                    value={countries ? countries.filter(x => x.value == item.trainingCountryId) : null}
                                                                                                    onChange={(e) => { changeEvent("training", idx, "trainingCountryId", e.value); }}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-3">
                                                                                                <Label htmlFor={"trainingCampus[" + idx + "]"}>Campus</Label>
                                                                                                <AvField
                                                                                                    name={"trainingCampus[" + idx + "]"}
                                                                                                    // placeholder="Campus"
                                                                                                    type="text"
                                                                                                    defaultValue={item.trainingCampus}
                                                                                                    onChange={(e) => { changeEvent("training", idx, "trainingCampus", e.target.value); }}
                                                                                                    className="form-control"
                                                                                                    id={"trainingCampus[" + idx + "]"}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-6">
                                                                                                <Label htmlFor={"trainingAddress[" + idx + "]"}>Address</Label>
                                                                                                <AvField
                                                                                                    name={"trainingAddress[" + idx + "]"}
                                                                                                    // placeholder="Address"
                                                                                                    type="text"
                                                                                                    defaultValue={item.trainingAddress}
                                                                                                    onChange={(e) => { changeEvent("training", idx, "trainingAddress", e.target.value); }}
                                                                                                    className="form-control"
                                                                                                    id={"trainingAddress[" + idx + "]"}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-3">
                                                                                                <Label htmlFor={"trainingStartDate[" + idx + "]"}>Start Date</Label>
                                                                                                <Flatpickr
                                                                                                    name={"trainingStartDate[" + idx + "]"}
                                                                                                    id={"trainingStartDate[" + idx + "]"}
                                                                                                    className="form-control d-block"
                                                                                                    // placeholder="dd M, yyyy"
                                                                                                    options={{
                                                                                                        altInput: true,
                                                                                                        altFormat: "F j, Y",
                                                                                                        dateFormat: "Y-m-d",
                                                                                                        defaultDate: item.trainingStartDate?new Date(item.trainingStartDate):false
                                                                                                    }}
                                                                                                    onChange={(selectedDates, dateStr, instance) => {
                                                                                                        changeEvent("training", idx, "trainingStartDate", dateStr);
                                                                                                    }}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-3">
                                                                                                <Label htmlFor={"trainingEndDate[" + idx + "]"}>End Date</Label>
                                                                                                <Flatpickr
                                                                                                    name={"trainingEndDate[" + idx + "]"}
                                                                                                    id={"trainingEndDate[" + idx + "]"}
                                                                                                    className="form-control d-block"
                                                                                                    // placeholder="dd M, yyyy"
                                                                                                    options={{
                                                                                                        altInput: true,
                                                                                                        altFormat: "F j, Y",
                                                                                                        dateFormat: "Y-m-d",
                                                                                                        defaultDate: item.trainingEndDate?new Date(item.trainingEndDate):false
                                                                                                    }}
                                                                                                    onChange={(selectedDates, dateStr, instance) => {
                                                                                                        changeEvent("training", idx, "trainingEndDate", dateStr);
                                                                                                    }}
                                                                                                />
                                                                                            </div>

                                                                                            <Col md={12} className="align-self-center" style={{ textAlign: "center" }}>
                                                                                                <button
                                                                                                    data-repeater-delete
                                                                                                    type="button"
                                                                                                    className="btn btn-danger waves-effect waves-light"
                                                                                                    onClick={e => {
                                                                                                        removeRow("training", item.idx)
                                                                                                    }}>
                                                                                                    <i className="bx bx-trash font-size-20 align-middle"></i>
                                                                                                </button>
                                                                                            </Col>
                                                                                        </div>

                                                                                    </div>
                                                                                    <hr />
                                                                                </React.Fragment>
                                                                            ))}
                                                                            <Col md={12} style={{ textAlign: "center" }}>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        setTrainingDetails([...trainingDetails, { idx: trainingDetails.length }])
                                                                                    }}
                                                                                    color="success"
                                                                                    className="btn btn-success mt-3 mt-lg-0"
                                                                                >{trainingDetails.length>0 ?"Add Training More":"Add Training"}</Button>
                                                                            </Col>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>

                                                            <TabPane tabId={5}>
                                                                <Row>
                                                                    <CardTitle typeof="h4">Certification Profile (List your professional certification, Latest/Recent Certification at the First)</CardTitle>
                                                                    <Col xs={12}>
                                                                        <div className="repeater">
                                                                            {certificationDetails && certificationDetails.map((item, idx) => (
                                                                                <React.Fragment key={idx}>
                                                                                    <div data-repeater-list="group-a" id={"repeater" + idx} className="customBackground">
                                                                                        <div data-repeater-item className="row">
                                                                                            <div className="mb-3 col-md-9">
                                                                                                <Label htmlFor={"profCertification[" + idx + "]"}>Professional Certification</Label>
                                                                                                <AvField
                                                                                                    name={"profCertification[" + idx + "]"}
                                                                                                    // placeholder="Professional Certification"
                                                                                                    type="text"
                                                                                                    defaultValue={item.certificationName}
                                                                                                    onChange={(e) => { changeEvent("certification", idx, "certificationName", e.target.value); }}
                                                                                                    className="form-control"
                                                                                                    id={"profCertification[" + idx + "]"}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-3">
                                                                                                <Label htmlFor={"certificationYear[" + idx + "]"}>Year</Label>
                                                                                                <AvField
                                                                                                    name={"certificationYear[" + idx + "]"}
                                                                                                    // placeholder="Year"
                                                                                                    type="number"
                                                                                                    defaultValue={item.certificationYear}
                                                                                                    onChange={(e) => { changeEvent("certification", idx, "certificationYear", e.target.value); }}
                                                                                                    className="form-control"
                                                                                                    // validate={{min: {value: 1900},max: {value: 9999}}}
                                                                                                    min={1900}
                                                                                                    max={9999}
                                                                                                    id={"certificationYear[" + idx + "]"}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-9">
                                                                                                <Label htmlFor={"certificationInstitution[" + idx + "]"}>Institution</Label>
                                                                                                <AvField
                                                                                                    name={"certificationInstitution[" + idx + "]"}
                                                                                                    // placeholder="Industry"
                                                                                                    defaultValue={item.certificationInstitution}
                                                                                                    type="text"
                                                                                                    onChange={(e) => { changeEvent("certification", idx, "certificationInstitution", e.target.value); }}
                                                                                                    className="form-control"
                                                                                                    id={"certificationInstitution[" + idx + "]"}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="mb-3 col-md-3">
                                                                                                <Label htmlFor={"certificationCountry[" + idx + "]"}>Country</Label>
                                                                                                <Select
                                                                                                    name={"certificationCountry[" + idx + "]"}
                                                                                                    id={"certificationCountry[" + idx + "]"}
                                                                                                    options={countries}
                                                                                                    value={countries ? countries.filter(x => x.value == item.certificationCountryId) : null}
                                                                                                    onChange={(e) => { changeEvent("certification", idx, "certificationCountryId", e.value); }}
                                                                                                />
                                                                                            </div>

                                                                                            <Col md={12} className="align-self-center" style={{ textAlign: "center" }}>
                                                                                                <button
                                                                                                    data-repeater-delete
                                                                                                    type="button"
                                                                                                    className="btn btn-danger waves-effect waves-light"
                                                                                                    onClick={e => {
                                                                                                        removeRow("certification", item.idx)
                                                                                                    }}>
                                                                                                    <i className="bx bx-trash font-size-20 align-middle"></i>
                                                                                                </button>
                                                                                            </Col>
                                                                                        </div>

                                                                                    </div>
                                                                                    <hr />
                                                                                </React.Fragment>
                                                                            ))}
                                                                            <Col md={12} style={{ textAlign: "center" }}>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        setCertificationDetails([...certificationDetails, { idx: certificationDetails.length }])
                                                                                    }}
                                                                                    color="success"
                                                                                    className="btn btn-success mt-3 mt-lg-0"
                                                                                >{certificationDetails.length>0 ?"Add Certification More":"Add Certification"}</Button>
                                                                            </Col>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>

                                                            <TabPane tabId={6}>
                                                                <Row>
                                                                    <Col xs={12}>
                                                                        <div className="mb-3 col-md-12">
                                                                            <Label htmlFor={"paymentCourseId"}>Program/Course <span className="requiredSign">*</span></Label>
                                                                            <Select
                                                                                name={"paymentCourseId"}
                                                                                id={"paymentCourseId"}
                                                                                options={courses}
                                                                                isDisabled={urlRequestdata!=false && courseId}
                                                                                value={courses ? courses.filter(x => x.value == courseId)[0] : ''}
                                                                                onChange={(e) => { setCourseId(e.value); fetchPaymentDetails(e.value); }}
                                                                            />
                                                                        </div>
                                                                        <PaymentPolicy paymentPolicyData={paymentPolicy} transactions={transactions} courseId={courseId} academicProfileId={updateData?.id} userHasEditPermission={userHasEditPermission}/>
                                                                        {userHasEditPermission && <>
                                                                            <CardTitle typeof="h4">Administrative Division</CardTitle>
                                                                            <Row>
                                                                                <div className="mb-3 col-md-6">
                                                                                    <Label htmlFor={"academicBimCertificateNo"}>Certificate No</Label>
                                                                                    <AvField
                                                                                        name={"academicBimCertificateNo"}
                                                                                        defaultValue={updateData.academicBimCertificateNo}
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        id={"academicBimCertificateNo"}
                                                                                    />
                                                                                </div>
                                                                                <div className="mb-3 col-md-6">
                                                                                    <Label htmlFor={"academicIsActive"}>Status</Label>
                                                                        
                                                                                    <Select
                                                                                        name={"academicIsActive"}
                                                                                        id={"academicIsActive"}
                                                                                        options={statusList}
                                                                                        value={statusList.filter(x => x.value == userStatus)}
                                                                                        onChange={(e) => { setUserStatus(e.value); }}
                                                                                    />
                                                                                </div>
                                                                            </Row>
                                                                        </>}
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                        </TabContent>
                                                    </div>
                                                    <div className="actions clearfix">
                                                        {activeTab != 1 &&
                                                            <Button
                                                                style={{ float: "left" }}
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={() => {
                                                                    toggleTab(activeTab - 1)
                                                                }}
                                                            >Previous</Button>
                                                        }
                                                        {activeTab === 6 || (isParticipant && activeTab === 5)?
                                                            <>
                                                                {showFinalSubmitBtn && <Button color="primary" type="submit">
                                                                    Submit
                                                                </Button>}
                                                            </>
                                                            :
                                                            <>
                                                                {activeTab == 5?
                                                                <Button color="primary" type="submit">
                                                                    Next
                                                                </Button>
                                                                :
                                                                <>
                                                                <Link
                                                                    to="#"
                                                                    className="btn btn-primary"
                                                                    onClick={() => {
                                                                        toggleTab(activeTab + 1)
                                                                    }}
                                                                >Next</Link>
                                                                </>}
                                                            </>
                                                            
                                                        }
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
                :
                <CustomLoader/>
            }

            <Modal
            size="sm"
            isOpen={status_modal_center}
            centered={true}>
              
            <div className="modal-header">
              <h5 className="modal-title mt-0">Duplicate profile found !</h5>
              <button
                type="button"
                onClick={() => {
                  setStatus_modal_center(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              > 
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{padding:"0"}}>
                <CardBody>
                    <Row>
                        <Col md="12" dangerouslySetInnerHTML={{ __html: status_modal_message }}>
                            {/* {props.bodyMsg?props.bodyMsg:"Are you sure about this action ?"} */}
                        </Col>
                        {showPasswordEntry && <Col md="12" className="pt-1">Password : <input type="password" placeholder="Password" onChange={(e)=>setUserPassword(e.target.value)}/></Col>}
                    </Row>
                </CardBody>
            </div>
            <div className="modal-footer customBtnArea" style={{textAlign: 'right'}}>
                {/* <Button color="primary" type="submit" className="btn btn-sm" onClick={()=>passwordResetHandler(true)}>
                    Yes
                </Button> */}
                {showPasswordEntry && 
                <Button color="primary" type="button" className="btn btn-sm" onClick={passwordResetHandler}>
                    Login
                </Button>}

                <Button color="primary" type="button" className="btn btn-sm" onClick={()=>setStatus_modal_center(false)}>
                    OK
                </Button>
            </div>
          </Modal>
        </Row>
    )
}

export default Model
